import axios from '@/axios'
import { setAuthTokens } from 'axios-jwt'

// ---------------------------------------------------- Общие
export function login(data) {
  return new Promise((resolve, reject) => {
    axios
      .post('token/jwt/create/', data)
      .then(response => {
        if (response.data.access) {
          setAuthTokens({
            accessToken: response.data.access,
            refreshToken: response.data.refresh
          })
        }
        resolve(response)
      })
      .catch(error => reject(error))
  })
}
export function getGroups() {
  return axios.get('auth/groups/')
}

// ---------------------------------------------------- Hall (основной экран)
export function getCRMAllData(datetime) {
  return axios.get(`crm/data/?datetime=${datetime}`)
}

export function getTableCheck(params) {
  return axios.get(`crm/check-table/?table_id=${params.table_id}&time_start=${params.time_start}&count_guests=${params.count_guests}`)
}

export function getReplaceTable(params) {
  return axios.get(`crm/replace-table/?table=${params.table}&booking=${params.booking}`)
}

export function postReplaceTable(data) {
  return axios.post('crm/replace-table/', data)
}

// ---------------------------------------------------- Гости
export function getGuests(params) {
  return axios.get(`guests/?search=${params.search}&page=${params.page}&page_size=${params.page_size}`)
}

export function postGuest(data) {
  return axios.post('guests/', data)
}

export function getGuest(id) {
  return axios.get(`guests/${id}/`)
}

export function putGuest(id, data) {
  return axios.put(`guests/${id}/`, data)
}

export function deleteGuest(id) {
  return axios.delete(`guests/${id}/`)
}

// ---------------------------------------------------- Столы
export function getTables(params) {
  return axios.get(`tables/?search=${params.search}&active=${params.active}&hall=${params.hall}`)
}

export function postTable(data) {
  return axios.post('tables/', data)
}

export function getTable(id) {
  return axios.get(`tables/${id}/`)
}

export function putTable(id, data) {
  return axios.put(`tables/${id}/`, data)
}

export function deleteTable(id) {
  return axios.delete(`tables/${id}/`)
}

export function getLanguages() {
  return axios.get('languages/?active=true')
}

// ---------------------------------------------------- Залы
export function getHalls() {
  return axios.get('halls/')
}

export function postHall(data) {
  return axios.post('halls/', data)
}

export function putHall(id, data) {
  return axios.put(`halls/${id}/`, data)
}

export function deleteHall(id) {
  return axios.delete(`halls/${id}/`)
}

// ---------------------------------------------------- Депозиты
export function getDeposits(params) {
  const url = new URL(`${process.env.VUE_APP_API_URL}deposits/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function postDeposit(data) {
  return axios.post('deposits/', data)
}

export function putDeposit(id, data) {
  return axios.put(`deposits/${id}/`, data)
}

export function deleteDeposit(id) {
  return axios.delete(`deposits/${id}/`)
}

// ---------------------------------------------------- Пользователи
export function getUsers(params) {
  return axios.get(`auth/users/?&page=${params.page}&page_size=${params.page_size}&search=${params.search}&active=${params.active}`)
}

export function postUser(data) {
  return axios.post('auth/users/', data)
}

export function putUser(id, data) {
  return axios.put(`auth/users/${id}/`, data)
}

export function deleteUser(id) {
  return axios.delete(`auth/users/${id}/`)
}

// ---------------------------------------------------- Бронирование
export function getBookings(params) {
  const url = new URL(`${process.env.VUE_APP_API_URL}bookings/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function postBooking(data) {
  return axios.post('bookings/', data)
}

export function getBooking(id) {
  return axios.get(`bookings/${id}/`)
}

export function putBooking(id, data) {
  return axios.put(`bookings/${id}/`, data)
}

export function patchBooking(id, data) {
  return axios.patch(`bookings/${id}/`, data)
}

export function deleteBooking(id) {
  return axios.delete(`bookings/${id}/`)
}

export function sendSMS(id) {
  return axios.get(`bookings/${id}/send-sms/`)
}

export function getCancelReasons() {
  return axios.get('cancel-reasons/?active=true')
}

export function getRefundStatuses() {
  return axios.get('refund-statuses/?active=true')
}

export function getOnlineBookings(params) {
  const url = new URL(`${process.env.VUE_APP_API_URL}schedules/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function postOnlineBooking(data) {
  return axios.post('schedules/', data)
}

export function putOnlineBooking(id, data) {
  return axios.put(`schedules/${id}/`, data)
}

export function deleteOnlineBooking(id) {
  return axios.delete(`schedules/${id}/`)
}

// ---------------------------------------------------- R_Keeper
export function getRK(id) {
  return axios.get(`bookings/${id}/rk/`)
}

export function postRKBooking(id) {
  return axios.post(`bookings/${id}/rk/book/`)
}

export function postRKPrepay(id) {
  return axios.post(`bookings/${id}/rk/prepay/`)
}

export function getBookingsLogs(id) {
  return axios.get(`rkeeper/bookings/${id}/`)
}

// ---------------------------------------------------- Платежи и транзакции
export function getTransactions(invoiceID) {
  return axios.get(`transactions/?invoice=${invoiceID}&page_size=100`)
}

export function checkInvoice(id) {
  return axios.get(`public/bookings/${id}/invoice/`)
}

// ---------------------------------------------------- Header
export function getMe() {
  return axios.get('me/')
}

export function getStatHeader() {
  return axios.get('crm/statistic/nav/')
}

export function getStat(status) {
  return axios.get(`crm/statistic/?status=${status}`)
}

// ---------------------------------------------------- На(Логи)
export function getBookingLogs(id) {
  return axios.get(`bookings/${id}/logs/`)
}

export function getBookingSMSLogs(id) {
  return axios.get(`bookings/${id}/sms/`)
}

export function getBookingEmailLogs(id) {
  return axios.get(`bookings/${id}/emails/`)
}

export function getGuestLogs(id) {
  return axios.get(`guests/${id}/logs/`)
}

export function getGuestSMSLogs(id) {
  return axios.get(`guests/${id}/sms/`)
}

export function getGuestEmailLogs(id) {
  return axios.get(`guests/${id}/emails/`)
}

// ---------------------------------------------------- Лист ожидания
export function getWaiting(params) {
  return axios.get(`waitings/?time_start=${params.time_start}&time_end=${params.time_end}&active=true`)
}

export function postWaiting(data) {
  return axios.post('waitings/', data)
}

export function putWaiting(id, data) {
  return axios.put(`waitings/${id}/`, data)
}

export function patchWaiting(id, data) {
  return axios.patch(`waitings/${id}/`, data)
}

// ---------------------------------------------------- Публичное бронирование
export function getTimes(date, person, hall) {
  return axios.get(`public/bookings/?date=${date}&count_guests=${person}&hall=${hall}`)
}

export function postPublicBooking(data) {
  return axios.post('public/bookings/create/', data)
}

export function getPublicHalls() {
  return axios.get('public/halls/')
}

// ---------------------------------------------------- Аналитика
export function getGuestAnalytics(params) {
  return axios.get(`analytics/guests/?time_start=${params.time_start}&time_end=${params.time_end}`)
}

export function getFinAnalytics(params) {
  return axios.get(`analytics/fin/?time_start=${params.time_start}&time_end=${params.time_end}`)
}

export function getRefundList(query) {
  const url = new URL(`${process.env.VUE_APP_API_URL}crm/refund-requests`)
  Object.entries(query).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

// ---------------------------------------------------- Не внесли депозит
export function getPaymentExpired() {
  return axios.get('crm/booking_payment_expired/')
}

export function postPaymentExpired(id, data) {
  return axios.post(`crm/booking_payment_expired/${id}/action/`, data)
}
