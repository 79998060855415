<template>
  <div class="replace-list">
    <div v-for="(item, index) in replace_data" :key="index" class="replace-list__block">
      <div v-if="index === 0" class="replace-list__title">Бронирование №{{ item.booking.id }} будет изменено:</div>
      <div v-if="index === 1" class="replace-list__title">Изменения в других бронированиях:</div>
      <div class="replace-list__item">
        <template v-if="index === 0">
          <div class="replace-list__part">
            <b>Бронь</b>: ID {{ item.booking.id }} ({{ item.booking.time_start }} - {{ item.booking.time_end }})
            <img v-if="item.booking.is_overbooking" src="@/assets/img/overbooking.svg" alt="">
          </div>
          <img src="@/assets/img/arrow-right-green.svg" alt="">
          <div class="replace-list__part"><b>Стол</b>: №{{ item.table.number }} ({{ item.table.hall }})</div>
        </template>
        <template v-else>
          <div class="replace-list__part"><b>Стол</b>: №{{ item.table.number }} ({{ item.table.hall }})</div>
          <img src="@/assets/img/arrow-left-yellow.svg" alt="">
          <div class="replace-list__part">
            <b>Бронь</b>: ID {{ item.booking.id }} ({{ item.booking.time_start }} - {{ item.booking.time_end }})
            <img v-if="item.booking.is_overbooking" src="@/assets/img/overbooking.svg" alt="">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppReplaceList',
  props: {
    replace_data: Array
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.replace-list
  margin-bottom: 30px

.replace-list__block:nth-child(1)
  margin-bottom: 10px

.replace-list__title
  margin-bottom: 10px
  color: #737c85

.replace-list__item
  display: grid
  grid-template-columns: 1fr 24px 1fr
  align-items: center
  margin-bottom: 10px
  gap: 25px

  &:last-child
    margin-bottom: 0

  b
    font-weight: 600

.replace-list__part
  display: flex
  align-items: center

  img
    margin-left: 6px

</style>
