<template>
  <button
      class="btn"
      @click="onClick"
      :disabled="disabled"
      :type="type"
      :class="[themes[theme], sizes[size], {'btn--beauty': disabled}, {'btn--preload': preload}]"
  >
    <slot v-if="!preload" />
    <img v-if="preload" src="@/assets/img/preloader.svg" alt="" class="btn__preloader" />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  data() {
    return {
      themes: {
        default: 'btn--default',
        accept: 'btn--accept',
        error: 'btn--error',
      },
      sizes: {
        normal: '',
        small: 'btn--sm',
        super_small: 'btn--super-sm',
        link: 'btn--link',
        stretch: 'btn--stretch',
      },
      preload: false,
    }
  },
  props: {
    theme: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'stretch'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'submit'
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) return
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.btn
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  min-width: 182px
  min-height: 52px
  padding: 15px 30px 13px
  border-radius: 5px
  color: #fff
  font-size: 16px
  font-weight: 600
  line-height: 1
  text-align: center
  text-decoration: none
  transition: background-color 0.3s
  cursor: pointer
  user-select: none

  +max-w($laptop-big)
    min-width: 160px

  img, svg
    flex-shrink: 0

.btn--preload
  pointer-events: none

.btn--default
  background-color: $color-theme

  &:active
    background-color: #4387ff

  @media (hover: hover)
    &:hover,
    &:focus
      background-color: #4387ff

.btn--accept
  background-color: $status-new

  &:active
    background-color: #88d9aa

  @media (hover: hover)
    &:hover,
    &:focus
      background-color: #88d9aa

.btn--error
  background-color: #eb5757

  &:active
    background-color: #ff6b6b

  @media (hover: hover)
    &:hover,
    &:focus
      background-color: #ff6b6b

.btn--sm
  min-width: 130px
  min-height: 44px
  border-radius: 5px

.btn--super-sm
  min-width: auto
  min-height: auto
  padding: 9px 10px 7px
  border-radius: 5px
  font-size: 12px

.btn--stretch
  width: 100%

.btn--beauty
  background-color: #b4b7bf
  pointer-events: none

.btn--link
  min-width: auto
  min-height: auto
  padding: 0
  border: none
  background-color: transparent
  color: $color-theme
  font-weight: 600
  transition: opacity 0.3s

  &[disabled]
    opacity: 0.5

  &:active
    opacity: 0.7
    background-color: transparent

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.7
      background-color: transparent

  &.btn--error
    color: #ea5467

.btn__preloader
  width: 20px
  height: 20px
</style>
