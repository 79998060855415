<template>
  <div class="content content--waiting">
    <div class="waiting">
      <div class="waiting__top">
        <app-cells position="between">
          <app-cells position="start" :indent="false">
            <h1 class="title">Лист ожидания</h1>
            <div class="date-picker-wrapper">
              <VueCtkDateTimePicker
                ref="datePicker"
                id="date_time_picker"
                class="custom-date-time-picker custom-date-time-picker--range"
                v-model="date_time_picker"
                :custom-shortcuts="custom_shortcuts"
                shortcut="thisWeek"
                @is-hidden="onIsHiddenPicker"
                format="DD.MM.YYYY"
                formatted="ll"
                label="Выберите даты"
                color="#2f73ff"
                button-color="#2f73ff"
                overlay
                no-header
                no-label
                range
                noClearButton
              />
              <div v-if="date_time_picker_error" class="date-picker-wrapper__error">Выберите дату окончания</div>
            </div>
          </app-cells>
          <app-button @click="onNewWaitingClick" size="small" type="button">Добавить запись</app-button>
        </app-cells>
      </div>
      <div class="waiting__list-wrap">
        <div class="waiting__list">
          <div class="waiting__header">
            <span v-for="(date, index) in waiting_data" :key="index">{{ dateFormatRU(index) }}</span>
          </div>
          <div class="waiting__content">
            <div v-for="(date, index) in waiting_data" :key="index" class="waiting__col">
              <div class="waiting__time" v-for="(key, index) in Object.keys(date).sort()" :key="index">
                  <app-divider>{{ `${key}:00` }}</app-divider>
                  <button v-for="card in date[key]" :key="card.id" @click="onCardClick(card)" class="waiting__card">
                  <span class="waiting__card-name">
                    <span>{{ card.guest.name }}, {{ card.count_guests }}</span>
                    <img src="@/assets/img/person-icon.svg" alt="person icon">
                  </span>
                    <span class="waiting__card-info">{{ card.guest.phone }}</span>
                    <span class="waiting__card-time">
                    <span>Удобное время:</span> <span class="waiting__card-info">{{ timeFormat(card.time) }}</span>
                  </span>
                    <span v-if="card.comment" class="waiting__card-comment">
                    <span>Комментарий:</span> <span class="waiting__card-info">{{ card.comment }}</span>
                  </span>
                  <span v-if="card.guest.language.icon" class="waiting__card-icon-wrap">
                    <span class="waiting__card-icon" v-html="card.guest.language.icon"></span>
                  </span>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-sidebar-right
      :title="is_create ? 'Добавление записи' : `Запись №${currentCard.id}, ${currentCard.count_guests} чел.`"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = false"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Дата, время" label-for="time_start" required>
          <app-input
            v-model="form.time"
            type="datetime-local"
            id="time_start"
            @change.native="onTimeChanged"
            :error="$v.form.time.$error"
          />
          <template #error>
            <div v-if="$v.form.time.$dirty && !$v.form.time.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Количество гостей" label-for="count_guests" required>
          <app-input
            v-model.number="form.count_guests"
            placeholder="Введите кол-во гостей"
            type="number"
            id="count_guests"
            :error="$v.form.count_guests.$error"
          />
          <template #error>
            <div v-if="$v.form.count_guests.$dirty && !$v.form.count_guests.required">
              Обязательное поле
            </div>
            <div v-if="$v.form.count_guests.$dirty && !$v.form.count_guests.integer">
              Принимаются только целые числовые значение
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Гость" key="guest_main" required>
          <template #additional>
            <app-button
              v-if="form.guest && form.guest.length < 1"
              @click="onAddGuestClick('main')"
              type="button" size="super_small" theme="accept"
              key="guest_main_add"
            >
              добавить
            </app-button>
          </template>
          <template #default>
            <app-guest-card
              :guests="form.guest"
              @onGuestDelete="onGuestDelete"
              @onGuestEdit="getCurrentGuest"
              type="main"
              key="guest_card_main"
            />
          </template>
          <template #error>
            <div v-if="$v.form.guest.$dirty && !$v.form.guest.required">
              Обязательное поле
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Комментарий хостес" label-for="description">
          <app-textarea
            v-model="form.comment"
            placeholder="Введите комментарий"
            id="description"
          />
        </app-form-group>
        <div v-if="!is_create" class="booking-controls">
          <div class="booking-controls__block  booking-controls__block--combined  booking-controls__block--2">
            <app-button theme="accept" type="submit" ref="submit">Сохранить</app-button>
            <app-button @click.native="onWaitingDelete" theme="error" type="button" ref="delete">Удалить</app-button>
          </div>
          <div class="booking-controls__block">
            <app-button @click.native="onCreateWaitingBooking" type="button" :disabled="!is_now">Создать бронирование</app-button>
          </div>
        </div>
        <app-cells v-else position="center">
          <app-button ref="submit" :disabled="$v.form.$error">Создать запись</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
    <modal
      name="guest-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <app-cells position="start">
          <div class="modal__title  modal__title--no-indent">Добавить гостя</div>
          <app-button @click.native="add_guest_by_phone = !add_guest_by_phone" type="button" size="super_small">
            {{ add_guest_by_phone ? 'По телефону' : 'По имени' }}
          </app-button>
        </app-cells>
        <div class="modal__body">
          <template v-if="guest_flag === '' || guest_flag === 'empty'">
            <app-form-group v-if="add_guest_by_phone" label="Телефон">
              <app-phone
                v-model="phone_search"
                @input.native="onPhoneSearch($event.target.value, 'phone')"
                placeholder="Введите телефон гостя"
                @paste.native.prevent
              />
            </app-form-group>
            <app-form-group v-else label="Имя">
              <app-input
                v-model="phone_search"
                @input.native="onPhoneSearch($event.target.value, 'name')"
                placeholder="Введите имя гостя"
              />
            </app-form-group>
            <div class="modal__search">
              <app-search-guests-list
                @onSearchGuestsListClick="onSearchGuestsListClick"
                :guests_list="guests_options_search"
              />
            </div>
            <app-cells position="center">
              <app-button
                v-if="guest_flag === 'empty'"
                @click="onEmptyButtonPhoneSearchClick"
                type="button" size="normal"
              >
                Гостя нет в базе, добавить его
              </app-button>
            </app-cells>
          </template>
          <template v-if="guest_flag !== 'empty' && guest_flag !== ''">
            <app-guest-form :form="current_guest" :flag="guest_flag" @guestAdded="guestAdded" />
          </template>
        </div>
        <div slot="top-right">
          <button @click="onGuestModalClear" class="modal__close"></button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { getGuest, getGuests, getWaiting, postWaiting, putWaiting, patchWaiting } from '@/http'
import { addWeeks, subDays, format, formatISO, getTime, addMinutes, startOfWeek, endOfWeek } from 'date-fns'
import { required, integer } from 'vuelidate/lib/validators'
import AppGuestCard from '@/components/AppGuestCard'
import AppSearchGuestsList from '@/components/AppSearchGuestsList'
import AppGuestForm from '@/components/AppGuestForm'
import AppDivider from '@/components/AppDivider'
import { debounce } from 'lodash'
import { dateFormatting } from '@/helpers'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import ru from 'date-fns/locale/ru'

export default {
  name: 'Waiting',
  components: { AppGuestCard, AppSearchGuestsList, AppGuestForm, AppDivider, VueCtkDateTimePicker },
  data() {
    return {
      waiting_data: {},
      form: { guest: [] },
      currentCard: {},
      show_sidebar: false,
      is_create: false,
      add_guest_by_phone: true,
      guest_flag: '',
      phone_search: '',
      current_guest: {},
      guests_options_search: [],
      is_now: false,
      date_time_picker: {},
      date_time_picker_error: false,
      custom_shortcuts: [
        { key: 'thisWeek', label: 'Эта неделя', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Последняя неделя', value: '-isoWeek' },
        { key: 'last7Days', label: 'Последние 7 дней', value: 7 },
        { key: 'last30Days', label: 'Последние 30 дней', value: 30 },
        { key: 'thisMonth', label: 'Этот месяц', value: 'month' },
        { key: 'lastMonth', label: 'Последний месяц', value: '-month' },
        { key: 'thisYear', label: 'Этот год', value: 'year' },
        { key: 'lastYear', label: 'Последний год', value: '-year' }
      ]
    }
  },
  validations: {
    form: {
      time: { required },
      count_guests: { required, integer },
      guest: { required }
    }
  },
  created() {
    this.getWaitingData(
      format(startOfWeek(new Date(), { locale: ru }), 'yyyy-MM-dd'),
      format(endOfWeek(new Date(), { locale: ru }), 'yyyy-MM-dd')
    )
  },
  methods: {
    calcIsNow(value) {
      const cardTime = getTime(new Date(value))
      const current = getTime(new Date(addMinutes(new Date(), 3)))

      this.is_now = cardTime > current
    },
    getWaitingData(
      time_start = this.date_time_picker ? dateFormatting(this.date_time_picker.start, 'normal-to-iso-small') : '',
      time_end = this.date_time_picker ? dateFormatting(this.date_time_picker.end, 'normal-to-iso-small') : ''
    ) {
      getWaiting({ time_start, time_end })
        .then(res => {
          this.waiting_data = { ...res.data }
          for (const key in this.waiting_data) {
            this.waiting_data[key] = this.waiting_data[key].reduce((rv, x) => {
              (rv[x['time'].slice(11, 13)] = rv[x['time'].slice(11, 13)] || []).push(x)
              return rv
            }, {})
          }
        })
    },
    onIsHiddenPicker() {
      if (this.date_time_picker &&
        this.date_time_picker.hasOwnProperty('start') &&
        this.date_time_picker.start &&
        this.date_time_picker.hasOwnProperty('end') &&
        this.date_time_picker.end) {
        this.date_time_picker_error = false

        this.getWaitingData(
          dateFormatting(this.date_time_picker.start, 'normal-to-iso-small'),
          dateFormatting(this.date_time_picker.end, 'normal-to-iso-small')
        )
      } else if (this.date_time_picker &&
        this.date_time_picker.start &&
        !this.date_time_picker.end) {
        this.date_time_picker_error = true
      }
    },
    onTimeChanged() {
      this.calcIsNow(this.form.time)
    },
    onCardClick(card) {
      this.currentCard = card
      this.form = { ...card }
      this.form.time = this.form.time.slice(0, 16)
      this.form.guest = [{ ...this.form.guest }]
      this.is_create = false
      this.show_sidebar = true
      this.calcIsNow(card.time)
    },
    onNewWaitingClick() {
      this.is_create = true
      this.form = { guest: [] }
      this.currentCard = {}
      this.$v.form.$reset()
      this.show_sidebar = true
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      const normalForm = { ...this.form }

      normalForm.guest = normalForm.guest[0].id
      normalForm.time = formatISO(new Date(normalForm.time))

      this.$refs.submit.preload = true
      if (this.is_create) {
        postWaiting(normalForm)
          .finally(() => { this.$refs.submit.preload = false })
          .then(() => {
            this.show_sidebar = false
            this.getWaitingData()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Запись успешно создана'
            })
          })
      } else {
        putWaiting(normalForm.id, normalForm)
          .finally(() => { this.$refs.submit.preload = false })
          .then(() => {
            this.show_sidebar = false
            this.getWaitingData()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Запись успешно сохранена'
            })
          })
      }
    },
    onAddGuestClick() {
      this.$modal.show('guest-modal')
    },
    onGuestDelete() {
      this.form.guest = []
    },
    onPhoneSearch(value, flag) {
      if (value) {
        if (flag === 'phone') {
          this.searchPhone(value.replace(/\s+/g, ''), this)
          if (value.length > 2 && this.phone_search !== 0 && !this.guests_options_search.length) this.guest_flag = 'empty'
          else this.guest_flag = ''
        } else if (flag === 'name') {
          this.searchPhone(value, this)
        }
      }
    },
    searchPhone: debounce((search, vm) => {
      getGuests({ search, page: 1, page_size: 20 })
        .then(res => { vm.guests_options_search = res.data.results })
    }, 350),
    onSearchGuestsListClick(guest) {
      if ((this.form.guest.length && this.form.guest[0].id === guest.id)) {
        this.$notify({
          type: 'info',
          title: 'Внимание',
          text: 'Этот гость уже добавлен'
        })
      } else this.getCurrentGuest(guest.id)
    },
    getCurrentGuest(guest_id, type) {
      getGuest(guest_id).then(res => {
        this.current_guest = { ...res.data }
        this.current_guest.birthday = dateFormatting(res.data.birthday, 'iso-to-normal-small')
        if (type) this.guest_type = type
        this.guest_flag = 'edit'
        this.$modal.show('guest-modal')
      })
    },
    onEmptyButtonPhoneSearchClick() {
      this.guest_flag = 'new'
      if (this.add_guest_by_phone) this.current_guest.phone = this.phone_search
      else this.current_guest.name = this.phone_search
    },
    guestAdded(new_guest) {
      if (this.form.guest.length > 0) this.form.guest.shift()
      if (!new_guest) this.form.guest.push({ ...this.current_guest })
      else this.form.guest.push({ ...new_guest })
      this.onGuestModalClear()
    },
    onGuestModalClear() {
      this.$modal.hide('guest-modal')
      this.guests_options_search = []
      this.current_guest = {}
      this.guest_flag = ''
      this.phone_search = ''
      this.add_guest_by_phone = true
    },
    onWaitingDelete() {
      this.$refs.delete.preload = true
      patchWaiting(this.form.id, { active: false })
        .finally(() => { this.$refs.delete.preload = false })
        .then(() => {
          this.show_sidebar = false
          this.getWaitingData()
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Запись удалена'
          })
        })
    },
    onCreateWaitingBooking() {
      this.$router.push({ name: 'hall', params: { waiting_data: this.form } })
    },
    dateFormatRU(date) {
      return format(new Date(date), 'EEEE dd MMMM', {locale: ru})
    },
    timeFormat(date) {
      return format(new Date(date), 'HH:mm', {locale: ru})
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.week-switcher
  display: inline-flex
  align-items: center
  justify-content: center
  gap: 10px
  padding: 13px 17px
  border-radius: 15px
  background-color: #040d26

.week-switcher__date
  display: inline-flex
  gap: 5px
  color: #fff
  font-size: 16px

  span
    min-width: 67px
    text-align: center

  i
    font-style: normal

.week-switcher__prev,
.week-switcher__next
  width: 16px
  height: 16px

.week-switcher__prev
  background-image: url("~@/assets/img/switcher-prev.svg")
  transition: opacity 0.3s

  &:disabled
    opacity: 0.5
    pointer-events: none

.week-switcher__next
  background-image: url("~@/assets/img/switcher-next.svg")

.waiting__top
  padding: 0 30px

.waiting__list-wrap
  overflow-x: auto
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar-track
    border-radius: 3px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 3px
    background-color: rgba($color-theme, 0.15)

  &::-webkit-scrollbar
    width: 7px
    height: 10px

.waiting__list
  max-width: 2488px

.waiting__time
  margin-bottom: 15px

.waiting__header
  display: flex
  padding: 0 30px
  background-color: #040d26

  span
    flex-shrink: 0
    width: 346px
    padding: 20px 0
    color: #fff
    background-color: #040d26
    font-size: 16px
    font-weight: 600
    text-align: center

.waiting__content
  display: flex
  padding: 0 30px
  background-color: #fff

.waiting__col
  flex-shrink: 0
  width: 346px
  max-height: calc(100vh - 300px)
  min-height: 225px
  padding: 20px 16px
  border-left: 1px solid #b4b7bf
  background-color: #fff
  overflow: auto
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar-track
    border-radius: 3px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 3px
    background-color: rgba($color-theme, 0.15)

  &::-webkit-scrollbar
    width: 3px
    height: 10px

  &:last-child
    border-right: 1px solid #b4b7bf

  +max-w($laptop-big)
    max-height: calc(100vh - 305px)

.waiting__card
  position: relative
  width: 100%
  margin-bottom: 16px
  padding: 20px 16px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third
  color: $color-text-base
  font-size: 16px
  transition: background-color 0.3s

  &:last-child
    margin-bottom: 0

  &:active
    background-color: #d6e5fc

  @media (hover: hover)
    &:hover,
    &:focus
      background-color: #d6e5fc

.waiting__card-icon-wrap
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  bottom: 0
  right: 0
  width: 36px
  height: 36px
  border-radius: 10px 0
  background-color: #eceff4

.waiting__card-icon
  line-height: 0

.waiting__card--new
  position: relative

  &:after
    content: 'Новая заявка'
    position: absolute
    inset: 0
    display: flex
    align-items: center
    justify-content: center
    border-radius: 10px
    color: #fff
    background-color: rgba(39, 174, 96, 0.9)
    font-size: 20px
    font-weight: 600

.waiting__card-name
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 10px
  font-weight: 600

.waiting__card-info
  display: flex
  flex-wrap: wrap
  gap: 5px
  margin-bottom: 10px

  &:last-child
    margin-bottom: 0

.waiting__card-info--anytime
  color: $status-new

.waiting__card-time
  display: flex
  align-items: center
  gap: 5px
  margin-bottom: 10px

  span:first-child
    color: #737c85

.waiting__card-comment
  display: block

  span:first-child
    color: #737c85
</style>
