<template>
  <input
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="type"
      class="input"
      :class="{'input--error': error}"
  />
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: [String, Number, Date],
    type: {
      default: 'text',
      validator: value => {
        return ['text', 'email', 'password', 'url', 'number', 'datetime-local', 'search'].includes(value)
      }
    },
    error: Boolean,
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.input
  width: 100%
  padding: 15px 25px 13px 16px
  border: 1px solid $color-border
  border-radius: 10px
  color: $color-text-base
  background-color: $color-bg-third
  font-family: $font-family-base
  font-size: 16px
  font-weight: 400
  line-height: 20px
  transition: border-color .3s

  &::placeholder
    color: $color-text-placeholder

    +max-h($laptop-big)
      font-size: 15px

  &:focus
    border-color: $color-theme

  &[readonly]
    pointer-events: none
    border-color: #ff9416

.input--maxw
  max-width: 300px

.input--minw
  min-width: 315px

.input--120
  max-width: 120px
  padding: 15px 16px 13px
  -moz-appearance: textfield

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

.input--450
  max-width: 450px

.input--error
  border-color: $color-error

.input--center
  text-align: center
</style>
