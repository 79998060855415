<template>
  <div class="content">
    <div class="expired">
      <h1 class="title title--indent">Просроченные депозиты</h1>
      <div v-if="expiredList.length" class="expired__list">
        <div v-for="booking in expiredList" :key="booking.id" class="expired__item">
          <div class="expired__row">
            <div class="expired__info">
              <div class="expired__key-value">
                <span>Гость: </span>
                <span>{{ booking.guest ? `${booking.guest.name} (ID: ${booking.guest.id})` : '-' }}</span>
              </div>
              <div class="expired__key-value">
                <span>Телефон: </span>
                <span>{{ booking.guest ? booking.guest.phone : '-' }}</span>
              </div>
              <div class="expired__key-value">
                <span>Столы: </span>
                <span>{{ booking.table_number }}</span>{{ booking.additional_tables_number.length ? ',' : '' }}
                <template v-if="booking.additional_tables_number.length">
                <span v-for="(additional_t, index) in booking.additional_tables_number" :key="additional_t">
                  {{ additional_t }}{{ index !== booking.additional_tables_number.length - 1 ? ',' : '' }}
                </span>
                </template>
              </div>
              <div class="expired__key-value"><span>Кол-во гостей: </span><span>{{ booking.count_guests }}</span></div>
              <div class="expired__key-value"><span>ID бронирования: </span><span>{{ booking.id }}</span></div>
              <div class="expired__key-value">
                <span>Дата: </span>
                <span>{{ formatDate(booking.time_start) }} - {{ booking.short_time_end }}</span>
              </div>
              <div class="expired__key-value">
                <span>Сумма: </span>
                <span>{{ new Intl.NumberFormat('ru-RU').format(booking.deposit_amount) }} ₽</span>
              </div>
              <div class="expired__key-value">
                <span>Срок оплаты: </span>
                <span>{{ formatDate(booking.pay_due_date) }}</span>
              </div>
            </div>
            <div class="expired_btns">
              <app-button @click="onShowModal('prolong', booking.id)" size="small">Продлить</app-button>
              <app-button @click="onShowModal('cancel', booking.id)" theme="error" size="small">Отменить</app-button>
            </div>
          </div>
          <div v-if="booking.comment_guest" class="expired__row">
            <div class="expired__key-value"><span>Гость: </span><span>{{ booking.comment_guest }}</span></div>
          </div>
          <div v-if="booking.comment_hostes" class="expired__row">
            <div class="expired__key-value"><span>Хостес: </span><span>{{ booking.comment_hostes }}</span></div>
          </div>
        </div>
      </div>
      <div v-else class="logs-plug">
        <img src="@/assets/img/logs-empty-plug.svg" alt="">
        <div class="logs-plug__text">Просроченные депозиты отсутствуют</div>
      </div>
    </div>
    <modal
      name="accept-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="$modal.hide('accept-modal')" class="modal__close"></button>
        </div>
        <div class="modal__title  modal__title--center">{{ accept_modal_title }}</div>
        <app-cells position="center">
          <div class="modal__body modal__body--center modal__body--text-center">
            {{ accept_modal_text }}
          </div>
        </app-cells>
        <template v-if="modalType === 'prolong'">
          <div
            class="modal__body modal__body--center modal__body--text-center"
          >
            Отправить повторное SMS-сообщение?
          </div>
          <app-cells position="center">
            <div class="radio-tags">
              <label
                class="radio-tags__item"
                :class="{ 'radio-tags__item--active': send_sms === true }"
              >
                <input type="radio" :value="true" v-model="send_sms" name="sendsms">
                Да
              </label>
              <label
                class="radio-tags__item"
                :class="{ 'radio-tags__item--active': send_sms === false }"
              >
                <input type="radio" :value="false" v-model="send_sms" name="sendsms">
                Нет
              </label>
            </div>
          </app-cells>
        </template>
        <app-cells position="center">
          <app-button
            @click="onActionBooking"
            size="normal"
            type="button"
            ref="accept"
          >
            {{ accept_modal_btn_text }}
          </app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { getPaymentExpired, postPaymentExpired } from '@/http'
import { dateFormatting } from '@/helpers'

export default {
  name: 'PaymentExpired',
  components: {},

  data() {
    return {
      expiredList: [],
      modalType: '',
      accept_modal_title: '',
      accept_modal_text: '',
      accept_modal_btn_text: '',
      send_sms: false,
      current_booking: null,
      payment_duration: process.env.VUE_APP_BASIC_PAYMENT_DURATION,
    }
  },
  created() {
    this.getExpiredPayment()
  },
  methods: {
    getExpiredPayment() {
      getPaymentExpired()
        .then(response => {
          this.expiredList = response.data
        })
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal')
    },
    onShowModal(type, id) {
      this.modalType = type
      this.current_booking = id
      if (type === 'prolong') {
        this.accept_modal_title = 'Продлить срок оплаты депозита'
        this.accept_modal_text = `Вы собираетесь продлить срок оплаты депозита на ${this.payment_duration} часов. Пожалуйста, подтвердите действие и укажите, необходимо ли повторно отправить SMS с ссылкой на оплату.`
        this.accept_modal_btn_text = 'Подтвердить продление'
      } else {
        this.accept_modal_title = 'Отмена бронирования в связи с неоплатой депозита'
        this.accept_modal_text = 'Вы собираетесь отменить бронирование и установить в качестве причины “Не оплатил депозит”. Пожалуйста, проверьте все данные. Восстановить бронирование будет невозможно.'
        this.accept_modal_btn_text = 'Отменить бронирование'
      }
      this.$modal.show('accept-modal')
    },
    onActionBooking() {
      const data = {
        action: this.modalType
      }
      if (this.modalType === 'prolong') data.send_sms = this.send_sms
      postPaymentExpired(this.current_booking, data)
        .then(() => {
          this.getExpiredPayment()
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: this.modalType === 'prolong' ? 'Срок оплаты продлен' : 'Бронь отменена '
          })
        })
        .finally(() => {
          this.$refs.accept.preload = false
          this.$modal.hide('accept-modal')
          this.send_sms = false
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.logs-plug
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 20px
  padding: 50px 10px

.logs-plug__text
  color: #737c85
  font-weight: 600
</style>
