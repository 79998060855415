<template>
  <div class="forgot">
    <div class="forgot__title">Забыли пароль?</div>
    <div class="forgot__text">
      <p>Для того, чтобы восстановить пароль от своего личного кабинета обратитесь к администратору ресторана.</p>
      <p>Если администратор ресторана утратил доступ, восстановить можно через техническую поддержку, контакты указаны слева.</p>
    </div>
    <div class="forgot__back">
      <router-link :to="{ name: 'login' }" class="btn btn--link">Вернуться к авторизации</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forgot'
}
</script>

<style lang="sass" scoped>
.forgot
  width: 100%

.forgot__title
  margin-bottom: 20px
  font-size: 24px
  font-weight: 700

.forgot__text
  p
    margin-bottom: 10px

.forgot__back
  position: absolute
  bottom: 30px
  left: 50%
  transform: translateX(-50%)

</style>
