import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRole: '',
    sidebarScrollTop: 0
  },
  mutations: {
    setUserRole(state, payload) {
      if (payload.id === 1) state.userRole = 'admin'
      else if (payload.id === 2) state.userRole = 'manager'
      else state.userRole = 'hostess'
    },
    setSidebarScrollTop(state, payload) {
      state.sidebarScrollTop = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
