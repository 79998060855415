<template>
  <div class="extend-slide">
    <app-form-group>
      <div class="extend-slide__list">
        <button
          v-for="time in times"
          :key="time.id"
          @click="onHandleClick(time)"
          type="button"
          class="extend-slide__item"
          :class="{ 'extend-slide__item--checked': time.id === checked_time.id }"
        >
          {{ time.hours ? `${time.hours}ч` : '' }} {{ time.minutes ? `${time.minutes}м` : '' }}
        </button>
      </div>
    </app-form-group>
  </div>
</template>

<script>
import { patchBooking } from '@/http'
import { addHours, addMinutes, formatISO, getTime } from 'date-fns'
import { dateFormatting } from '@/helpers'

export default {
  name: 'AppExtendSlideBooking',
  props: {
    booking_slide_effect: String,
    booking: Object
  },
  data() {
    return {
      times: [
        { id: 1, hours: '', minutes: '15' },
        { id: 2, hours: '', minutes: '30' },
        { id: 3, hours: '', minutes: '45' },
        { id: 4, hours: '1', minutes: '' },
        { id: 5, hours: '1', minutes: '15' },
        { id: 6, hours: '1', minutes: '30' },
        { id: 7, hours: '1', minutes: '45' },
        { id: 8, hours: '2', minutes: '' },
        { id: 9, hours: '2', minutes: '15' },
        { id: 10, hours: '2', minutes: '30' },
        { id: 11, hours: '2', minutes: '45' },
        { id: 12, hours: '3', minutes: '' },
        { id: 13, hours: '3', minutes: '15' },
        { id: 14, hours: '3', minutes: '30' },
        { id: 15, hours: '3', minutes: '45' },
        { id: 16, hours: '4', minutes: '' },
      ],
      checked_time: {}
    }
  },
  methods: {
    onHandleClick(time) {
      let title = '', text = '', btnText = ''

      if (this.booking_slide_effect === 'slide') {
        title = 'Изменить время начала бронирования'
        text = `Вы собираетесь изменить время начала бронирования на ${time.hours ? `${time.hours}ч ` : ''}${time.minutes}м вперед. Пожалуйста, проверьте все данные и подтвердите изменения.`
        btnText = 'Сдвинуть бронирование'
      }
      if (this.booking_slide_effect === 'extend') {
        title = 'Продление бронирования'
        text = `Вы собираетесь продлить данное бронирование на ${time.hours ? `${time.hours}ч ` : ''}${time.minutes}м. Пожалуйста, проверьте все данные перед подтверждением.`
        btnText = 'Продлить бронирование'
      }

      this.checked_time = time
      this.$emit('onShowAcceptModal', title, text, btnText, this.booking_slide_effect)
    },
    sendForm() {
      if (this.booking_slide_effect === 'extend') {
        let time_end = this.booking.time_end

        time_end = addHours(new Date(time_end), this.checked_time.hours)
        time_end = addMinutes(new Date(time_end), this.checked_time.minutes)

        patchBooking(this.booking.id, { time_end: formatISO(time_end) })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: `Время брони продлилось на ${this.checked_time.hours ? `${this.checked_time.hours}ч` : ''} ${this.checked_time.minutes}мин`
            })
            this.$emit('onExtendSlideClose', 'patched')
          })
      } else if (this.booking_slide_effect === 'slide') {
        let time_start = this.booking.time_start
        const time_end = this.booking.time_end

        time_start = addHours(new Date(dateFormatting(time_start, 'normal-to-iso')), this.checked_time.hours)
        time_start = addMinutes(new Date(time_start), this.checked_time.minutes)

        if (getTime(new Date(time_start)) <= getTime(new Date(time_end))) {
          patchBooking(this.booking.id, { time_start })
            .then(() => {
              this.$notify({
                type: 'success',
                title: 'Успех',
                text: `Время брони сдвинуто на ${this.checked_time.hours ? `${this.checked_time.hours}ч` : ''} ${this.checked_time.minutes}мин`
              })
              this.$emit('onExtendSlideClose', 'patched')
            })
        } else {
          this.$notify({
            type: 'warn',
            title: 'Внимание',
            text: 'Время начала брони не должно быть больше времени окончания'
          })
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.extend-slide__list
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 4px

.extend-slide__item
  display: flex
  justify-content: center
  align-items: center
  height: 63px
  padding-top: 2px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: #f3f7fd
  font-size: 16px
  font-weight: 600

.extend-slide__item--checked
  border-color: $color-theme

</style>
