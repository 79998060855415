<template>
  <div class="booking">
    <h1 v-if="!bookingSuccess" class="payment__title">Онлайн-бронирование</h1>
    <template v-if="step === 1 && !bookingSuccess">
      <app-form-group label="Зал" required>
        <v-select
          v-model="form.hall"
          :options="halls_options"
          :filterable="false"
          :clearable="false"
          :searchable="false"
          @input="onChangeHall"
          label="name"
          placeholder="Выберите зал"
          class="select"
        >
          <template #open-indicator>
            <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
              <path
                d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                fill="#fff"></path>
            </svg>
          </template>
          <template #option="option">
            <div class="select__item d-center">{{ option.name }}</div>
          </template>
          <template #selected-option="option">
            <div class="selected d-center">{{ option.name }}</div>
          </template>
        </v-select>
      </app-form-group>
      <div class="booking__controls">
        <app-form-group label="Дата бронирования:" required>
          <VueCtkDateTimePicker
            @input="onChangeDate"
            v-model="form.date_value"
            id="booking-date-picker"
            class="booking-date-picker"
            :min-date="min_date"
            format="DD.MM.YYYY"
            formatted="D MMMM YYYY"
            label="Выберите дату"
            color="#2f73ff"
            button-color="#2f73ff"
            locale="ru"
            overlay
            noClearButton
            no-header
            no-label
            no-button
            only-date
            auto-close
            :disabled="!form.hall"
          />
        </app-form-group>
        <app-form-group label="Кол-во человек:" label-for="person" required>
          <input
            @change="onChangePerson"
            v-model.number="form.count_guests"
            id="person"
            type="number"
            min="1"
            class="booking__person"
            :disabled="!form.hall"
          >
        </app-form-group>
      </div>
      <template v-if="times.length && !times_request_need">
        <app-form-group label="Выбор времени:">
          <div class="booking__times" :class="{ 'booking__times--loading': loadTimes }">
            <label
              v-for="(time, index) of times"
              :key="index"
              class="booking__time"
              :class="{ 'booking__time--active': time === form.time_value }"
            >
              <input
                :value="time"
                v-model="form.time_value"
                type="radio"
                name="time"
              >
              {{ time }}
            </label>
          </div>
        </app-form-group>
        <app-form-group label="Выбор меню:" required>
          <v-select
            v-model="menuChoiceResult"
            :options="menuChoiceOptions"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите меню"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
        </app-form-group>
        <app-form-group label="Пожелания к бронированию:" label-for="comment">
          <app-textarea v-model="form.guest_comment" id="comment" placeholder="Будем отмечать день рождения, нужно будет вынести торт" />
        </app-form-group>
        <div class="payment__footer">
          <app-button
            @click="onChangeStep(2)"
            size="normal"
            :disabled="!form.date_value || !form.time_value || !form.count_guests || !menuChoiceResult"
          >
            Продолжить
          </app-button>
        </div>
      </template>
      <div v-if="!times.length && !times_request_need" class="booking__alert" :class="{ 'booking__alert--loading': loadTimes }">
        Ваша бронь требует большего внимания. Пожалуйста, позвоните в ресторан
        по номеру: <span v-for="(phone, index) in sitePhone" :key="index">
          <a :href="`tel:${phone}`">{{ phone }}</a><span v-if="index !== sitePhone.length - 1">, </span>
        </span>
      </div>
    </template>
    <template v-if="step === 2 && !bookingSuccess">
      <button @click="onChangeStep(1)" class="booking__back">
        <img src="@/assets/img/arrow-back.svg" alt="arrow back">
        <span>Назад</span>
      </button>
      <app-form-group label="Ваше бронирование:">
        <p>{{ parsedDate }} в {{ form.time_value }}, столик на {{ form.count_guests }} {{ form.count_guests > 1 ? 'человек' : 'человека' }}</p>
      </app-form-group>
      <app-form-group v-if="form.guest_comment" label="Пожелания к бронированию:">
        <span>Меню: {{ menuChoiceResult.name }}. </span>{{ form.guest_comment }}
      </app-form-group>
      <app-form-group label="Имя:" label-for="name" required>
        <app-input
          @change.native="$v.form.guest_name.$touch()"
          v-model="form.guest_name"
          id="name"
          placeholder="Введите ваше имя"
          :error="$v.form.guest_name.$error"
        />
        <template #error>
          <div v-if="$v.form.guest_name.$dirty && !$v.form.guest_name.required">Обязательное поле</div>
        </template>
      </app-form-group>
      <app-form-group label="Телефон" required>
        <app-phone
          @paste.native.prevent
          @change.native="$v.form.guest_phone.$touch()"
          v-model="form.guest_phone"
          placeholder="Введите телефон"
          :error="$v.form.guest_phone.$dirty && (!$v.form.guest_phone.required || (form.guest_phone === 0))"
        />
        <template #error>
          <div v-if="$v.form.guest_phone.$dirty && !$v.form.guest_phone.required">Обязательное поле</div>
          <div v-if="$v.form.guest_phone.$dirty && (form.guest_phone === 0) && $v.form.guest_phone.required">
            Неправильный формат номера
          </div>
        </template>
      </app-form-group>
      <app-form-group label="Email:" label-for="email" required>
        <app-input
          @change.native="$v.form.guest_email.$touch()"
          v-model="form.guest_email"
          placeholder="Введите ваш email"
          :error="$v.form.guest_email.$error"
        />
        <template #error>
          <div v-if="$v.form.guest_email.$dirty && !$v.form.guest_email.email">Неправильный формат электронной почты</div>
          <div v-if="$v.form.guest_email.$dirty && !$v.form.guest_email.required">Обязательное поле</div>
        </template>
      </app-form-group>
      <!--<div class="payment__desc">-->
      <!--  * Данное бронирование предполагает депозит в размере 3000 руб с человека-->
      <!--</div>-->
      <div class="payment__checkbox">
        <label class="checkbox">
          <span class="checkbox__text">Ознакомлен с
            <router-link :to="{name: 'terms'}" target="_blank">правилами онлайн бронирования<span style="color: #ea5467;">*</span></router-link>
          </span>
          <input
            type="checkbox"
            v-model="termsAgree"
          />
          <span class="checkbox__checkmark"></span>
        </label>
      </div>
      <div class="payment__footer">
        <app-button
          @click="onCheckForm"
          size="normal"
          :disabled="!termsAgree || $v.form.$error"
          ref="submit"
        >
          Забронировать
        </app-button>
      </div>
    </template>

    <template v-if="bookingSuccess">
      <div class="payment__success">
        <img src="@/assets/img/clock-icon.svg" alt="success">
      </div>
      <div class="payment__title">Ваше бронирование принято, ожидайте подтверждения!</div>
      <div class="payment__some-grid">
        <div class="payment__some-block">
          <p>Дата:</p>
          <p>{{ generateBookingTime(postCreateData.time_start, postCreateData.time_end) }}</p>
        </div>
        <div class="payment__some-block">
          <p>Персон:</p>
          <p>{{ postCreateData.count_guests }}</p>
        </div>
        <div v-if="postCreateData.comment_guest" class="payment__some-block">
          <p>Комментарий к бронированию:</p>
          <p>{{ postCreateData.comment_guest }}</p>
        </div>
      </div>
      <div class="payment__note">
        <div class="payment__note-title">Дорогие гости!</div>
        <div class="payment__note-text">
          Заполнение формы на сайте не является финальным подтверждением бронирования. Пожалуйста, дождитесь звонка от представителя ресторана для уточнения и подтверждения деталей.
          <br>
          <b>Ваш номер заявки - {{ postCreateData.id }}</b>
        </div>
      </div>
      <div class="payment__footer">
        <a :href="siteMain" target="_blank" class="btn btn--default">Спасибо</a>
        <app-button
          @click="onBookAgain"
          size="normal"
          ref="bookAgain"
          theme="accept"
        >
          Новое бронирование
        </app-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getTimes, postPublicBooking, getPublicHalls } from '@/http'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { format, parse } from 'date-fns'
import ru from 'date-fns/locale/ru'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Booking',
  components: {
    VueCtkDateTimePicker
  },
  data() {
    return {
      step: 1,
      min_date: format(new Date(), 'yyyy-MM-dd'),
      termsAgree: false,
      times: [],
      loadTimes: false,
      bookingSuccess: false,
      form: {
        date_value: format(new Date(), 'dd.MM.yyyy'),
        time_value: '',
        count_guests: 1,
        guest_name: '',
        guest_phone: '',
        guest_email: '',
        guest_comment: '',
        hall: '',
      },
      postCreateData: {},
      menuChoiceOptions: [],
      menuChoiceResult: '',
      halls_options: [],
      times_request_need: true,
    }
  },
  validations: {
    form: {
      guest_name: { required },
      guest_phone: { required },
      guest_email: { required, email },
    }
  },
  created() {
    getPublicHalls()
      .then(response => {
        this.halls_options = response.data
      })
  },
  computed: {
    parsedDate() {
      return format(parse(this.form.date_value, 'dd.MM.yyyy', new Date()), 'd MMMM, EEEE', {locale: ru})
    },
    sitePhone() {
      return process.env.VUE_APP_TERMS_SITE_PHONE.split(',')
    },
    siteMain() {
      return process.env.VUE_APP_MAIN_RESTAURANT
    },
  },
  methods: {
    onBookAgain() {
      this.$refs.bookAgain.preload = true
      this.form.date_value = format(new Date(), 'dd.MM.yyyy')
      this.form.time_value = ''
      this.form.count_guests = 1
      this.form.guest_comment = ''
      this.form.hall = ''
      this.times = []
      this.$v.form.$reset()
      this.$refs.bookAgain.preload = false
      this.step = 1
      this.bookingSuccess = false
    },
    onChangeDate() {
      if (!this.form.count_guests) return
      this.getTimes(this.form.date_value, this.form.count_guests, this.form.hall.id)
    },
    onChangePerson () {
      if (!this.form.count_guests) this.form.count_guests = 1
      this.getTimes(this.form.date_value, this.form.count_guests, this.form.hall.id)
    },
    onChangeHall() {
      if (!this.form.count_guests) return
      this.getTimes(this.form.date_value, this.form.count_guests, this.form.hall.id)
      this.menuChoiceResult = ''
      this.menuChoiceOptions = this.form.hall.menu
      if (this.menuChoiceOptions.length === 1) this.menuChoiceResult = this.menuChoiceOptions[0]
    },
    onChangeStep(step) {
      this.step = step
      window.scroll(0, 0)
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      let data = null
      this.$refs.submit.preload = true
      data = {
        ...this.form,
        hall: this.form.hall.id,
        menu: this.menuChoiceResult.id
      }
      postPublicBooking(data)
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(res => {
          this.postCreateData = res.data
          this.times_request_need = true
          this.bookingSuccess = true
        })
    },
    generateBookingTime(start, end) {
      return `${format(new Date(start), 'dd.MM.yyyy')} с ${format(new Date(start), 'HH:mm')} по ${format(new Date(end), 'HH:mm')}`
    },
    getTimes(date, person, hall) {
      this.form.time_value = ''
      this.loadTimes = true
      if (this.times_request_need) this.times_request_need = false
      getTimes(date, person, hall)
        .then(response => {
          this.loadTimes = false
          if (response.data.length) {
            this.times = response.data
          } else {
            this.times = []
          }
        })
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.booking
  width: 100%
  max-width: 740px
  padding: 0 100px

  +max-w($mobile_xl)
    padding: 0 50px

  +max-w($mobile_lg)
    max-width: 100%
    padding: 0 15px

.booking__controls
  display: grid
  grid-template-columns: 320px 174px
  justify-content: space-between
  gap: 0 10px

  +max-w($mobile_md)
    grid-template-columns: 260px 174px

  +max-w($mobile_sm)
    grid-template-columns: 1fr
    margin-bottom: 20px

.booking-date-picker
  width: 320px
  margin: 0

  +max-w($mobile_md)
    width: 260px

  +max-w($mobile_sm)
    width: 100%

  .field-input.no-clear-button
    padding: 0 16px 0 16px
    border-radius: 10px
    background-image: url('~@/assets/img/calendar-grey.svg')
    background-repeat: no-repeat
    background-position: right 16px center

  .field.is-disabled .field-input.no-clear-button
    background-color: transparent
    background-image: url('~@/assets/img/calendar-grey.svg')
    background-repeat: no-repeat
    background-position: right 16px center

  .field-input
    height: 50px
    border: 1px solid $color-border
    color: $color-text-base
    background-color: $color-bg-third
    font-family: $font-family-base
    font-size: 16px

    &::placeholder
      color: $color-text-placeholder

  button
    text-align: center

.booking__person
  width: 100%
  padding: 15px 16px
  border: 1px solid $color-border
  border-radius: 10px
  background-color: $color-bg-third
  font-size: 16px
  transition: border-color 0.3s

  @media (hover: hover)
    &:focus
      border-color: $color-theme

  &:disabled
    border-color: #ccc

.booking__times
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr))
  gap: 12px
  transition: opacity 0.3s

.booking__times--loading
  opacity: 0.5
  pointer-events: none

.booking__time
  padding: 15px 0
  border-radius: 10px
  color: $color-text-base
  background-color: #e6eaf4
  font-size: 16px
  font-weight: 600
  text-align: center
  cursor: pointer
  transition: color 0.3s, background-color 0.3s

  input
    +visually-hidden

.booking__time--active
  color: #fff
  background-color: $color-theme

.booking__alert
  margin-bottom: 20px
  padding: 30px 15px
  border-radius: 10px
  background-color: #e6eaf4
  font-size: 16px
  text-align: center
  transition: opacity 0.3s

  a
    color: $color-theme
    text-decoration: none
    transition: color 0.3s

    &:active
      color: $status-open

    @media (hover: hover)
      &:hover,
      &:focus
        color: $status-open

.booking__alert--loading
  opacity: 0.5
  pointer-events: none

.booking__back
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 16px
  font-size: 16px
</style>
