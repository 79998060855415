import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import VueTables from 'vue-tables-2-premium'
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate'
import XmlViewer from 'vue-xml-viewer'
import CompositionApi from '@vue/composition-api'
const VueInputMask = require('vue-inputmask').default
import VModal from 'vue-js-modal'

import DefaultLayout from './layouts/Default'
import AuthLayout from './layouts/Auth'
import PaymentLayout from './layouts/Payment'

Vue.component('default', DefaultLayout)
Vue.component('auth', AuthLayout)
Vue.component('payment', PaymentLayout)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('XmlViewer', XmlViewer)

Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueTables.ClientTable)
Vue.use(VueInputMask)
Vue.use(CompositionApi)
Vue.use(VModal)
