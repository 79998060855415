<template>
  <div class="changes">
    <template v-if="logs.length">
      <div
        v-for="log of logsList"
        :key="log.datetime"
        class="changes__item"
        :class="{'changes__item--open': log.opened}"
      >
        <button
          @click="onLogClick(log)"
          class="changes__header"
        >
          <span class="changes__name">{{ log.who }},</span>
          <span class="changes__date">{{ parseDate(log.datetime) }}</span>
          <span class="changes__action">{{ log.action }}</span>
          <span class="changes__arrow"></span>
        </button>
        <transition name="fade">
        <span v-show="log.opened" class="changes__content">
        <span class="changes__line changes__line--top">
          <span>Поле:</span>
          <span>Старое значение: </span>
          <span>Новое значение:</span>
        </span>
        <span
          v-for="(change, index) of log.changes"
          :key="index"
          class="changes__line"
        >
          <span>{{ change.field_text }}:</span>
          <span>{{ change.old_value && change.old_value !== 'None' ? change.old_value : '-' }}</span>
          <span>{{ change.new_value && change.new_value !== 'None' ? change.new_value : '-' }}</span>
        </span>
      </span>
        </transition>
      </div>
    </template>
    <div v-else class="logs-plug">
      <img src="@/assets/img/logs-empty-plug.svg" alt="">
      <div class="logs-plug__text">Информация для данного раздела еще не предоставлена</div>
    </div>
  </div>
</template>

<script>
import { dateFormatting } from '@/helpers'

export default {
  name: 'AppChanges',
  props: {
    logs: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      logsList: [],
    }
  },
  watch: {
    logs() {
      this.logsList = this.logs.map(log => ({...log, opened: false}))
    }
  },
  methods: {
    parseDate(date) {
      return dateFormatting(date, 'iso-to-normal')
    },
    onLogClick(log) {
      log.opened = !log.opened
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.changes__item
  margin-bottom: 16px
  border-radius: 10px
  overflow: hidden

  &:last-child
    margin-bottom: 0

.changes__item--open
  .changes__arrow
    transform: scale(-1)

.changes__header
  position: relative
  display: flex
  width: 100%
  gap: 8px
  padding: 15px 20px
  font-size: 16px
  background-color: #fff
  transition: background-color 0.3s
  z-index: 1

  @media (hover: hover)
    &:hover,
    &:focus
      background-color: #d6e5fc

.changes__arrow
  position: absolute
  top: 17px
  right: 20px
  width: 16px
  height: 16px
  background-image: url("~@/assets/img/change-arrow.svg")
  transition: transform 0.1s

.changes__name
  color: $color-text-base
  font-weight: 600

.changes__date
  color: $color-text-base

.changes__action
  color: $status-new
  font-weight: 600

.changes__content
  display: flex
  flex-direction: column
  padding: 9px 20px 15px
  background-color: #fff

.changes__line
  display: grid
  grid-template-columns: repeat(3, 1fr)
  margin-bottom: 8px
  padding-bottom: 8px
  border-bottom: 1px solid $color-bg

  &:last-child
    margin-bottom: 0
    padding-bottom: 0
    border: none

.changes__line--top
  margin-bottom: 16px
  border: none
  color: #737c85
  font-weight: 600

.fade-enter-active, .fade-leave-active
  transition: opacity 0.1s, transform 0.1s

.fade-enter, .fade-leave-to
  opacity: 0
  transform: translateY(-100%)

.logs-plug
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 20px
  padding: 50px 10px

.logs-plug__text
  color: #737c85
  font-weight: 600
</style>
