<template>
  <div class="terms">
    <h1 class="payment__title">Правила онлайн бронирования столов в ресторане «{{ site_name }}»</h1>
    <div class="terms__content">
      <h2>1. Терминология</h2>
      <p>
        1.1. Онлайн-бронирование – сформированный и оплаченный Заказ на предварительное резервирование свободного стола
        в ресторане на определенную дату и время.
      </p>
      <p>1.2. {{ point_1_2 }}</p>
      <p>1.3. Посетитель – физическое лицо, достигшее 18-летнего возраста.</p>
      <p>1.4. {{ point_1_4 }}</p>
      <p>1.5. Заказ – оформление онлайн-бронирования на сайте
        <a :href="site_url" target="_blank">{{ site_url }}</a>.
      </p>
      <p>
        1.6. Акцепт - полное и безоговорочное принятие Посетителем условий настоящих Правил.
      </p>
      <h2>2. Общие положения</h2>
      <p>
        2.1. Предметом настоящих правил является оказание Исполнителем дополнительных услуг Посетителю по бронированию
        стола в Ресторане на определенную дату и время.
      </p>
      <p>
        2.2. Стоимость дополнительной услуги, указанной в п. 2.1. настоящих Правил, составляет сумму равную стоимости
        онлайн-бронирования стола, в соответствии с п. 4.5. настоящих Правил.
      </p>
      <p>
        2.3. Оформление дополнительных услуг осуществляется исключительно по настоящим правилам и в случае ее полного и безоговорочного акцепта.
      </p>
      <h2>3. Порядок и условия Акцепта</h2>
      <p>
        3.1. Посетитель соглашается с тем, что до совершения им действия по акцепту, установленных настоящими правилами,
        он ознакомился с настоящими правилами. Посетитель подтверждает, что положения настоящих правил ему полностью понятны.
      </p>
      <p>
        3.2. Акцепт совершается Посетителем путем совершения онлайн-бронирования и оплаты Заказа на сайте
        <a :href="site_url" target="_blank">{{ site_url }}</a>.
      </p>
      <p>
        3.3. После оплаты Заказа, Посетителю направляется на контактный номер мобильного телефона и/или адрес
        электронной почты, указанных при оформлении Заказа, уведомление о статусе брони.
      </p>
      <h2 >4. Порядок бронирования и внесения денежных средств</h2>
      <p>
        4.1. Посетитель оформляет заказ на онлайн-бронирование стола на сайте
        <a :href="site_url" target="_blank">{{ site_url }}</a>.
      </p>
      <p>
        4.2. В соответствующем разделе сайта Покупатель осуществляет время и дату бронирования, количество персон, на
        которое необходимо забронировать стол. Посетитель вправе выбрать определенный зал Ресторана, а также в
        комментариях указать дополнительные пожелания к забронированному столу.
      </p>
      <p>
        4.3. При оформлении Заказа Посетитель обязан внести необходимую о себе информацию: ФИО, контактный номер телефона, адрес электронный почты.
      </p>
      <p>
        4.4. Заказ считается оформленным только после внесения 100 % оплаты. Оплата Заказа должна быть произведена в
        течение 5 минут после оформления Заказа, в противном случае, онлайн-бронирование отменяется.
      </p>
      <p>4.5. Размер оплаты Заказа зависит от количества персон.</p>
      <p>
        4.6. Датой оплаты считается дата зачисления денежных средств на корреспондентский счет банка Исполнителя.
      </p>
      <p>
        4.7. При оплате Посетитель автоматически переадресовывается на сайт защищенной системы электронных платежей
        (далее – платежная система). На сайте платежной системы Посетителю потребуется ввести информацию о своей
        платежной карте и произвести оплату Заказа. Эти данные (платежной карты) будут переданы по безопасному протоколу
        непосредственно на Авторизационный Сервер и являются недоступными для Ресторана. Сервис «CloudPayments»
        сертифицированный сервис-провайдер с максимальным уровнем соответствия PCI DSS, предоставляющим право хранить
        данные платежных карт и обрабатывать более 6 миллионов платежей ежегодно. Подтверждение соответствия проходит
        каждый год в рамках сертификационного аудита, что гарантирует безопасность безналичной оплаты через сеть Интернет.
      </p>
      <p>
        4.8. Временной интервал бронирования столов составляет: с 13-00 до 22-00. Заказ будет действителен в течение
        20 минут, после заявленного в Заказе времени.
      </p>
      <p>
        4.9. По истечении 20 минут после указанного времени бронирования Посетитель будет размещен при наличии свободных
        столов в Ресторане. Денежные средства, внесенные за Заказ, будут зачитываться при оплате счета.
      </p>
      <p>4.10. Оформленный Посетителем Заказ гарантирует бронирование стола в течение 2 часов в
        указанные в Заказе дату и время. В случае необходимости, Посетитель вправе забронировать стол на более длительное
        время, указав данный параметр на этапе оформления Заказа «Выбор даты и места».
      </p>
      <p>4.11. Внесенные денежные средства за Заказ засчитываются в стоимость счета Посетителя.</p>
      <p>
        4.12. Посетитель вправе изменить дату и время онлайн-бронирования, но не позднее, чем за 24 часа до времени,
        на которое было осуществлено бронирование.
      </p>
      <h2>5. Отмена бронирования и возврат денежных средств</h2>
      <p>
        5.1. Для отмены бронирования Посетителю необходимо связаться с менеджером ресторана по контактному номеру
        телефона: <span v-for="(phone, index) in sitePhone" :key="index">
          <a :href="`tel:${phone}`">{{ phone }}</a><span v-if="index !== sitePhone.length - 1">, </span>
        </span>
        или перейти по специальной ссылке, отправленной ранее на адрес
        электронной почты, указанной при оформлении Заказа.
      </p>
      <p>
        5.2. В случае своевременного отказа или переноса времени Посетителя от Бронирования Исполнитель возвращает
        стоимость Заказа в полном объеме в течение 10 банковских дней с даты отказа и уведомления об этом Исполнителя.
        Своевременным считается отказ, произведенный Посетителем не позднее, чем за 24 (двадцать четыре) часа до времени,
        на которое было осуществлено бронирование.
      </p>
      <p>
        5.3. В случае несвоевременного отказа Покупателя от Бронирования, менее чем за 24 (двадцать четыре) часа до
        времени бронирования, указанного в Заказе, с Посетителем согласовывается любые другие дата и время Бронирования.
      </p>
      <p>5.4. В заявлении на возврат денежных средств необходимо указать следующие данные:</p>
      <ul>
        <li>Дата и время бронирования;</li>
        <li>Номер телефона, указанный при бронировании;</li>
        <li>Адрес электронной почты, указанный при бронировании;</li>
        <li>Последние 4 цифры номер карты, с которой осуществлялась оплата Заказа;</li>
        <li>Номер заказа;</li>
        <li>Номер транзакции.</li>
      </ul>
      <p>5.5. После отмены Заказа Посетителю придет уведомление об отмене Онлайн-бронирования.</p>
      <h2>6. Ответственность</h2>
      <p>
        6.1. Исполнитель не несет ответственности за указание неправильного адреса электронной почты, реквизитов
        банковской карты и номера телефона Посетителя. Указанные данные должны быть проверены Посетителем до момента оплаты Заказа.
      </p>
      <p>
        6.2. В случае выявления несоответствия между оплаченным Заказом и уведомлением о произведенной оплате,
        Посетитель должен заблаговременно обратиться в службу поддержки банка для подтверждения транзакции, а затем к
        Исполнитель для разрешения возникшей ситуации.
      </p>
      <h2>7. Дополнительные условия</h2>
      <p>
        7.1. Совершая заказ, в соответствии с положениями ФЗ от 27.07.2006г. № 152- ФЗ «О персональных данных»
        Покупатель признает и соглашается на обработку персональных данных. Исполнитель гарантирует, что полученные
        сведения не будут переданы третьим лицам.
      </p>
      <p>7.2. Срок использования предоставленных персональных данных – бессрочно.</p>
      <p>
        7.3. Обработка персональных данных осуществляется в соответствии с положениями ФЗ от 27.07.2006г. № 152- ФЗ
        «О персональных данных» и включает в себя сбор, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), сортировку, использование, обезличивание, блокирование, уничтожение.
      </p>
      <p>
        7.4. Посетитель настоящим дает свое согласие Исполнителю на предоставление прав на передачу и обработку
        его персональных данных, третьим лицам.
      </p>
      <p>
        7.5. Настоящие правила вступают в силу с момента их размещения на Сайте Исполнителя и действуют бессрочно.
      </p>
      <p>
        7.6. Все изменения, вносимые в настоящие правила подлежат размещению на сайте Исполнителя и вступают в силу с
        момента их размещения.
      </p>
      <p>
        7.7. Исполнитель вправе в любое время вносить изменения в настоящие Правила, но в любом случае данные изменения
        публикуются на сайте Исполнителя.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  data() {
    return {
      point_1_2: process.env.VUE_APP_TERMS_1_2,
      point_1_4: process.env.VUE_APP_TERMS_1_4,
      site_url: process.env.VUE_APP_TERMS_SITE_URL,
      site_name: process.env.VUE_APP_TERMS_SITE_NAME,
    }
  },
  computed: {
    sitePhone() {
      return process.env.VUE_APP_TERMS_SITE_PHONE.split(',')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.terms
  padding: 0 30px

  +max-w($laptop)
    padding: 0 15px

.terms__content
  max-height: 50vh
  overflow-y: auto
  overflow-x: hidden
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar-track
    border-radius: 3px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 3px
    background-color: rgba($color-theme, 0.15)

  &::-webkit-scrollbar
    width: 7px
    height: 20px

  +max-w($laptop)
    overflow: hidden
    max-height: unset

  h2
    margin-bottom: 25px
    color: #030405
    font-size: 24px
    font-weight: 600

    +max-w($laptop)
      margin-bottom: 20px
      font-size: 22px

  p
    margin-bottom: 20px
    color: #030405
    font-size: 16px
    font-weight: 600

    &:last-child
      margin-bottom: 0

  a
    color: $color-theme
    transition: color 0.3s
    text-decoration: none

    &:active
      color: $status-open

    @media (hover: hover)
      &:hover,
      &:focus
        color: $status-open

  ul
    margin-bottom: 20px

    li
      position: relative
      margin-bottom: 10px
      padding-left: 17px
      color: #030405
      font-size: 16px
      font-weight: 600

      &:last-child
        margin-bottom: 0

      &:before
        content: ''
        position: absolute
        left: 0
        top: 6px
        width: 8px
        height: 8px
        border-radius: 50%
        background-color: $color-theme
</style>
