<template>
  <div class="table-reservations">
    <div v-if="getBookings.length > 0" class="table-reservations__list">
      <transition-group name="fade" tag="div">
        <button
          v-for="booking in getBookings"
          :key="booking.id"
          @click="$emit('onGetBooking', booking)"
          type="button"
          :class="['table-reservations__item', `table-reservations__item--${booking.status.id}`]"
        >
          <div class="table-reservations__row">
            <span class="flex">
              <img v-if="!booking.guest" src="@/assets/img/warning-triangle-icon.svg" alt=""> {{ booking.guest ? booking.guest.name : 'Гость не указан' }}, {{ booking.count_guests }}
            </span>
            <span class="no-shrink">{{ booking.short_time_start }} - {{ booking.short_time_end }}</span>
          </div>
          <div v-if="booking.guest && booking.guest.phone" class="table-reservations__row">
            <span>{{ booking.guest.phone }}</span>
          </div>
          <div class="table-reservations__row table-reservations__row--nomb">
            <span class="tables">
              Столы: <span :class="{ 'is-main': booking.additional_tables.length }">{{ booking.table_number }}</span>{{ booking.additional_tables.length ? ',' : '' }}
              <template v-if="booking.additional_tables.length">
                <span v-for="(additional_t, index) in booking.additional_tables_number" :key="additional_t.id">
                  {{ additional_t }}{{ index !== booking.additional_tables.length - 1 ? ',' : '' }}
                </span>
              </template>
              <img v-if="booking.is_fixed_table" src="@/assets/img/lock.svg" alt="">
              <img v-if="booking.is_overbooking" src="@/assets/img/overbooking.svg" alt="">
            </span>
            <span v-if="booking.deposit_amount" class="depo">
              Депозит: <span :class="{ 'is-paid': booking.is_paid }">{{ new Intl.NumberFormat('ru-RU').format(booking.deposit_amount) }} ₽</span>
            </span>
          </div>
          <div class="table-reservations__footer">
            <div class="table-reservations__footer-wrapper">
              <div v-if="is_today && booking.status.id === 'new'" class="table-reservations__info-status  table-reservations__info-status--new">
                <img src="@/assets/img/hall/time-new-icon.svg" alt=""> <span>{{ getNewTime(booking.time_start) }}</span>
              </div>
              <div v-if="is_today && booking.status.id === 'wait'" class="table-reservations__info-status  table-reservations__info-status--wait">
                <img src="@/assets/img/hall/time-wait-icon.svg" alt=""> <span>{{ getWaitTime(booking.time_start) }}</span>
              </div>
              <div v-if="is_today && booking.status.id === 'open'" class="table-reservations__info-status  table-reservations__info-status--close">
                <img v-if="isClosure(booking.time_end) <= 0" src="@/assets/img/hall/time-closure-icon.svg" alt="">
                <img v-else src="@/assets/img/hall/to-close-icon.svg" alt="">
                <span>{{ getOpenTime(booking.time_end) }}</span>
              </div>
              <div v-if="is_today && booking.is_fast" class="table-reservations__info-status  table-reservations__info-status--is-fast">
                <img src="@/assets/img/hall/location.svg" alt=""> <span>Офлайн</span>
              </div>
            </div>
            <div class="table-reservations__b-number">ID: {{ booking.id }}</div>
          </div>
        </button>
      </transition-group>
    </div>
    <div v-else class="table-reservations__plug">
      <img src="@/assets/img/empty-bookings-icon.svg" alt="">
      <div>На выбранный стол еще нет броней</div>
    </div>
  </div>
</template>

<script>
import { differenceInMinutes } from 'date-fns'

export default {
  name: 'AppTableReservations',
  props: {
    bookings: Array,
    is_today: Boolean
  },
  computed: {
    getBookings() {
      return this.bookings
    }
  },
  methods: {
    getNewTime(time_start) {
      const difference = differenceInMinutes(new Date(time_start), new Date())
      return this.calculateTime(difference)
    },
    getWaitTime(time_start) {
      const difference = differenceInMinutes(new Date(), new Date(time_start))
      return this.calculateTime(difference)
    },
    getOpenTime(time_end) {
      const difference = differenceInMinutes(new Date(time_end), new Date())
      return this.calculateOpenTime(difference)
    },
    calculateTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    calculateOpenTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      if (difference <= 0) return 'Закрытие'
      else return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    isClosure(time_end) {
      return differenceInMinutes(new Date(time_end), new Date())
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.table-reservations__item
  position: relative
  width: 100%
  margin-top: 10px
  padding: 12px 14px 36px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third
  color: $color-text-base
  font-size: 16px
  overflow: hidden

.table-reservations__item--wait
  background-color: #faf7e4

.table-reservations__item--open
  background-color: #fae4e4

.table-reservations__row
  display: flex
  justify-content: space-between
  gap: 0 15px
  margin-bottom: 10px

  &:first-child
    font-weight: 600

  .no-shrink
    flex-shrink: 0

  .is-main
    color: $status-wait

  .flex
    display: flex
    align-items: center
    gap: 0 6px

  .tables
    max-width: 55%
    color: $color-theme

    img
      margin-right: 3px
      margin-bottom: -3px

  .depo
    color: #737c85

    span
      color: #eb5757

      &.is-paid
        color: $status-new

.table-reservations__row--nomb
  align-items: center
  margin-bottom: 0

.table-reservations__plug
  padding: 50px 0
  text-align: center

  img
    margin-bottom: 20px

  div
    color: $color-border
    font-size: 14px

.table-reservations__footer
  position: absolute
  bottom: 0
  left: 0
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%

.table-reservations__info-status
  display: inline-flex
  align-items: center
  gap: 0 6px
  padding: 6px 11px 5px
  border-top-right-radius: 10px
  font-size: 14px
  font-weight: 600

  &:first-child
    position: relative

  &:not(:first-child)
    margin-left: -7px

  img
    width: 19px

.table-reservations__info-status--is-fast
  background-color: #b4b7bf
  color: #fff

.table-reservations__info-status--new
  background-color: $color-theme
  color: #fff

.table-reservations__info-status--wait
  background-color: $status-wait

.table-reservations__info-status--close
  background-color: $color-error
  color: #fff

.table-reservations__b-number
  padding: 6px 11px 5px
  border-top-left-radius: 10px
  background-color: $color-theme
  color: #fff
  font-size: 14px

.fade-enter-active, .fade-leave-active
  opacity: 0
  transition: opacity 0.3s

.fade-enter, .fade-leave-to
  opacity: 0

</style>
