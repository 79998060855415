import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from 'axios-jwt'
import Login from '@/views/auth/Login'
import Forgot from '@/views/auth/Forgot'
import Hall from '@/views/Hall/Index'
import Bookings from '@/views/Bookings/Index'
import Guests from '@/views/Guests/Index'
import Settings from '@/views/Settings/Layout'
import Users from '@/views/Users'
import Stat from '@/views/Stat'
import Payment from '@/views/Payment'
import Terms from '@/views/Terms'
import PublicBooking from '@/views/PublicBooking'
import Waiting from '@/views/Waiting'
import Analytics from '@/views/Analytics/Layout'
import PaymentExpired from '@/views/PaymentExpired'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'hall' }
  },
  {
    path: '/hall',
    name: 'hall',
    component: Hall,
    meta: {
      title: 'Схема зала'
    }
  },
  {
    path: '/bookings',
    component: Bookings,
    children: [
      {
        path: '',
        name: 'bookings-list',
        component: () => import('@/views/Bookings/List'),
        meta: {
          title: 'Бронирования'
        },
      },
      {
        path: ':id',
        name: 'booking-detail',
        component: () => import('@/views/Bookings/DetailLayout'),
        redirect: { name: 'booking-detail-info' },
        meta: {
          title: 'Бронь'
        },
        children: [
          {
            path: 'info',
            name: 'booking-detail-info',
            component: () => import('@/views/Bookings/Detail'),
            meta: {
              title: 'Бронь'
            },
          },
          {
            path: 'changes',
            name: 'booking-detail-changes',
            component: () => import('@/views/Bookings/Changes'),
            meta: {
              title: 'Бронь'
            },
          },
          {
            path: 'logs',
            name: 'booking-detail-request',
            component: () => import('@/views/Bookings/Request'),
            meta: {
              title: 'Бронь'
            },
          },
          {
            path: 'sms',
            name: 'booking-detail-sms',
            component: () => import('@/views/Bookings/SMS'),
            meta: {
              title: 'Бронь'
            },
          },
          {
            path: 'email',
            name: 'booking-detail-email',
            component: () => import('@/views/Bookings/Email'),
            meta: {
              title: 'Бронь'
            },
          },
        ]
      },
    ]
  },
  {
    path: '/guests',
    component: Guests,
    children: [
      {
        path: '',
        name: 'guests-list',
        component: () => import('@/views/Guests/List'),
        meta: {
          title: 'Гостевая книга'
        },
      },
      {
        path: ':id',
        name: 'guest-detail',
        component: () => import('@/views/Guests/DetailLayout'),
        redirect: { name: 'guest-detail-info' },
        meta: {
          title: 'Гость'
        },
        children: [
          {
            path: 'info',
            name: 'guest-detail-info',
            component: () => import('@/views/Guests/Detail'),
            meta: {
              title: 'Гость'
            },
          },
          {
            path: 'changes',
            name: 'guest-detail-changes',
            component: () => import('@/views/Guests/Changes'),
            meta: {
              title: 'Гость'
            },
          },
          {
            path: 'sms',
            name: 'guest-detail-sms',
            component: () => import('@/views/Guests/SMS'),
            meta: {
              title: 'Гость'
            },
          },
          {
            path: 'email',
            name: 'guest-detail-email',
            component: () => import('@/views/Guests/Email'),
            meta: {
              title: 'Гость'
            },
          },
        ]
      },
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    redirect: { name: 'deposits' },
    children: [
      {
        path: 'deposits',
        name: 'deposits',
        component: () => import('@/views/Settings/Deposits'),
        meta: {
          title: 'Депозиты'
        },
      },
      {
        path: 'bookings',
        name: 'online-bookings',
        component: () => import('@/views/Settings/Bookings'),
        meta: {
          title: 'Онлайн бронирования'
        },
      },
      {
        path: 'tables',
        name: 'tables',
        component: () => import('@/views/Settings/Tables'),
        meta: {
          title: 'Столы'
        },
      },
      {
        path: 'halls',
        name: 'halls',
        component: () => import('@/views/Settings/Halls'),
        meta: {
          title: 'Залы'
        },
      },
    ]
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Пользователи'
    }
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: Waiting,
    meta: {
      title: 'Лист ожидания'
    },
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stat,
    meta: {
      title: 'Статистика'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'auth',
      title: 'Авторизация'
    },
  },
  {
    path: '/password-forgot',
    name: 'password-forgot',
    component: Forgot,
    meta: {
      layout: 'auth',
      title: 'Забыли пароль?'
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      layout: 'payment',
      title: 'Правила бронирования'
    },
  },
  {
    path: '/booking',
    name: 'booking',
    component: PublicBooking,
    meta: {
      layout: 'payment',
      title: 'Бронирование'
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    redirect: { name: 'analytic-guests' },
    children: [
      {
        path: 'guests',
        name: 'analytic-guests',
        component: () => import('@/views/Analytics/Guests'),
        meta: {
          title: 'Аналитика - Гости'
        },
      },
      {
        path: 'fin',
        name: 'analytic-fin',
        component: () => import('@/views/Analytics/Fin'),
        meta: {
          title: 'Аналитика - Финансы'
        },
      },
      {
        path: 'refund-requests',
        name: 'analytic-refund',
        component: () => import('@/views/Analytics/Refund'),
        meta: {
          title: 'Аналитика - Запросы на возврат'
        },
      },
    ]
  },
  {
    path: '/p/:id',
    name: 'payment',
    component: Payment,
    meta: {
      layout: 'payment',
      title: 'Оплата бронирования'
    },
  },
  {
    path: '/p',
    redirect: to => {
      window.location.replace(process.env.VUE_APP_MAIN_RESTAURANT)
    },
  },
  {
    path: '/booking-deposit-expired',
    name: 'payment-expired',
    component: PaymentExpired,
    meta: {
      title: 'Просроченные депозиты'
    },
  },
  {
    path: '*',
    redirect: { name: 'hall' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = isLoggedIn()

  if (loggedIn) {
    if (['login', 'password-forgot', '404'].includes(to.name)) next({ name: 'hall' })
    else next()
  } else {
    if (['login', 'password-forgot', '404', 'payment', 'terms', 'booking'].includes(to.name)) next()
    else next({ name: 'login' })
  }
  document.title = `${ to.meta.title } | BMS - ${process.env.VUE_APP_RESTARAUNT_TITLE}`
})

export default router
