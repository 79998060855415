<template>
  <div :class="['sidebar-hall', { 'sidebar-hall--footer-hide': is_hide_footer }]">
    <div class="sidebar-hall__content">
      <div ref="sidebar_wrapper" class="sidebar-hall__wrapper">
        <div ref="sidebar_header" class="sidebar-hall__header">
          <slot name="header"></slot>
        </div>
        <div ref="sidebar_main" class="sidebar-hall__main" :style="{ 'max-height': height + 'px' }">
          <slot />
        </div>
      </div>
      <div ref="sidebar_footer" class="sidebar-hall__footer">
        <slot name="footer"></slot>
      </div>
      <button @click="$emit('open-sidebar')" type="button" class="sidebar-hall__open"></button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: ['title', 'is_hide_footer'],
  data() {
    return {
      height: 0
    }
  },
  mounted () {
    this.setHeight()
    window.addEventListener('resize', debounce(this.setHeight, 250))
  },
  methods: {
    setHeight() {
      const header = this.$refs.sidebar_header
      const main = this.$refs.sidebar_main
      const footer = this.$refs.sidebar_footer

      if (main) main.scrollTo(0, 0)
      if (header && footer) {
        setTimeout(() => {
          if (window.innerWidth > 1200) this.height = window.innerHeight - (130 + header.clientHeight + footer.clientHeight)
          else this.height = window.innerHeight - (80 + header.clientHeight + footer.clientHeight)
        }, 0)
      }
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.sidebar-hall
  position: fixed
  top: 0
  right: 0
  z-index: 100
  width: 100%
  height: 100%
  pointer-events: none

.sidebar-hall--footer-hide
  .sidebar-hall__main
    padding-bottom: 0

  .sidebar-hall__footer
    min-height: 0
    margin: 0
    padding: 0

.sidebar-hall--full
  .sidebar-hall__content
    transform: translateX(0%)
    pointer-events: auto
    transition: transform 0.3s

  .sidebar-hall__open
    display: none

  .sidebar-hall__footer
    border-top: 1px solid #c4c4c4

  &.sidebar-hall--footer-hide
    .sidebar-hall__footer
      border: none

.sidebar-hall__content
  z-index: 1
  position: absolute
  top: 0
  right: 0
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 450px
  height: 100%
  padding: 110px 16px 0
  background-color: #fff
  transform: translateX(calc(100% - 15px))
  transition: transform 0.3s

  +max-w($laptop-big)
    width: 400px
    padding-top: 70px

.sidebar-hall__open
  position: absolute
  top: 50%
  left: -22px
  z-index: 9999
  display: block
  width: 44px
  height: 44px
  border-radius: 50%
  background-image: url('~@/assets/img/arrow-sidebar-left.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: 50%
  background-color: #fff
  cursor: pointer
  pointer-events: visible

.sidebar-hall__title
  flex-shrink: 1
  font-size: 28px
  font-weight: 600

.sidebar-hall__top
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 30px

.sidebar-hall__close
  svg path
    transition: fill 0.3s

  &:hover
    svg path
      fill: #49B1F5

  &:active
    svg path
      fill: #177FD3

.sidebar-hall__form
  margin-bottom: 30px

.sidebar-hall__wrapper
  flex-grow: 1

.sidebar-hall__main
  height: 100%
  margin-right: -16px
  padding-right: 16px
  padding-bottom: 15px
  overflow-y: auto
  transition: transform 0.5s
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.sidebar-hall__header
  padding-bottom: 20px

.sidebar-hall__footer
  min-height: 62px
  margin: 0 -16px
  padding: 15px 16px

</style>
