<template>
  <div class="status" :class="[ `status--${status}` ]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppStatus',
  props: {
    status: {
      type: String,
      default: 'new'
    },
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.status
  display: block
  padding: 7px 19px
  border: 1px solid
  border-radius: 10px
  text-align: center

.status--cancel
  border-color: $status-close
  color: $status-close
  background-color: rgba($status-close, 0.2)

.status--close
  border-color: $status-close
  color: $status-close
  background-color: rgba($status-close, 0.2)

.status--new
  border-color: $status-new
  color: $status-new
  background-color: rgba($status-new , 0.2)

.status--open
  border-color: $status-close
  color: $status-close
  background-color: rgba($status-close, 0.2)

.status--wait
  border-color: $status-wait
  color: $status-wait
  background-color: rgba($status-wait, 0.2)

.status--is_fast
  border-color: #c4cdd7
  color: #737c85
  background-color: rgba(#737c85, 0.2)

.status--notifications
  border-color: #a971c2
  color: #a971c2
  background-color: rgba(169, 113, 194, 0.2)

.status--blacklist
  border-color: #040d26
  color: #fff
  background-color: #040d26
</style>
