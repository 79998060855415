<template>
  <div class="content">
    <div class="stat">
      <template v-if="userRole !== 'hostess'">
        <h1 class="title title--indent">Депозиты</h1>
        <div class="stat__cards">
          <div class="stat__card">
            <span class="stat__card-name">Всего:</span>
            <span class="stat__card-sum">{{ stat.payments.paid_total }} / {{ formatSum(stat.payments.paid_total_sum) }} ₽</span>
          </div>
          <div class="stat__card">
            <span class="stat__card-name">Возвраты:</span>
            <span class="stat__card-sum">
            {{ stat.payments.is_refund || 0 }} / {{ formatSum(stat.payments.is_refund_sum) }} ₽
          </span>
          </div>
          <div class="stat__card">
            <span class="stat__card-name">Сегодня / Вчера:</span>
            <span class="stat__card-sum">
            {{ formatSum(stat.payments.is_paid_today) }} ₽ / {{ formatSum(stat.payments.is_paid_yesterday) }} ₽
          </span>
          </div>
          <div class="stat__card">
            <span class="stat__card-name">Нет в r_keeper:</span>
            <span class="stat__card-sum">
            {{ stat.payments.no_rk_uid || 0 }} / {{ formatSum(stat.payments.no_rk_uid_sum) }} ₽
          </span>
          </div>
          <div class="stat__card">
            <span class="stat__card-name">Не реализовано:</span>
            <span class="stat__card-sum">{{ stat.payments.not_implemented }} / {{ formatSum(stat.payments.not_implemented_sum) }} ₽</span>
          </div>
        </div>
      </template>
      <h1 class="title title--indent">График бронирований</h1>
      <div class="stat__tabs">
        <button
          v-for="tab of tabs"
          :key="tab.status"
          @click="onSwitchTab(tab.status)"
          class="stat__tab"
          :class="{'stat__tab--active': currentTab === tab.status}"
        >
          {{ tab.name }}
        </button>
      </div>
      <div class="stat__head">
        <span>Дата</span>
        <span>Гости</span>
        <span>Брони</span>
        <span>Новые</span>
        <span>Б / Д</span>
        <span>Д</span>
        <span>Д / +</span>
        <span>Д / -</span>
      </div>
      <button
        class="stat__item"
        @click="onStatClick(key)"
        v-for="(item, key, index) in stat.plan"
        :key="key"
        :class="{ 'stat__item--green': item.not_viewed > 0, 'stat__item--disabled': currentTab === 'past' || index === 0 }"
      >
        <span>{{ formatDate(key) }}</span>
        <span>{{ item.count_guests }}</span>
        <span>{{ item.count_bookings }}</span>
        <span>{{ item.not_viewed }}</span>
        <span>{{ item.without_deposit }}</span>
        <span>{{ item.with_deposit }}</span>
        <span>{{ item.payed }} ({{ formatSum(item.payed_sum) }} ₽)</span>
        <span>{{ item.not_payed }} ({{ formatSum(item.not_payed_sum) }} ₽)</span>
      </button>
    </div>
  </div>
</template>

<script>
import { getStat } from '@/http'
import { dateFormatting } from '@/helpers'

export default {
  name: 'Stat',
  data() {
    return {
      stat: {
        payments: {},
        plan: {},
      },
      tabs: [
        {
          name: 'Предстоящие',
          status: 'future',
        },
        {
          name: 'Прошедшие',
          status: 'past',
        },
      ],
      currentTab: 'future',
    }
  },
  created() {
    this.getStatistic()
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
  },
  methods: {
    getStatistic() {
      getStat(this.currentTab)
        .then(response => {
          this.stat = response.data
        })
    },
    formatSum(value) {
      if (!value) return 0
      return new Intl.NumberFormat('ru-RU').format(value)
    },
    onStatClick (date) {
      this.$router.push({ name: 'hall', params: { date_from_stat: `${this.formatDate(date)} 10:00` } })
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal-small')
    },
    onSwitchTab(tab) {
      this.currentTab = tab
      this.getStatistic()
    }
  }
}
</script>
