<template>
  <div class="content">
    <h1 class="title">Пользователи</h1>
    <app-cells position="start">
      <app-button @click="onAddButtonHandler" type="button" size="link">
        <img src="@/assets/img/add-icon.svg" alt="">
        Добавить пользователя
      </app-button>
    </app-cells>
    <app-cells position="between">
      <app-filters
        v-model="filterActive"
        :filters="filtersActive"
        name="filterActive"
        @change.native="fetchItems('', 1, 1000, filterActive)"
      />
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по телефону, имени"
        class="select select--minw"
      >
        <template #open-indicator>
          <img src="@/assets/img/search-icon.svg" alt="">
        </template>
      </v-select>
    </app-cells>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="h__first_name" class="table-default__left">Имя</div>
      <div slot="first_name" slot-scope="props" class="table-default__left">
        {{ props.row.first_name }} {{ props.row.last_name }}
      </div>
      <div slot="groups" slot-scope="props">
        <div v-for="item in props.row.groups" :key="item.id">{{ item.name }}</div>
      </div>
      <div slot="active" slot-scope="props">
        {{ props.row.active ? 'Активен' : 'Неактивен' }}
      </div>
    </v-client-table>
    <app-sidebar-right
      :title="!isAdd ? 'Редактирование пользователя' : 'Добавление пользователя'"
      :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Имя" label-for="first_name" required>
          <app-input
            v-model="sidebarData.first_name"
            placeholder="Введите имя"
            id="first_name"
            :error="$v.sidebarData.first_name.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.first_name.$dirty && !$v.sidebarData.first_name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Фамилия" label-for="last_name">
          <app-input
            v-model="sidebarData.last_name"
            placeholder="Введите фамилию"
            id="last_name"
          />
        </app-form-group>
        <app-form-group label="Телефон" required>
          <app-phone
            v-model="sidebarData.phone_number"
            placeholder="Введите телефон"
            :error="$v.sidebarData.phone_number.$dirty && (!$v.sidebarData.phone_number.required || (sidebarData.phone_number === 0))"
            ref="phonePicker"
            @paste.native.prevent
          />
          <template #error>
            <div v-if="$v.sidebarData.phone_number.$dirty && !$v.sidebarData.phone_number.required">Обязательное поле</div>
            <div v-if="$v.sidebarData.phone_number.$dirty && (sidebarData.phone_number === 0) && $v.sidebarData.phone_number.required">
              Неправильный формат номера
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Пароль" :required="isAdd">
          <app-input
            v-model="sidebarData.password"
            :type="show_pass ? 'text' : 'password'"
            placeholder="Введите пароль"
            :error="$v.sidebarData.password.$error"
            @change.native="$v.sidebarData.password.$touch()"
          />
          <template #right_place>
            <button
              @click="show_pass = !show_pass"
              type="button"
            >
              <img v-if="show_pass" src="@/assets/img/eye-open.svg" alt="">
              <img v-else src="@/assets/img/eye-close.svg" alt="">
            </button>
          </template>
          <template v-if="isAdd" #error>
            <div v-if="$v.sidebarData.password.$dirty && !$v.sidebarData.password.required">Обязательное поле</div>
            <div v-if=" $v.sidebarData.password.$dirty && !$v.sidebarData.password.minLength">
              Длина пароля менее 4 символов
            </div>
          </template>
        </app-form-group>
        <app-form-group label="Электронная почта" label-for="email">
          <app-input
            v-model="sidebarData.email"
            placeholder="Введите электронную почту"
            id="email"
            :error="$v.sidebarData.email.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.email.$dirty && !$v.sidebarData.email.email">Неправильный формат электронной почты</div>
          </template>
        </app-form-group>
        <app-form-group label="Группы" required>
          <v-select
            v-model="sidebarData.groups"
            :reduce="item => item.id"
            :options="groupsList"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите группу"
            class="select select--multiple"
            :class="{ 'select--error': $v.sidebarData.groups.$error }"
            multiple
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.sidebarData.groups.$dirty && !$v.sidebarData.groups.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group>
          <app-checkbox
            label="Активность"
            v-if="!isAdd"
            v-model="sidebarData.active"
          />
        </app-form-group>
        <app-cells>
          <app-button v-if="!isAdd" ref="submit" :disabled="$v.sidebarData.$error || sidebarData.phone_number === 0">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error || sidebarData.phone_number === 0">Добавить</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
  </div>
</template>

<script>
import { getGroups, getUsers, putUser, postUser } from '@/http'
import { debounce, isObject } from 'lodash'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'Tables',
  components: {},

  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'first_name', 'phone_number', 'email', 'groups', 'active'],
        options: {
          headings: {
            'id': 'ID',
            'first_name': 'Имя',
            'phone_number': 'Телефон',
            'email': 'Электронная почта',
            'groups': 'Группы',
            'active': 'Активность'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 1000,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      filterActive: 'true',
      filtersActive: [
        {
          value: '',
          label: 'Все',
        },
        {
          value: 'true',
          label: 'Активный',
        },
        {
          value: 'false',
          label: 'Неактивный',
        },
      ],
      show_sidebar: false,
      sidebarData: {},
      groupsList: [],
      isAdd: false,
      phone_number: '',
      show_pass: false
    }
  },
  validations() {
    const data = {
      first_name: { required },
      phone_number: { required },
      password: { required, minLength: minLength(4) },
      email: { email },
      groups: { required }
    }
    if (!this.isAdd) {
      data.password = { minLength: minLength(4) }
    } else {
      data.password = { required, minLength: minLength(4) }
    }
    return { sidebarData: data }
  },
  created () {
    this.fetchItems()
    getGroups().then(res => { this.groupsList = res.data })
  },
  methods: {
    fetchItems(search = '', page = 1, page_size = 1000, active = this.filterActive) {
      getUsers({ page, page_size, search, active }).then(res => {
        this.table.items = res.data.results
      })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(search, 1, 1000, vm.filterActive)
      loading(false)
    }, 350),
    onRowClick(element) {
      this.isAdd = false
      this.show_sidebar = true
      this.sidebarData = { ...element.row }
    },
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.isAdd) {
        putUser(this.sidebarData.id, this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Пользователь обновлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      } else {
        postUser(this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Пользователь добавлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.sidebarData }

      normalizedForm.groups = normalizedForm.groups.map(item => {
        if (typeof item === 'object') return item.id
        else return item
      })

      return normalizedForm
    },
    onAddButtonHandler() {
      this.sidebarData = {}
      this.$refs.phonePicker._data.phone = ''
      this.$v.sidebarData.$reset()
      this.isAdd = true
      this.show_sidebar = true
    },
  }
}
</script>
