<template>
  <vue-tel-input
      v-model="phone"
      defaultCountry="RU"
      mode="international"
      @input="onInput"
      :validCharactersOnly="true"
      type="tel"
      :inputOptions="phone_picker_options"
      :dropdownOptions="dropdown_options"
      :disabled="readonly"
      class="phonepicker"
      :class="{ 'phonepicker--error': error, 'phonepicker--readonly': readonly }"
  />
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'AppPhone',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Введите номер телефона'
    },
    readonly: Boolean,
    error: Boolean,
    required: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      phone: this.$props.value,
      isValid: true,
      phone_picker_options: {
        placeholder: this.$props.placeholder,
        required: this.$props.required,
      },
      dropdown_options: {
        showDialCodeInSelection: true,
        showFlags: true,
      }
    }
  },
  watch: {
    value() {
      if (this.$props.value) {
        this.phone = this.$props.value
      }
    }
  },
  methods: {
    onInput(number, obj) {
      if (this.phone) {
        if (obj.valid) {
          this.$emit('input', obj.number)
        } else if (obj.valid === undefined) {
          this.$emit('input', '')
        } else {
          this.$emit('input', 0)
        }
      }
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"
@import "~vue-tel-input/dist/vue-tel-input.css"

.phonepicker
  &.vue-tel-input
    height: 50px
    background-color: transparent
    border: 1px solid $color-border
    box-sizing: border-box
    border-radius: 10px
    font-family: $font-family-base
    font-size: 16px
    font-weight: 600
    line-height: 20px
    color: $color-text-base
    outline: none
    transition: border-color 0.3s

    &:focus-within
      box-shadow: none
      border-color: $color-theme

    &[disabled]
      pointer-events: none
      border-color: #EFF1F5

      .vti__dropdown
        opacity: 0.7

      input
        background-color: #fff

  .vti__input
    padding-left: 20px
    border-radius: 10px
    font-family: $font-family-base
    font-size: 16px
    font-weight: 400
    line-height: 20px
    color: $color-text-base
    background: $color-bg-third

    &::placeholder
      font-family: $font-family-base
      font-size: 16px
      font-weight: 400
      line-height: 20px
      color: $color-text-placeholder

    &[disabled]
      background-color: #fff

  .vti__dropdown
    pointer-events: none
    padding: 7px 16px
    border-radius: 9px 0 0 9px
    background-color: #e7ebf1
    outline: none
    transition: background-color 0.3s

    &:hover
      background-color: #adc8e4

    &.open
      background-color: rgba(43, 147, 231, 0.2)

      .vti__selection
        svg
          transform: rotate(180deg)

  .vti__dropdown-arrow
    display: none

  .vti__dropdown-list
    width: 290px
    background: #fff
    border-radius: 0 0 10px 10px
    border: none
    overflow-y: scroll
    scrollbar-color: $color-theme #f2f3f8
    scrollbar-width: thin

    &::-webkit-scrollbar
      width: 2px

    &::-webkit-scrollbar-track
      border-radius: 5px
      background-color: #f2f3f8

    &::-webkit-scrollbar-thumb
      border-radius: 5px
      background-color: $color-theme

  .vti__dropdown-list.below
    top: calc(100% + 2px)

  .vti__selection
    svg
      margin-left: 10px
      transition: transform 0.3s

    .vti__flag
      transform: scale(1.1)

    .vti__country-code
      color: $color-text-base

  .vti__dropdown-item
    display: flex
    align-items: center
    padding: 8px 15px
    font-size: 14px
    color: $color-text-base

    .vti__flag
      margin-right: 20px

    strong
      font-weight: 400

    span
      margin-left: 10px
      opacity: 0.5

  .vti__dropdown-item.highlighted
    border-radius: 0 20px 20px 0
    background-color: rgba(56, 123, 191, 0.1)

  .vti__country-code
    margin-left: 5px
    color: $color-text-base
    font-size: 16px
    font-weight: 600

.phonepicker--error
  &.vue-tel-input
    border-color: $color-error

    &:focus-within
      box-shadow: none
      border-color: $color-error

  .vti__dropdown
    background-color: $color-error

  .vti__selection
    svg path
      stroke: #fff

  .vti__selection .vti__country-code
    color: #fff

.phonepicker--readonly
  cursor: default

  .vti__dropdown
    pointer-events: none

  &.vue-tel-input
    //border-color: $color-readonly

  input.vti__input
    cursor: default
</style>
