<template>
  <div class="payment-layout">
    <img :src="`${base_url}static/front/bg/main.jpg`" alt="bg-img" class="payment-layout__bg">
    <div class="payment-layout__content">
      <div class="payment-layout__logo">
        <img :src="`${base_url}static/front/logos/logo_main.png`" alt="logo">
      </div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API_URL,
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.payment-layout
  position: relative
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  min-height: 100vh

.payment-layout__bg
  position: absolute
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center
  z-index: -1

.payment-layout__content
  flex-grow: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1028px
  min-height: 640px
  padding: 60px 0
  background-color: #f3f7fd

  +max-w($laptop-big)
    flex-grow: 0
    min-height: 100vh
    padding: 60px 0

  +max-w($mobile_xl)
    padding: 30px 0

  +max-w($mobile_sm)
    flex-grow: 1

.payment-layout__logo
  width: 193px
  margin-bottom: 40px
  line-height: 0

  +max-w($mobile_sm)
    margin-bottom: 30px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
