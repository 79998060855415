<template>
  <div class="divider">
    <span><slot /></span>
  </div>
</template>

<script>
export default {
  name: 'AppDivider'
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.divider
  margin-bottom: 12px
  overflow: hidden
  text-align: center

  span
    color: $color-border
    font-size: 14px
    font-weight: 600

    &:before,
    &:after
      content: ''
      display: inline-block
      width: 100%
      height: 1px
      background-color: $color-border
      vertical-align: middle

    &:before
      margin-left: -100%
      margin-right: 10px

    &:after
      margin-right: -100%
      margin-left: 10px
</style>
