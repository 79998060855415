<template>
  <div class="table-detail">
    <div class="table-detail__desc">
      <template v-if="table.events.length">
        <div
          v-for="(event, index) in table.events"
          :key="index"
          class="table-detail__row"
        >
          <template v-if="event.event_time === 'free' && index === 0">Свободен до: <span>{{ event.event_start }}</span></template>
          <template v-if="event.event_time === 'busy'">Занят до: <span>{{ event.event_end }}</span></template>
        </div>
        <div v-if="table.events.map(item => item.event_time).includes('free')" class="table-detail__row">
          <!--До брони: <span>{{ differenceInHours }}</span>-->
          Следующая бронь: <span>{{ rangeInHours }}</span>
        </div>
      </template>
      <div class="table-detail__row  table-detail__row--flex">
        Посадка: <span>{{ table.min_seat_number }} {{ table.max_seat_number === table.min_seat_number ? '' : `- ${table.max_seat_number}` }}</span>
        <img src="@/assets/img/person-blue.svg" alt="">
      </div>
    </div>
    <div :class="['table-detail__icon', `table-detail__icon--${table.status}`]" v-html="table.svg_code"></div>
  </div>
</template>

<script>
import { differenceInMinutes, parseISO } from 'date-fns'

export default {
  name: 'AppTableDetail',
  props: {
    data: Object
  },
  computed: {
    table() {
      return this.data
    },
    differenceInHours() {
      const eventFree = this.table.events.find(item => item.event_time === 'free')
      const minutes = differenceInMinutes(parseISO(eventFree.event_start_full), new Date())
      return this.getTimeFromMins(minutes)
    },
    rangeInHours() {
      const eventFree = this.table.events.find(item => item.event_time === 'free')
      return `${eventFree.event_start} - ${eventFree.event_end}`
    }
  },
  methods: {
    getTimeFromMins(mins) {
      const hours = Math.trunc(mins / 60)
      const minutes = mins % 60
      return `${hours}ч ${minutes}мин`
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.table-detail
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 16px
  padding-bottom: 20px
  border-bottom: 1px solid #c4c4c4

.table-detail__row
  margin-bottom: 12px
  color: #737c85

  span
    color: $color-theme

  img
    margin-top: -1px

.table-detail__row--flex
  display: flex
  align-items: center
  gap: 0 5px

.table-detail__icon svg
  width: 100px
  max-height: 110px
  height: auto

.table-detail__icon--wait
  svg
    filter: hue-rotate(260deg) brightness(1.15)

.table-detail__icon--busy,
.table-detail__icon--cancel,
.table-detail__icon--close
  svg
    filter: hue-rotate(200deg)
</style>
