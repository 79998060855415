<template>
  <header class="header">
    <div class="header__left">
      <router-link :to="{name: 'stats'}" class="header__button header__button--link">
        График <sup>{{ unread_bookings }}</sup>
      </router-link>
      <router-link :to="{name: 'payment-expired'}" v-if="payment_period_expired" class="header__button header__button--link">
        Не внесли депозит <sup>{{ payment_period_expired }}</sup>
      </router-link>
      <div class="header__text">
        <span>{{ user.first_name }}, </span>
        <span v-if="user.groups.length">{{ user.groups[0].name }}</span>
      </div>
    </div>
    <div class="header__right">
      <div class="header__text  header__text--with-icon" :class="{ 'header__text--red': balance_sms < 500 }">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4 0H1.6C.72 0 .008.72.008 1.6L0 16l3.2-3.2h11.2c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6ZM5.6 7.2H4V5.6h1.6v1.6Zm3.2 0H7.2V5.6h1.6v1.6Zm3.2 0h-1.6V5.6H12v1.6Z" fill="#fff"/></svg>
        <span>{{ balance_sms }} ₽</span>
      </div>
      <div class="header__text">Версия: 1.3.2</div>
      <button type="button" class="header__button" @click="showModal">Поддержка</button>
    </div>
    <modal
      name="sup-modal"
      :width="600"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="closeModal" class="modal__close"></button>
        </div>
        <div class="modal__title modal__title--center">Тех. поддержка</div>
        <div class="modal__body modal__body--center">
          <div class="modal__contacts">
            <div class="modal__contact">
              <div class="modal__icon modal__icon--email"></div>
              <div class="modal__desc">
                <span class="modal__contact-name">Email:</span>
                <a href="mailto:support@antonov.group" class="modal__link">support@antonov.group</a>
              </div>
            </div>
            <div class="modal__contact">
              <div class="modal__icon modal__icon--phone"></div>
              <div class="modal__desc">
                <span class="modal__contact-name">Телефон:</span>
                <a href="tel:+79280147272" class="modal__link">+7 (928) 014-72-72</a>
              </div>
            </div>
            <div class="modal__contact">
              <div class="modal__icon modal__icon--tg"></div>
              <div class="modal__desc">
                <span class="modal__contact-name">Telegram:</span>
                <a href="https://t.me/antonov_group" class="modal__link" target="_blank">@antonov_group</a>
              </div>
            </div>
          </div>
          <a href="https://antonovgroup.gitbook.io/savva/" target="_blank" class="btn btn--default btn--sm">Инструкция</a>
        </div>
      </div>
    </modal>
  </header>
</template>

<script>
import { clearAuthTokens } from 'axios-jwt'
import { getMe, getStatHeader } from '@/http'

export default {
  name: 'AppHeader',
  data() {
    return {
      user: {
        groups: [],
      },
      unread_bookings: null,
      balance_sms: null,
      payment_period_expired: null,
      timeout: null,
    }
  },
  created() {
    getMe()
      .then(response => {
        this.user = response.data
        if (response.data.groups.length > 0) this.$store.commit('setUserRole', response.data.groups[0])
      })
    this.pollingData()
  },
  methods: {
    closeModal() {
      this.$modal.hide('sup-modal')
    },
    showModal() {
      this.$modal.show('sup-modal')
    },
    pollingData() {
      getStatHeader()
        .then(response => {
          this.unread_bookings = response.data.unread_bookings
          this.balance_sms = response.data.balance_sms
          this.payment_period_expired = response.data.payment_period_expired
          this.timeout = setTimeout(() => {
            this.pollingData()
          }, 300000)
        })
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.header
  position: fixed
  z-index: 200
  width: calc(100% - 80px)
  display: flex
  align-items: center
  justify-content: space-between
  height: 81px
  padding: 16px 30px
  background-color: $color-text-base

  +max-w($laptop-big)
    width: calc(100% - 40px)
    height: 51px
    padding: 0 20px

.header__left,
.header__right
  display: flex
  align-items: center
  gap: 0 24px

.header__button
  position: relative
  padding: 13px 30px 11px
  border: 1px solid #fff
  border-radius: 10px
  color: #fff
  font-size: 16px
  cursor: pointer
  transition: opacity 0.3s

  +max-w($laptop-big)
    padding: 7px 20px 5px
    border-radius: 8px
    font-size: 14px

  &:active
    opacity: 0.7

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.7

  sup
    position: absolute
    top: 5px
    right: 12px
    display: inline-flex
    align-items: center
    justify-content: center
    min-width: 18px
    min-height: 18px
    padding: 3px 3px 2px
    font-size: 10px
    font-weight: 700
    line-height: 1
    background-color: $color-theme
    border-radius: 50%

    +max-w($laptop-big)
      top: 2px
      right: 2px

.header__button--link
  text-decoration: none
  line-height: normal

.header__text
  color: #fff

.header__text--with-icon
  display: flex
  align-items: center
  gap: 0 12px

.header__text--red
  color: $color-error

  svg
    animation: warningSms 1.5s infinite

    path
      fill: $color-error

@keyframes warningSms
  0%
    transform: translateY(0)
  10%
    transform: translateY(-2px)
  20%
    transform: translateY(0)
  35%
    transform: translateY(-2px)
  45%
    transform: translateY(0)
  100%
    transform: translateY(0)

</style>
