<template>
  <div class="sidebar-filters">
    <button
      @click="onFilterChecked('not-read')"
      type="button"
      class="sidebar-filters__button"
      :class="{ 'is-active': filter_checked === 'not-read', 'is-disabled': getNotReadBookings < 1 }"
    >

      <div class="sidebar-filters__wrapper">
        <img src="@/assets/img/hall/not-read.svg" alt="">
        <span class="sidebar-filters__count">{{ getNotReadBookings }}</span>
      </div>
      <div class="sidebar-filters__name">Подтвердить</div>
    </button>
    <button
      v-for="filter in filters"
      :key="filter.id"
      @click="onFilterChecked(filter.id)"
      type="button"
      class="sidebar-filters__button"
      :class="{ 'is-active': filter.id === filter_checked, 'is-disabled': filteredBookings(filter.id).length < 1 }"
    >
      <div class="sidebar-filters__wrapper">
        <img :src="require(`@/assets/img/hall/${filter.icon}`)" alt="">
        <span class="sidebar-filters__count">{{ filteredBookings(filter.id).length }}</span>
      </div>
      <div class="sidebar-filters__name">{{ filter.name }}</div>
    </button>
    <button
      @click="onFilterChecked('closure')"
      type="button"
      class="sidebar-filters__button"
      :class="{ 'is-active': filter_checked === 'closure', 'is-disabled': getClosureBookings < 1 }"
    >

      <div class="sidebar-filters__wrapper">
        <img src="@/assets/img/hall/closure.svg" alt="">
        <span class="sidebar-filters__count">{{ getClosureBookings }}</span>
      </div>
      <div class="sidebar-filters__name">Закрытие</div>
    </button>
  </div>
</template>

<script>
import { differenceInMinutes } from 'date-fns'

export default {
  name: 'AppSidebarFilters',
  props: {
    bookings: Array
  },
  data() {
    return {
      filters: [
        { id: 'new', icon: 'calendar-filter.svg', name: 'Новые' },
        { id: 'wait', icon: 'time-filter.svg', name: 'Ожидают' },
        { id: 'open', icon: 'chair-filter.svg', name: 'Занято' },
      ],
      filter_checked: 'new'
    }
  },
  created () {
    this.$emit('filterBookingsList', this.filter_checked)
  },
  computed: {
    getBookings() {
      return this.bookings
    },
    getNotReadBookings() {
      return this.getBookings.filter(item => !item.is_read).length
    },
    getClosureBookings() {
      return this.getBookings.filter(item => item.status.id === 'open' && differenceInMinutes(new Date(item.time_end), new Date()) <= 0).length
    }
  },
  methods: {
    filteredBookings(status) {
      if (status !== 'open') return this.getBookings.filter(item => item.status.id === status)
      else return this.getBookings.filter(item => item.status.id === 'open' && differenceInMinutes(new Date(item.time_end), new Date()) > 0)
    },
    onFilterChecked(id) {
      if (this.filter_checked !== id) this.filter_checked = id
      else this.filter_checked = ''
      this.$emit('filterBookingsList', this.filter_checked)
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.sidebar-filters
  display: flex
  justify-content: center
  align-items: center
  gap: 0 30px
  padding-top: 7px

  +max-w($laptop-big)
    gap: 0 20px

.sidebar-filters__button
  text-align: center
  line-height: 0

  &.is-active
    span
      background-color: $status-wait

  &.is-disabled
    pointer-events: none
    opacity: 0.5

.sidebar-filters__wrapper
  position: relative
  display: inline-block
  text-align: center

.sidebar-filters__count
  position: absolute
  top: -6px
  right: -17px
  display: inline-block
  min-width: 27px
  height: 16px
  padding: 1px 4px 0
  border-radius: 14px
  color: #fff
  font-size: 12px
  font-weight: 600
  line-height: 16px
  background-color: $color-theme
  text-align: center

.sidebar-filters__name
  margin-top: 4px
  color: #737c85
  font-size: 12px
  line-height: 1.3

</style>
