<template>
  <div class="content">
    <app-cells position="start"><h1 class="title">Настройки ресторана</h1></app-cells>
    <app-tabs :tabs="tabs" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SettingsLayout',
  data() {
    return {
      tabs: [
        { name: 'deposits', label: 'Расписание депозитов' },
        { name: 'online-bookings', label: 'Расписание онлайн - бронирований' },
        { name: 'tables', label: 'Столы' },
        { name: 'halls', label: 'Залы' },
      ]
    }
  },
}
</script>
