<template>
  <div class="auth-layout">
    <img :src="`${base_url}static/front/bg/main.jpg`" alt="bg-img" class="payment-layout__bg">
    <div class="auth-layout__body">
      <div class="auth-layout__static">
        <div class="auth-layout__main">
          <h1 class="auth-layout__title">Система управления бронированием</h1>
          <!--<a href="#" class="auth-layout__instruction">Скачать инструкцию</a>-->
          <div class="auth-layout__support">
            <div class="auth-layout__subt">Техническая поддержка:</div>
            <div class="auth-layout__list">
              <a href="tel:+79280147272" class="auth-layout__support-item  auth-layout__support-item--phone">
                +7 (928) 014-72-72
              </a>
              <a href="mailto:support@antonov.group" class="auth-layout__support-item  auth-layout__support-item--email">
                support@antonov.group
              </a>
              <a href="tg://resolve?domain=antonov_group" class="auth-layout__support-item  auth-layout__support-item--tg">
                @antonov_group
              </a>
            </div>
          </div>
        </div>
        <div class="auth-layout__footer">
          <a class="auth-layout__ag" href="https://antonov.group/" target="_blank">
            <img src="@/assets/img/ag-white-logo.svg" alt="">
          </a>
        </div>
      </div>
      <div class="auth-layout__content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API_URL,
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.auth-layout
  position: relative
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: center
  padding: 20px
  //background-image: url("~@/assets/img/auth-bg.jpg")
  //background-repeat: no-repeat
  //background-position: center
  //background-size: cover

.auth-layout__bg
  position: absolute
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center
  z-index: -1

.auth-layout__body
  display: flex
  max-width: 1110px
  width: 100%
  max-height: 590px
  height: 100%
  min-height: 500px

.auth-layout__static
  flex-grow: 1
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 100px 40px 28px
  background-image: url("~@/assets/img/auth-static-bg.jpg")
  background-repeat: no-repeat
  background-position: center
  background-size: cover

.auth-layout__content
  display: flex
  align-items: center
  justify-content: center
  position: relative
  width: 473px
  padding: 50px
  background-color: $color-bg-third

.auth-layout__ag
  transition: opacity 0.3s

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.8

.auth-layout__title
  margin-bottom: 20px
  font-size: 30px
  font-weight: 700
  color: #fff

.auth-layout__instruction
  display: inline-block
  margin-bottom: 30px
  padding-left: 25px
  font-weight: 600
  color: #fff
  background-image: url("~@/assets/img/doc-instruction-icon.svg")
  background-repeat: no-repeat
  background-position: left top 1px
  text-decoration: none
  transition: opacity 0.3s

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.8

.auth-layout__support
  margin-bottom: 30px

.auth-layout__subt
  margin-bottom: 16px
  font-size: 20px
  font-weight: 600
  color: #fff

.auth-layout__list
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 12px

.auth-layout__support-item
  padding-left: 25px
  color: #fff
  text-decoration: none
  background-repeat: no-repeat
  background-position: left center
  transition: opacity 0.3s

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.8

.auth-layout__support-item--phone
  background-image: url("~@/assets/img/phone-icon.svg")

.auth-layout__support-item--email
  background-image: url("~@/assets/img/email-icon.svg")

.auth-layout__support-item--tg
  background-image: url("~@/assets/img/tg-icon.svg")

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
