<template>
  <div class="bookings-search">
    <app-input
      v-model="input_search"
      @input.native="onInputSearch($event.target.value)"
      type="search"
      placeholder="Имя гостя, телефон, почта и ID брони"
      autofocus
    />
    <div class="bookings-search__body">
      <div v-if="bookings.length < 1" class="bookings-search__plug">
        <img src="@/assets/img/search-big-icon.svg" alt="">
        <div>Ничего не найдено</div>
      </div>
      <div v-else class="bookings-search__list">
        <div v-for="key in Object.keys(filteredDateBookings).sort()" :key="key" class="bookings-list__time">
          <app-divider>{{ key }}</app-divider>
          <app-booking-card @onGetBooking="onGetBooking" :bookings="filteredDateBookings[key]" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { debounce } from 'lodash'
import { getBookings } from '@/http'
import { format } from 'date-fns'
import AppBookingCard from './Card'
import AppDivider from '@/components/AppDivider'
import { dateFormatting } from '@/helpers'

export default {
  name: 'AppBookingsSearch',
  components: { AppBookingCard, AppDivider },
  data() {
    return {
      bookings: [],
      input_search: ''
    }
  },
  computed: {
    filteredDateBookings() {
      return this.bookings.reduce((rv, x) => {
        (rv[x['time_start'].slice(0, 10)] = rv[x['time_start'].slice(0, 10)] || []).push(x)
        return rv
      }, {})
    },
  },
  methods: {
    onInputSearch(search) {
      if (search.length) this.searchBookings(search, this)
      else this.bookings = []
    },
    searchBookings: debounce((search, vm) => {
      getBookings({
        search, page_size: 100, multi_status: ['new', 'open', 'wait'], time_start: format(new Date(), 'yyyy-MM-dd')
      }).then(res => {
        vm.bookings = res.data.results.map(item => ({
          ...item,
          time_start: dateFormatting(item.time_start, 'iso-to-normal')
        }))
      })
    }, 350),
    onGetBooking(booking) {
      booking.time_start = dateFormatting(booking.time_start, 'normal-to-iso')
      this.$emit('onGetBooking', booking)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.bookings-search
  height: 100%

.bookings-search__body
  margin-top: 20px

.bookings-search__plug
  padding: 50px 0
  text-align: center

  div
    margin-top: 10px
    color: $color-border
    font-size: 14px

</style>
