<template>
  <div class="guest-card">
    <div
      v-for="guest in getGuests"
      :key="`${type}_${guest.id}`"
      :class="['guest-card__item', `guest-card__item--${type}`]"
    >
      <div class="guest-card__between">
        <div v-if="guest.phone" class="guest-card__phone">{{ guest.phone }}</div>
        <app-cells :indent="false">
          <app-button
            @click="$emit('onGuestDelete', guest.id, type)"
            type="button"
            size="super_small"
            theme="error"
          >
            удалить
          </app-button>
          <app-button
            @click="$emit('onGuestEdit', guest.id, type)"
            type="button"
            size="super_small"
          >
            редактировать
          </app-button>
        </app-cells>
      </div>
      <div class="guest-card__name">
        <span>{{ guest.name }}</span>
        <span class="guest-card__icon" v-html="guest.language.icon"></span>
      </div>
      <template v-if="guest.comment">
        <div class="guest-card__title">Примечания:</div>
        <div class="guest-card__text">{{ guest.comment }}</div>
      </template>
      <template v-if="guest.allergy">
        <div class="guest-card__title">Аллергия:</div>
        <div class="guest-card__text">{{ guest.allergy }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGuestCard',
  props: {
    guests: Array,
    type: String
  },
  computed: {
    getGuests() {
      return this.guests
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.guest-card__item
  margin-bottom: 10px
  padding: 14px 16px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third

  &:last-child
    margin-bottom: 0

.guest-card__item--main
  background-color: #e1edff

.guest-card__item--additional
  background-color: #fff1ca

.guest-card__between
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px

.guest-card__type
  color: #38bf4e

.guest-card__type--additional
  color: $status-wait

.guest-card__name
  display: flex
  align-items: center
  gap: 0 6px
  margin-bottom: 10px
  font-weight: 600

.guest-card__icon
  line-height: 0

.guest-card__title
  margin-bottom: 5px

.guest-card__text
  margin-bottom: 10px
  color: #737c85
  font-size: 14px

  &:last-child
    margin-bottom: 0

</style>
