<template>
  <div class="booking-detail">
    <div class="booking-detail__info">
      <div class="booking-detail__row">
        <div class="booking-detail__elem  booking-detail__elem--shrink  booking-detail__elem--blue">
          Время: <span>{{ getBooking.time_start }} - {{ getBooking.short_time_end }}</span>
        </div>
      </div>
      <div class="booking-detail__row">
        <div class="booking-detail__elem">
          Зал: <span>{{ getBooking.hall.name }}</span>
        </div>
      </div>
      <div class="booking-detail__row">
        <div
          class="booking-detail__elem  booking-detail__elem--img  booking-detail__elem--blue"
          :class="{ 'booking-detail__elem--max-w': getBooking.deposit_amount }"
        >
          Стол №: <span :class="{ 'is-main': getBooking.additional_tables.length }">{{ getBooking.table_number }}</span>{{ getBooking.additional_tables_number.length ? ',' : '' }}
          <template v-if="getBooking.additional_tables_number.length">
            <span v-for="(additional_t, index) in getBooking.additional_tables_number" :key="additional_t.id">
              {{ additional_t }}{{ index !== getBooking.additional_tables_number.length - 1 ? ',' : '' }}
            </span>
          </template>
          <img v-if="getBooking.is_fixed_table" src="@/assets/img/lock.svg" alt="">
          <img v-if="getBooking.is_overbooking" src="@/assets/img/overbooking.svg" alt="">
        </div>
        <div
          v-if="getBooking.deposit_amount"
          :class="[
            'booking-detail__elem', 'booking-detail__elem--depo',
            { 'booking-detail__elem--check': getBooking.is_paid }
          ]"
        >
          Депозит: <span>{{ new Intl.NumberFormat('ru-RU').format(getBooking.deposit_amount) }} ₽</span>
        </div>
      </div>
      <div v-if="getBooking.pay_due_date" class="booking-detail__row booking-detail__row--end">
        <div
          class="booking-detail__elem"
        >
          Срок оплаты: <span>{{ getBookingDueDate }}</span>
        </div>
      </div>
      <div v-if="getBooking.is_paid && (getBooking.balance !== getBooking.deposit_amount)" class="booking-detail__row booking-detail__row--end">
        <div
          class="booking-detail__elem"
        >
          Баланс: <span>{{ new Intl.NumberFormat('ru-RU').format(getBooking.balance) }} ₽</span>
        </div>
      </div>
    </div>
    <div @click="onGuestCardClick" class="booking-detail__guest" :class="{ 'booking-detail__guest--clicked': getBooking.guest }">
      <div v-if="getBooking.guest" class="booking-detail__guest-type">Основной гость{{ getBooking.is_fast ? ' (офлайн)' : '' }}</div>
      <div v-if="getBooking.guest && getBooking.guest.phone" class="booking-detail__guest-phone">{{ getBooking.guest.phone }}</div>
      <div class="booking-detail__guest-wrapper">
        <div class="booking-detail__guest-name">
          <img v-if="!getBooking.guest" src="@/assets/img/warning-triangle-icon.svg" alt="">
          <span>
            {{ getBooking.guest && getBooking.guest.name ? getBooking.guest.name : 'Гость не указан' }}
          </span>
          <span v-if="getBooking.guest" class="booking-detail__guest-icon" v-html="getBooking.guest.language.icon"></span>
        </div>
        <span v-if="getBooking.is_fast" class="booking-detail__guest-offline">Офлайн</span>
      </div>
      <div v-if="getBooking.guest && getBooking.guest.comment" class="booking-detail__guest-comment">
        <div class="booking-detail__guest-t">Примечания:</div>
        <div class="booking-detail__guest-d">{{ booking.guest.comment }}</div>
      </div>
      <div v-if="getBooking.guest && getBooking.guest.allergy" class="booking-detail__guest-comment">
        <div class="booking-detail__guest-t">Аллергия:</div>
        <div class="booking-detail__guest-d">{{ getBooking.guest.allergy }}</div>
      </div>
    </div>
    <app-cells v-if="getBooking.additional_guests.length > 0" position="center">
      <app-button type="button" @click="guests_toggler = !guests_toggler">
        {{ guests_toggler ? 'Скрыть дополнительных гостей' : `Еще гости (${getBooking.additional_guests.length} чел.)` }}
      </app-button>
    </app-cells>
    <transition-group name="fade" tag="div">
      <template v-if="guests_toggler">
        <div v-for="guest in getBooking.additional_guests" :key="guest.id" class="booking-detail__guest">
          <div class="booking-detail__guest-type  booking-detail__guest-type--additional">Дополнительный гость</div>
          <div class="booking-detail__guest-phone">{{ guest.phone }}</div>
          <div class="booking-detail__guest-name">{{ guest.name }}</div>
          <div v-if="guest.comment" class="booking-detail__guest-comment">
            <div class="booking-detail__guest-t">Примечания:</div>
            <div class="booking-detail__guest-d">{{ guest.comment }}</div>
          </div>
          <div v-if="guest.allergy" class="booking-detail__guest-comment">
            <div class="booking-detail__guest-t">Аллергия:</div>
            <div class="booking-detail__guest-d">{{ guest.allergy }}</div>
          </div>
        </div>
      </template>
    </transition-group>
    <div v-if="getBooking.comment_guest" class="booking-detail__guest-comment">
      <div class="booking-detail__guest-t">Комментарий гостя:</div>
      <div class="booking-detail__guest-d">{{ getBooking.comment_guest }}</div>
    </div>
    <div v-if="getBooking.comment_hostes" class="booking-detail__guest-comment">
      <div class="booking-detail__guest-t">Хостес:</div>
      <div class="booking-detail__guest-d">{{ getBooking.comment_hostes }}</div>
    </div>
    <div v-if="getBooking.deposit_amount" class="k-r-info">
      <div class="k-r-info__item">
        Бронь:
        <img v-if="getBooking.order_guid" src="@/assets/img/check-r-k.svg" alt="">
        <img v-else src="@/assets/img/close-r-k.svg" alt="">
      </div>
      <div class="k-r-info__item">
        Платеж:
        <img v-if="getBooking.prepay_guid" src="@/assets/img/check-r-k.svg" alt="">
        <img v-else src="@/assets/img/close-r-k.svg" alt="">
      </div>
    </div>
    <div class="booking-detail__info  booking-detail__info--mt">
      <div class="booking-detail__row">
        <div class="booking-detail__elem">
          Создано: <span>{{ getBooking.created_by ? `${getBooking.created_by},` : 'Система,' }} {{ getBooking.created_at }}</span>
        </div>
      </div>
      <div class="booking-detail__row">
        <div class="booking-detail__elem">
          Обновлено: <span>{{ getBooking.updated_by ? `${getBooking.updated_by},` : 'Система,' }} {{ getBooking.updated_at }}</span>
        </div>
      </div>
    </div>
    <div class="booking-controls">
      <div class="booking-controls__block  booking-controls__block--combined  booking-controls__block--4">
        <app-button
          @click="onShowModal('accept')"
          :disabled="getBooking.is_read"
          theme="accept"
          type="button"
        >
          Подтвердить
        </app-button>
        <app-button
          @click="onShowModal('cancel')"
          :disabled="getBooking.status.id === 'open'"
          type="button"
        >
          Отменить
        </app-button>
        <app-button
          @click="onShowModal('open')"
          :disabled="getBooking.status.id === 'open' || !getBooking.is_read || !is_today"
          type="button"
        >
          Открыть
        </app-button>
        <app-button
          @click="onShowModal('close')"
          :disabled="getBooking.status.id !== 'open'"
          type="button"
          theme="error"
        >
          Закрыть
        </app-button>
      </div>
      <div class="booking-controls__block">
        <app-button @click="$emit('onEditBookingButtonClick')" type="button">Редактировать</app-button>
      </div>
      <div v-if="getBooking.order_guid" class="booking-controls__block">
        <app-button
          @click="onGetRKInfo"
          type="button"
          ref="r_k_info"
          theme="accept"
        >
          Информация по заказу из R keeper
        </app-button>
      </div>
      <div
        v-if="getBooking.guest && getBooking.guest.phone && !getBooking.is_paid && getBooking.deposit_amount"
        class="booking-controls__block"
      >
        <app-button
          @click="onShowModal('sms')"
          type="button"
          theme="accept"
        >
          Отправить СМС
        </app-button>
      </div>
      <div
        v-if="getBooking.is_paid && !getBooking.order_guid && getBooking.status.id === 'open'"
        class="booking-controls__block"
      >
        <app-button
          @click="onShowModal('create_rk_booking')"
          type="button"
          ref="r_k_create"
        >
          Создать заказ в R Keeper
        </app-button>
      </div>
      <div
        v-if="
          getBooking.order_guid && getBooking.status.id === 'open' &&
          getBooking.is_paid &&
          !getBooking.prepay_guid
        "
        class="booking-controls__block"
      >
        <app-button
          @click="onShowModal('create_rk_prepay')"
          type="button"
        >
          Внести предоплату в R keeper
        </app-button>
      </div>
      <div class="booking-controls__block  booking-controls__block--combined  booking-controls__block--2">
        <app-button @click.native="$emit('onExtendSlideOpen', 'slide')" type="button">Сдвинуть</app-button>
        <app-button @click.native="$emit('onExtendSlideOpen', 'extend')" type="button">Продлить</app-button>
      </div>
      <div class="booking-controls__block">
        <app-button
          v-if="!booking.additional_tables.length && is_picking_table !== 'replace' && !getBooking.is_fixed_table"
          @click.native="$emit('onReplaceStart')"
          type="button"
        >
          Заменить стол
        </app-button>
        <div v-if="is_picking_table === 'replace'" class="table-tags" key="tags_main">
          <div class="table-tags__plug">Выберите стол на схеме</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { patchBooking, sendSMS, getRK, postRKBooking, postRKPrepay } from '@/http'
import {formatISO, addMinutes, getTime, addHours, differenceInMilliseconds, differenceInMinutes} from 'date-fns'
import { dateFormatting } from '@/helpers'

export default {
  name: 'AppBookingDetail',
  props: {
    booking: Object,
    is_today: Boolean,
    is_picking_table: String
  },
  data() {
    return {
      guests_toggler: false
    }
  },
  computed: {
    getBooking() {
      return this.booking
    },
    getBookingDueDate() {
      if (!this.getBooking.pay_due_date) return
      return dateFormatting(this.getBooking.pay_due_date, 'iso-to-normal')
    },
    openEarlier() {
      return getTime(new Date()) < getTime(new Date(dateFormatting(this.getBooking.time_start, 'normal-to-iso')))
    }
  },
  methods: {
    onShowModal(type) {
      let title = '', text = '', btnText = ''
      if (type === 'accept') {
        title = this.getBooking.deposit_amount ? 'Подтверждение онлайн-бронирования с депозитом' : 'Подтверждение онлайн-бронирования'
        text = this.getBooking.deposit_amount ? 'После нажатия на “подтвердить”, гостю на e-mail отправятся подробные детали бронирования и ссылка на оплату депозита. Пожалуйста, убедитесь в корректности данных перед подтверждением.' : 'Пожалуйста, убедитесь в корректности данных перед подтверждением. После нажатия на “подтвердить”, гостю на e-mail отправятся подробные детали бронирования.'
        btnText = 'Подтвердить онлайн-бронирование'
      }
      if (type === 'cancel') {
        title = 'Отмена бронирования'
        text = 'Вы собираетесь отменить бронирование - это действие необратимо, восстановить бронь будет невозможно. Пожалуйста, убедитесь в том, что отменяется нужная бронь, а также выберите причину отмены:'
        btnText = 'Отменить бронирование'
      }
      if (type === 'open') {
        title = 'Открытие бронирования'
        text = this.openEarlier ? `Вы открываете бронирование на ${this.calculateTime(this.getBooking.time_start)} раньше, чем это было установлено изначально. Пожалуйста, убедитесь в верности действий. ОБРАТИТЕ ВНИМАНИЕ: при подтверждении открытия, у брони установится обновленное время начала.` : 'Подтвердите открытие бронирования'
        btnText = this.openEarlier ? 'Все верно, открыть бронирование' : 'Открыть бронирование'
      }
      if (type === 'close') {
        title = 'Закрытие бронирования'
        text = 'Пожалуйста, подтвердите закрытие бронирования.'
        btnText = 'Закрыть бронирование'
      }
      if (type === 'sms') {
        title = 'Повторная отправка SMS об оплате'
        text = 'Подтвердите повторную отправку SMS-сообщения об оплате гостю. Статус отправленных SMS можно увидеть в детальной информации бронирования.'
        btnText = 'Отправить повторное SMS об оплате'
      }
      if (type === 'create_rk_booking') {
        title = 'Создание заказа и предоплаты в r_keeper'
        text = `Подтвердите создание заказа и предоплаты в r_keeper. После подтверждения в  r_keeper будет создан заказ на ${this.getBooking.table_number} стол и внесена предоплата в размере ${new Intl.NumberFormat('ru-RU').format(this.getBooking.balance)} ₽. Убедитесь, что стол, указанный в бронировании, совпадает с фактической посадкой гостя.`
        btnText = 'Создать заказ и предоплату в r_keeper'
      }
      if (type === 'create_rk_prepay') {
        title = 'Внесение предоплаты в r_keeper'
        text = `Подтвердите внесение предоплаты в r_keeper. После подтверждения в r_keeper будет создан заказ на ${this.getBooking.table_number} стол и внесена предоплата в размере ${new Intl.NumberFormat('ru-RU').format(this.getBooking.balance)} ₽. Убедитесь, что стол, указанный в бронировании, совпадает с фактической посадкой гостя.`
        btnText = 'Внести предоплату в r_keeper'
      }

      this.$emit('onShowAcceptModal', title, text, btnText, type)
    },
    onAcceptBooking() {
      const bookingAcceptData = { is_read: true }
      if (!this.getBooking.is_paid) {
        bookingAcceptData.pay_due_date = formatISO(addHours(new Date(), 6))
      }
      patchBooking(this.getBooking.id, bookingAcceptData)
        .then(() => {
          this.$emit('getReactiveBooking', this.getBooking.id, this.booking.table_id)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Бронь подтверждена'
          })
        })
    },
    onCancelBooking() {
      const bookingCancelData = {
        status: 'cancel',
        cancel_reason: this.getBooking.cancel_reason,
      }
      if (this.getBooking.deposit_amount && this.getBooking.is_paid) bookingCancelData.refund_status = this.getBooking.refund_status
      if (this.getBooking.refund_status === 'required') bookingCancelData.refund_request_amount = this.getBooking.refund_request_amount
      patchBooking(this.getBooking.id, bookingCancelData)
        .then(() => {
          this.$emit('getReactiveBooking', this.getBooking.id, 'reset', this.booking.table_id)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Бронь отменена'
          })
        })
    },
    onOpenBooking() {
      if (getTime(new Date()) < getTime(new Date(dateFormatting(this.getBooking.time_start, 'normal-to-iso')))) {
        patchBooking(this.getBooking.id, { status: 'open', time_start: formatISO(new Date()) })
          .then(() => {
            this.$emit('getReactiveBooking', this.getBooking.id)
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Бронь открыта'
            })
          })
      } else {
        patchBooking(this.getBooking.id, { status: 'open' })
          .then(() => {
            this.$emit('getReactiveBooking', this.getBooking.id)
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Бронь открыта'
            })
          })
      }
    },
    onCloseBooking() {
      const bookingCloseData = {
        status: 'close',
        time_end: formatISO(addMinutes(new Date(), 1))
      }
      if (this.getBooking.deposit_amount && this.getBooking.is_paid) bookingCloseData.refund_status = this.getBooking.refund_status
      if (this.getBooking.refund_status === 'required') bookingCloseData.refund_request_amount = this.getBooking.refund_request_amount
      patchBooking(this.getBooking.id, bookingCloseData)
        .then(() => {
          this.$emit('getReactiveBooking', this.getBooking.id, 'reset', this.booking.table_id)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Бронь закрыта'
          })
        })
    },
    onSendSMS() {
      sendSMS(this.getBooking.id)
        .finally(() => { this.$modal.hide('accept-modal') })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'СМС об оплате отправлена гостю'
          })
        })
    },
    onCreateRKBooking() {
      postRKBooking(this.getBooking.id)
        .finally(() => { this.$modal.hide('accept-modal') })
        .then(() => {
          this.$emit('getReactiveBooking', this.getBooking.id)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Заказ в R_Keeper создан'
          })
        })
    },
    onCreateRKPrepay() {
      postRKPrepay(this.getBooking.id)
        .finally(() => { this.$modal.hide('accept-modal') })
        .then(() => {
          this.$emit('getReactiveBooking', this.getBooking.id)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Оплата по заказу в R_Keeper создана'
          })
        })
    },
    onGetRKInfo() {
      this.$refs.r_k_info.preload = true
      getRK(this.getBooking.id)
        .finally(() => { this.$refs.r_k_info.preload = false })
        .then(response => {
          if (!response.data.name) {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Заказ не найден'
            })
          } else {
            this.$emit('onShowInfoModal', response.data)
          }
        })
    },
    calculateTime(time_start) {
      const difference = differenceInMinutes(new Date(dateFormatting(time_start, 'normal-to-iso')), new Date())
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    onGuestCardClick() {
      if (this.getBooking.guest) this.$router.push({ name: 'guest-detail', params: { id: this.getBooking.guest.id } })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.booking-detail__info
  margin-bottom: 15px

.booking-detail__info--mt
  margin-top: 20px

.booking-detail__row
  display: flex
  justify-content: space-between
  gap: 0 15px
  margin-bottom: 10px

  &:last-child
    margin-bottom: 0

.booking-detail__row--end
  justify-content: flex-end

.booking-detail__elem
  color: #737c85

  span
    color: $color-text-base
    font-weight: 600

    &.is-main
      color: $status-wait

.booking-detail__elem--img
  line-height: 1

  img
    display: inline-block
    vertical-align: top
    margin-right: 3px

.booking-detail__elem--shrink
  flex-shrink: 0

.booking-detail__elem--right
  text-align: right

.booking-detail__elem--max-w
  max-width: 55%

.booking-detail__elem--blue
  span
    color: $color-theme

.booking-detail__elem--depo
  span
    color: $color-error

.booking-detail__elem--check
  span
    color: $status-new

.booking-detail__guest
  position: relative
  margin-top: 10px
  padding: 14px 15px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third
  overflow: hidden

.booking-detail__guest--clicked
  cursor: pointer

.booking-detail__guest-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px

.booking-detail__guest-wrapper
  display: flex
  align-items: center
  justify-content: space-between

.booking-detail__guest-name
  display: flex
  align-items: center
  gap: 0 6px
  font-weight: 600

.booking-detail__guest-icon
  line-height: 0

.booking-detail__guest-phone
  margin-bottom: 10px

.booking-detail__guest-type
  position: absolute
  top: 0
  right: 0
  padding: 8px 15px
  background-color: $status-new
  color: #fff
  font-size: 14px
  border-radius: 0 0 0 10px

.booking-detail__guest-type--additional
  background-color: $status-wait

.booking-detail__guest-offline
  color: #737c85
  font-weight: 600

.booking-detail__guest-comment
  margin-top: 10px

.booking-detail__guest-t
  margin-bottom: 5px

.booking-detail__guest-d
  color: #737c85
  font-size: 14px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.3s

.fade-enter, .fade-leave-to
  opacity: 0

</style>
