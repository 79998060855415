<template>
  <form>
    <app-form-group label="Имя" label-for="name" required>
      <app-input
        v-model="form.name"
        placeholder="Введите имя гостя"
        id="name"
        :error="$v.form.name.$error"
      />
      <template #error>
        <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
      </template>
    </app-form-group>
    <app-form-group label="Телефон">
      <app-phone
        v-model="form.phone"
        placeholder="Введите телефон"
        ref="phonePicker"
        @paste.native.prevent
        @input="$v.form.phone.$touch"
        :error="$v.form.phone.$dirty && form.phone === 0"
      />
      <template #error>
        <div v-if="$v.form.phone.$dirty && form.phone === 0">
          Неправильный формат номера
        </div>
      </template>
    </app-form-group>
    <app-form-group label="Язык">
      <app-cells position="start">
        <app-radio
          v-for="language in languages"
          :key="language.id"
          v-model="guestLanguage"
          :value="language.id"
          name="language"
          :label="language.name"
          :icon="language.icon"
        />
      </app-cells>
    </app-form-group>
    <app-form-group label="Электронная почта" label-for="email">
      <app-input
        v-model="form.email"
        placeholder="Введите электронную почту"
        id="email"
        :error="$v.form.email.$error"
      />
      <template #error>
        <div v-if="$v.form.email.$dirty && !$v.form.email.email">Неправильный формат электронной почты</div>
      </template>
    </app-form-group>
    <app-form-group>
      <app-checkbox
        v-model="send_notifications"
        label="Не отправлять письма на E-mail"
      />
    </app-form-group>
    <app-form-group label="Дата рождения" label-for="birthday">
      <app-input
        v-model="form.birthday"
        v-mask="'99.99.9999'"
        placeholder="Введите дату рождения"
        id="birthday"
        :error="$v.form.birthday.$error"
      />
      <template #error>
        <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.normalDateSpan">Неправильная дата рождения</div>
        <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">Неправильный формат даты</div>
      </template>
    </app-form-group>
    <app-form-group>
      <div class="switcher">
        <label class="switcher__label">
          <input type="checkbox" v-model="form.is_blacklisted">
          <span class="switcher__control">
                <span class="switcher__point"></span>
              </span>
          <span class="switcher__text">Черный список</span>
        </label>
      </div>
    </app-form-group>
    <app-form-group label-for="allergy" label="Аллергия">
      <app-textarea
        v-model="form.allergy"
        id="allergy"
      />
    </app-form-group>
    <app-form-group label-for="comment" label="Примечание">
      <app-textarea
        v-model="form.comment"
        id="comment"
      />
    </app-form-group>
    <app-cells>
      <app-button @click="onCheckForm" type="button" ref="submit" size="normal" :disabled="$v.form.$error || form.phone === 0">
        {{ flag === 'new' ? 'Создать / добавить гостя' : 'Сохранить / добавить гостя' }}
      </app-button>
    </app-cells>
  </form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import { dateFormatting, validDate } from '@/helpers'
import { isWithinInterval, subDays } from 'date-fns'
import { postGuest, putGuest } from '@/http'

const normalDateSpan = value => {
  if (!value) return true
  else {
    if (!value.includes('_') && validDate(value)) {
      return isWithinInterval(new Date(dateFormatting(value, 'normal-to-iso-small')), {
        start: new Date(1925, 0, 1),
        end: subDays(new Date(), 1)
      })
    } else return false
  }
}

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'AppGuestForm',
  props: {
    form: Object,
    flag: String,
    languages: Array,
  },
  data() {
    return {
      guestLanguage: 'ru',
      send_notifications: false,
    }
  },
  validations: {
    form: {
      phone: {},
      name: { required },
      email: { email },
      birthday: { normalDateSpan, underscorePresent },
    }
  },
  watch: {
    'form.language': {
      handler(val) {
        if (val) this.guestLanguage = val.id
      },
      immediate: true
    },
    'form.send_notifications': {
      handler(val) {
        if (val) this.send_notifications = !val
      },
      immediate: true
    },
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.$props.flag === 'new') {
        postGuest(this.normalizeForm())
          .finally(() => { this.$refs.submit.preload = false })
          .then(res => {
            this.$refs.submit.preload = false
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Гость создан и добавлен'
            })
            this.$emit('guestAdded', res.data)
          })
      } else {
        putGuest(this.$props.form.id, this.normalizeForm())
          .finally(() => { this.$refs.submit.preload = false })
          .then(res => {
            this.$refs.submit.preload = false
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Гость сохранен и добавлен'
            })
            this.$emit('guestAdded', undefined, res.data.language)
          })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.$props.form }
      normalizedForm.birthday = dateFormatting(normalizedForm.birthday, 'normal-to-iso-small')
      normalizedForm.language = this.guestLanguage
      normalizedForm.send_notifications = !this.send_notifications
      if (!normalizedForm.phone) normalizedForm.phone = null

      return normalizedForm
    }
  }
}
</script>

