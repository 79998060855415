import Vue from 'vue'
import AppPhone from '@/components/AppPhone'
import AppInput from '@/components/AppInput'
import AppFormGroup from '@/components/AppFormGroup'
import AppButton from '@/components/AppButton'
import AppCells from '@/components/AppCells'
import AppTableHead from '@/components/AppTableHead'
import AppFilters from '@/components/AppFilters'
import AppSidebarRight from '@/components/AppSidebarRight'
import AppTextarea from '@/components/AppTextarea'
import AppTabs from '@/components/AppTabs'
import AppRadio from '@/components/AppRadio'
import AppStatus from '@/components/AppStatus'
import AppCheckbox from '@/components/AppCheckbox'
import AppChanges from '@/components/AppChanges'

Vue.component('AppPhone', AppPhone)
Vue.component('AppInput', AppInput)
Vue.component('AppFormGroup', AppFormGroup)
Vue.component('AppButton', AppButton)
Vue.component('AppCells', AppCells)
Vue.component('AppTableHead', AppTableHead)
Vue.component('AppFilters', AppFilters)
Vue.component('AppSidebarRight', AppSidebarRight)
Vue.component('AppTextarea', AppTextarea)
Vue.component('AppTabs', AppTabs)
Vue.component('AppRadio', AppRadio)
Vue.component('AppStatus', AppStatus)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppChanges', AppChanges)
