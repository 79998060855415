import { format, parse, parseISO, formatISO } from 'date-fns'

export function dateFormatting(date, flag) {
  if (date) {
    switch (flag) {
    case 'iso-to-normal-small':
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
    case 'normal-to-iso-small':
      return format(parse(date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
    case 'iso-to-normal':
      return format(parseISO(date), 'dd.MM.yyyy HH:mm')
    case 'normal-to-iso':
      return formatISO(parse(date, 'dd.MM.yyyy HH:mm', new Date()))
    }
  } else {
    return null
  }
}

export function validDate(date) {
  const d_arr = date.split('.')
  const d = new Date(`${d_arr[2]}-${d_arr[1]}-${d_arr[0]}`)
  return !(+d_arr[2] !== d.getFullYear() || +`0${d_arr[1]}`.slice(-2) !== (d.getMonth() + 1) || +d_arr[0] !== d.getDate())
}
