<template>
  <form @submit.prevent="onCheckForm">
    <app-form-group label="Дата, время начала" label-for="time_start" required>
      <app-input
        v-model="guest_seat_form.time_start"
        v-mask="'99.99.9999 99:99'"
        placeholder="Период с"
        id="time_start"
        readonly
      />
    </app-form-group>
    <app-form-group label="Дата, время окончания" label-for="time_end" required>
      <app-input
        v-model="guest_seat_form.time_end"
        type="datetime-local"
        placeholder="Период по"
        id="time_end"
        :error="$v.guest_seat_form.time_end.$error"
      />
      <template #error>
        <div v-if="$v.guest_seat_form.time_end.$dirty && !$v.guest_seat_form.time_end.required">Обязательное поле</div>
      </template>
    </app-form-group>
    <app-form-group label="Количество гостей" label-for="count_guests" required>
      <app-input
        v-model.number="guest_seat_form.count_guests"
        placeholder="Введите кол-во гостей"
        type="number"
        id="count_guests"
        :error="$v.guest_seat_form.count_guests.$error"
      />
      <template #error>
        <div v-if="$v.guest_seat_form.count_guests.$dirty && !$v.guest_seat_form.count_guests.required">
          Обязательное поле
        </div>
        <div v-if="$v.guest_seat_form.count_guests.$dirty && !$v.guest_seat_form.count_guests.integer">
          Принимаются только целые числовые значение
        </div>
      </template>
    </app-form-group>
    <app-cells position="center">
      <app-button ref="submit" size="normal" :disabled="$v.guest_seat_form.$error">Создать бронирование</app-button>
    </app-cells>
  </form>
</template>

<script>
import { patchBooking, postBooking } from '@/http'
import { dateFormatting } from '@/helpers'
import { integer, required } from 'vuelidate/lib/validators'

export default {
  name: 'AppGuestSeatForm',
  props: {
    guest_seat_form: Object
  },
  validations: {
    guest_seat_form: {
      time_end: { required },
      count_guests: { required, integer },
      table: { required },
    }
  },
  methods: {
    onCheckForm() {
      this.$v.guest_seat_form.$touch()
      if (this.$v.guest_seat_form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postBooking(this.normalizeForm())
        .finally(() => { this.$refs.submit.preload = false })
        .then(res => {
          this.$notify({
            type: 'success',
            title: 'Успешно',
            text: 'Бронь создана'
          })
          this.$emit('guestSeatModalSubmit', res.data.table)
          this.$v.guest_seat_form.$reset()
        })
    },
    normalizeForm() {
      const normalizedForm = { ...this.guest_seat_form }

      normalizedForm.time_start = dateFormatting(normalizedForm.time_start, 'normal-to-iso')
      normalizedForm.time_end = `${normalizedForm.time_end}:00+03:00`
      normalizedForm.table = normalizedForm.table.id
      normalizedForm.send_sms = false
      normalizedForm.deposit = false
      normalizedForm.is_read = true
      normalizedForm.is_fast = true

      return normalizedForm
    },
  }
}
</script>
