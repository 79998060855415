<template>
  <div class="payment">
    <template v-if="showDeposit && !paidSuccess">
      <div class="payment__title">Ваше бронирование требует оплаты депозита</div>
      <div class="payment__info">
        <div class="payment__subt">Детали бронирования</div>
        <div class="payment__details">
          <div class="payment__detail">
            <span class="payment__key">Дата и время:</span>
            <span class="payment__value" v-if="invoiceData.time_start && invoiceData.time_end">
              {{ invoiceData.time_start.slice(0, 10) }} {{ invoiceData.time_start.slice(-5) }} - {{ invoiceData.time_end.slice(-5) }}
            </span>
          </div>
          <div class="payment__detail">
            <span class="payment__key">Персон:</span>
            <span class="payment__value">{{ invoiceData.count_guests }}</span>
          </div>
        </div>
        <div v-if="invoiceData.deposit_data" class="payment__desc">
          Обращаем ваше внимание, что данный тип бронирования подразумевает внесение депозита в размере
          {{ invoiceData.deposit_data.price }} руб. {{ invoiceData.deposit_data.type }}
        </div>
        <app-form-group label="Электронная почта для чека и деталей бронирования" label-for="email" required>
          <app-input
            v-model="guestEmail"
            placeholder="guest@siteurl.ru"
            id="email"
            :error="$v.guestEmail.$error"
            @change.native="$v.guestEmail.$touch()"
          />
          <template #error>
            <div v-if="$v.guestEmail.$dirty && !$v.guestEmail.email">Неправильный формат электронной почты</div>
          </template>
        </app-form-group>
        <div class="payment__checkbox">
          <label class="checkbox">
          <span class="checkbox__text">Ознакомлен с
            <router-link :to="{name: 'terms'}" target="_blank">правилами онлайн бронирования*</router-link>
          </span>
            <input
              type="checkbox"
              v-model="termsAgree"
            />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="payment__footer">
          <app-button
            @click="showPayWidget"
            size="normal"
            :disabled="!termsAgree || $v.guestEmail.$error || !guestEmail"
          >
            Оплатить {{ new Intl.NumberFormat('ru-RU').format(invoiceData.amount) }} ₽
          </app-button>
        </div>
      </div>
    </template>
    <template v-if="paidSuccess">
      <div class="payment__success">
        <img src="@/assets/img/payment-success.svg" alt="success">
      </div>
      <div class="payment__title">Успешно!</div>
      <div class="payment__desc payment__desc--center">
        Ваше бронирование успешно подтверждено, с вами свяжется менеджер в течение нескольких минут
      </div>
      <div class="payment__footer">
        <a :href="siteMain" target="_blank" class="btn btn--default">Спасибо</a>
      </div>
    </template>
    <template v-if="showNotFound">
      <div class="payment__subt payment__subt--center">
        Счет на внесение депозита не найден.<br>Обратитесь в ресторан по телефонам:
        <a href="tel:+74997959999">+7 (499) 795-99-99</a>, <a href="tel:+79680848052">+7 (968) 084-80-52</a>
      </div>
      <div class="payment__footer">
        <a :href="siteMain" target="_blank" class="btn btn--default">Спасибо</a>
      </div>
    </template>
    <template v-if="showIsPaid">
      <div class="payment__subt payment__subt--center">
        Депозит уже оплачен, детали отправлены на почту.<br>В случае возникновения вопросов обратитесь в ресторан по
        телефоам: <a href="tel:+74997959999">+7 (499) 795-99-99</a>, <a href="tel:+79680848052">+7 (968) 084-80-52</a>
      </div>
      <div class="payment__footer">
        <a href="siteMain" target="_blank" class="btn btn--default">Спасибо</a>
      </div>
    </template>
  </div>
</template>

<script>
import { email } from 'vuelidate/lib/validators'
import { checkInvoice } from '@/http'
import { formatInTimeZone } from 'date-fns-tz'

export default {
  name: 'Payment',
  data() {
    return {
      invoiceData: {},
      showNotFound: false,
      showIsPaid: false,
      showDeposit: false,
      termsAgree: false,
      guestEmail: '',
      paidSuccess: false,
    }
  },
  validations: {
    guestEmail: { email }
  },
  computed: {
    siteMain() {
      return process.env.VUE_APP_MAIN_RESTAURANT
    }
  },
  created() {
    checkInvoice(this.$route.params.id)
      .then(response => {
        this.invoiceData = response.data
        if (response.data.is_paid) this.showIsPaid = true
        else {
          this.invoiceData.time_start = formatInTimeZone(response.data.time_start, 'Europe/Moscow', 'dd.MM.yyyy HH:mm')
          this.invoiceData.time_end = formatInTimeZone(response.data.time_end, 'Europe/Moscow', 'dd.MM.yyyy HH:mm')
          this.showDeposit = true
          const cpSrc = 'https://widget.cloudpayments.ru/bundles/cloudpayments'
          const addScr = document.createElement('script')
          addScr.setAttribute('type', 'text/javascript')
          addScr.setAttribute('src', cpSrc)
          document.querySelector('head').append(addScr)
        }
      })
      .catch(() => {
        this.showNotFound = true
      })
  },
  methods: {
    showPayWidget() {
      const data = {
        comment: `${this.invoiceData.count_guests} чел. ${ this.invoiceData.time_start.slice(0, 10) } ${ this.invoiceData.time_start.slice(-5) } - ${ this.invoiceData.time_end.slice(-5) }`,
        cloudPayments: {
          CustomerReceipt: {
            Items: [
              {
                label: 'Предоплата за услуги общественного питания',
                price: this.invoiceData.amount,
                quantity: 1,
                amount: this.invoiceData.amount,
                vat: null,
                method: 2,
                object: 4,
              }
            ],
            calculationPlace: process.env.VUE_APP_RECEIPT_CALCULATION_PLACE,
            taxationSystem: process.env.VUE_APP_RECEIPT_TAXATION_SYSTEM,
            email: this.guestEmail,
            isBso: false,
            amounts:
              {
                electronic: this.invoiceData.amount,
              }
          },
        }
      }
      const widget = new cp.CloudPayments()
      widget.pay('charge',
        {
          publicId: process.env.VUE_APP_CLOUDPAYMENTS_PUBLIC_ID,
          description: 'Предоплата за услуги общественного питания ',
          amount: this.invoiceData.amount,
          currency: 'RUB',
          accountId: this.invoiceData.booking_id,
          invoiceId: this.invoiceData.id,
          email: this.guestEmail,
          requireEmail: true,
          skin: 'mini',
          data,
        },
        {
          onComplete: (paymentResult, options) => {
            if (paymentResult.success) this.paidSuccess = true
          }
        }
      )
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.payment
  padding: 0 110px

  +max-w($laptop-big)
    padding: 0 60px

  +max-w($mobile_xl)
    padding: 0 30px

  +max-w($mobile_sm)
    padding: 0 15px

.payment__title
  margin-bottom: 40px
  color: #030405
  font-size: 30px
  font-weight: 600
  text-align: center

  +max-w($laptop)
    font-size: 25px

  +max-w($mobile_sm)
    margin-bottom: 30px
    font-size: 22px

.payment__subt
  margin-bottom: 25px
  color: #030405
  font-size: 24px
  font-weight: 600

  +max-w($laptop)
    font-size: 20px

  +max-w($mobile_sm)
    margin-bottom: 20px
    font-size: 18px

  a
    color: #030405
    text-decoration: none
    transition: color 0.3s

    &:active
      color: $color-theme

    @media (hover: hover)
      &:hover,
      &:focus
        color: $color-theme

.payment__subt--center
  text-align: center

.payment__subt--indent
  margin-bottom: 40px

.payment__some-grid
  display: grid
  grid-template-columns: 350px 50px
  gap: 20px 50px

  +max-w($mobile_md)
    grid-template-columns: repeat(1, 1fr)

.payment__some-block p:first-child
  margin-bottom: 8px
  font-weight: 600

.payment__note
  margin-top: 20px
  padding: 20px 16px
  border: 1px solid #c9d9f1
  border-radius: 10px
  background-color: rgba(201, 217, 241, 0.25)

.payment__note-title
  margin-bottom: 12px
  color: $color-theme
  font-weight: 600

.payment__note-text b
  font-weight: 600

.payment__footer
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 20px
  margin-top: 40px

.payment__info
  max-width: 610px
  margin: 0 auto

.payment__checkbox
  margin-top: 12px

  a
    color: $color-theme
    transition: color 0.3s
    text-decoration: none

    &:active
      color: $status-open
      text-decoration: none

    @media (hover: hover)
      &:hover,
      &:focus
        color: $status-open
        text-decoration: none

.payment__details
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 20px
  margin-bottom: 35px

  +max-w($mobile_xs)
    grid-template-columns: 1fr

.payment__key
  display: block
  margin-bottom: 12px
  color: $color-text-base
  font-size: 16px
  font-weight: 600

.payment__value
  color: #030405
  font-size: 16px
  font-weight: 600

.payment__desc
  margin-bottom: 20px
  color: #030405
  font-size: 16px
  font-weight: 600

.payment__desc--center
  text-align: center

.payment__success
  margin-bottom: 30px
  text-align: center
  line-height: 0
</style>
