<template>
  <div class="login">
    <div class="login__title">Добро пожаловать</div>
    <form @submit.prevent="onCheckForm">
      <app-form-group>
        <app-phone
          v-model="phone_number"
          placeholder="Введите телефон"
          :error="$v.phone_number.$dirty && (!$v.phone_number.required || (phone_number === 0))"
          @paste.native.prevent
        />
        <template #error>
          <div v-if="$v.phone_number.$dirty && !$v.phone_number.required">Обязательное поле</div>
          <div v-if="$v.phone_number.$dirty && (phone_number === 0) && $v.phone_number.required">
            Неправильный формат номера
          </div>
        </template>
      </app-form-group>
      <app-form-group>
        <app-input
          v-model="password"
          :type="show_pass ? 'text' : 'password'"
          placeholder="Введите пароль"
          :error="$v.password.$error"
          @change.native="$v.password.$touch()"
        />
        <template #right_place>
          <button
            @click="show_pass = !show_pass"
            type="button"
          >
            <img v-if="show_pass" src="@/assets/img/eye-open.svg" alt="">
            <img v-else src="@/assets/img/eye-close.svg" alt="">
          </button>
        </template>
        <template #error>
          <div v-if="$v.password.$dirty && !$v.password.required">Обязательное поле</div>
          <div v-if=" $v.password.$dirty && !$v.password.minLength">
            Длина пароля менее 4 символов
          </div>
        </template>
      </app-form-group>
      <app-cells position="center">
        <app-button
          ref="submit"
          size="small"
          :disabled="$v.phone_number.$error || $v.password.$error || phone_number === 0"
        >
          Войти
        </app-button>
      </app-cells>
    </form>
    <div class="login__forgot">
      <router-link :to="{ name: 'password-forgot' }" class="btn btn--link">Забыли пароль?</router-link>
    </div>
  </div>
</template>

<script>
import { login } from '@/http'
import { minLength, required } from 'vuelidate/lib/validators'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'Login',
  data() {
    return {
      phone_number: '',
      password: '',
      show_pass: false
    }
  },
  validations: {
    phone_number: { required },
    password: { required, minLength: minLength(4) }
  },
  methods: {
    onCheckForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      login({ phone_number: this.phone_number, password: this.password })
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'hall' })
        })
        .catch(() => {
          this.$refs.submit.preload = false
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.login
  width: 100%

.login__title
  margin-bottom: 30px
  font-size: 24px
  font-weight: 700
  color: #040d26
  text-align: center

.login__forgot
  position: absolute
  bottom: 30px
  left: 50%
  transform: translateX(-50%)

</style>
