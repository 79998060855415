<template>
  <label class="radio" :class="{ 'radio--readonly': readonly, 'radio--error': error }">
    <span class="radio__text"><span v-if="icon" v-html="icon" class="radio__icon"></span><span>{{ label }}</span></span>
    <input
        type="radio"
        :value="value"
        :checked="value === checked"
        :name="name"
        @change="onRadioChange($event.target.value)"
    />
    <span class="radio__radiomark"></span>
  </label>
</template>

<script>
export default {
  name: 'AppRadio',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    value: [Boolean, String, Number],
    checked: [Boolean, String, Number],
    label: String,
    id: String,
    name: String,
    readonly: Boolean,
    error: Boolean,
    icon: String,
  },
  methods: {
    onRadioChange(value) {
      if (value === 'true') value = true
      if (value === 'false') value = false
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.radio
  display: flex
  align-items: center
  position: relative
  padding-left: 35px
  cursor: pointer

  input
    +visually-hidden

    &:checked ~ .radio__radiomark
      border-color: $color-theme

      &:after
        content: ""

.radio--readonly
  pointer-events: none
  cursor: default

  //input
  //  & ~ .radio__radiomark
  //    border-color: $color-readonly
  //
  //    &:after
  //      background-color: $color-readonly
  //
  //  &:checked ~ .radio__radiomark
  //    border-color: $color-readonly

.radio--error
  input
    & ~ .radio__radiomark
      border-color: $color-error

      &:after
        background-color: $color-error

    &:checked ~ .radio__radiomark
      border-color: $color-error

.radio__radiomark
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 3px
  left: 0
  width: 15px
  height: 15px
  background-color: #fff
  border: 1px solid rgba(56, 123, 191, 0.3)
  box-sizing: border-box
  border-radius: 50%

  &:after
    content: none
    width: 7px
    height: 7px
    background-color: $color-theme
    border-radius: 50%

.radio__text
  display: flex
  align-items: center
  gap: 0 6px
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: $color-text-base

.radio__icon
  line-height: 0
</style>
