<template>
  <div class="search-guests-list">
    <button
      v-for="guest in guests_list"
      :key="guest.id"
      @click="$emit('onSearchGuestsListClick', guest)"
      class="search-guests-list__item"
    >
      <span class="search-guests-list__name">
        <span>{{ guest.name }}</span>
        <span v-html="guest.language.icon" class="search-guests-list__icon"></span>
        <span v-if="guest.is_blacklisted" class="search-guests-list__blacklist">(черный список)</span>
      </span>
      <span class="search-guests-list__phone">{{ guest.phone }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AppSearchGuestsList',
  props: {
    guests_list: Array
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.search-guests-list
  display: flex
  flex-direction: column
  gap: 8px

.search-guests-list__item
  display: flex
  justify-content: space-between
  align-items: center
  gap: 0 15px
  padding: 16px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third
  transition: border-color 0.3s

  &:active
    border-color: $color-theme

  @media (hover: hover)
    &:hover,
    &:focus
      border-color: $color-theme

.search-guests-list__name
  display: flex
  align-items: center
  gap: 0 6px
  font-size: 16px
  font-weight: 600

.search-guests-list__icon
  line-height: 0

.search-guests-list__blacklist
  color: $status-close

.search-guests-list__phone
  font-size: 16px
</style>
