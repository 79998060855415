import Vue from 'vue'
import App from './App.vue'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
import router from './router'
import store from './store'

import './assets'
import './global'
import './my-components'

Vue.config.productionTip = false

// if (process.env.VUE_APP_TRACING_ORIGINS) {
//   Sentry.init({
//     Vue,
//     dsn: 'https://1958d1c4cd28478c9ba4b04df39f351c@o1091698.ingest.sentry.io/6109012',
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: [process.env.VUE_APP_TRACING_ORIGINS, /^\//],
//       }),
//     ],
//     tracesSampleRate: 1.0,
//   })
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
