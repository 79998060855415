<template>
  <div :class="['content', 'content--hall', { 'content--margin': show_sidebar }]" :style="{ 'height': viewport_height + 'px' }">
    <app-cells position="start">
      <VueCtkDateTimePicker
        id="date_time_picker"
        class="custom-date-time-picker"
        @is-hidden="onIsHiddenPicker"
        v-model="date_time_picker"
        :min-date="min_date"
        minute-interval="1"
        format="DD.MM.YYYY HH:mm"
        label="Выберите дату и время"
        color="#2f73ff"
        button-color="#2f73ff"
        button-now-translation="Сейчас"
        locale="ru"
        overlay
        noClearButton
        no-header
        no-label
      />
      <div class="polling-status" :class="{ 'polling-status--active': is_now }"></div>
      <app-button @click="onNewBookingButtonClick" size="small" type="button" class="desktop">Новая бронь</app-button>
      <button @click="onNewBookingButtonClick" type="button" class="add-booking-small  mobile">
        <img src="@/assets/img/new-booking.svg" alt="">
      </button>
    </app-cells>
    <!--------------------------------------------------------------------  hall-stat -->
    <app-hall-stat :guests="crm_data.guests" />
    <app-cells>
      <!--------------------------------------------------------------------  hall-tabs -->
      <div class="hall-tabs">
        <div
          v-for="hall in crm_data.halls"
          :key="hall.id"
          class="hall-tabs__item"
          :class="{ 'hall-tabs__item--active': hall.id === checked_hall.id }"
          @click="onCheckHallTabItem(hall)"
        >
          {{ hall.name }}
        </div>
      </div>
    </app-cells>
    <!-------------------------------------------------------------------- Hall -->
    <div class="hall" ref="hall">
      <div
        class="hall__body"
        :style="{ width: getCurrentHallWidth(checked_hall.settings) + 'px', height: getCurrentHallHeight(checked_hall.settings) + 'px' }"
      >
        <transition-group name="fade" tag="div">
          <div
            v-for="table in filteredTablesByHall"
            :key="table.id"
            class="hall__table"
            :class="[`hall__table--${table.number}`]"
            :style="{ left: getCurrentTableX(table.settings) + 'px', top: getCurrentTableY(table.settings) + 'px' }"
          >
            <!-------------------------------------------------------------------- hall-card -->
            <app-hall-card
              :number="table.number"
              :count_place_min="table.min_seat_number"
              :count_place_max="table.max_seat_number"
              :events="table.events"
              :icon="table.settings.svg_code"
              :status="table.status"
              :class="{
                'hall-card--main': findMainBookingTable(table),
                'hall-card--additional': findAdditionalBookingTables(table.id)
              }"
              :style="{ width: getCurrentTableWidth(table.settings) + 'px', height: getCurrentTableHeight(table.settings) + 'px' }"
              @click.native="onTableHallClick(table)"
            />
          </div>
        </transition-group>
      </div>
    </div>
    <app-sidebar-hall
      :class="{'sidebar-hall--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
      @open-sidebar="show_sidebar = true; is_show_booking = true;"
      :is_hide_footer="
        is_show_booking_new || is_show_booking_detail || is_show_booking_edit ||
        is_show_bookings_search || is_show_booking_extend_slide || (is_show_table_detail && current_table.status !== 'free')
      "
      ref="app_sidebar_hall"
    >
      <!-------------------------------------------------------------------- sidebar-hall header -->
      <template v-slot:header>
        <!-------------------------------------------------------------------- header table_detail -->
        <app-cells v-if="is_show_table_detail" :indent="false" position="between">
          <div class="title  title--medium  title--600">Стол № {{ current_table.number }}</div>
          <app-cells :indent="false">
            <button @click="onTableDetailClose" type="button">
              <!--close table-detail button-->
              <img src="@/assets/img/gg_close.svg" alt="">
            </button>
          </app-cells>
        </app-cells>
        <!-------------------------------------------------------------------- header bookings_list -->
        <app-cells v-if="is_show_bookings_list" :indent="false" position="between">
          <div class="title  title--medium  title--600">Бронирования</div>
          <app-cells :indent="false">
            <button @click="onOpenSearchBookings" type="button">
              <!--open search-bookings button-->
              <img src="@/assets/img/search-black-icon.svg" alt="">
            </button>
            <button @click="show_sidebar = false" type="button">
              <!--close bookings-list button-->
              <img src="@/assets/img/arrow-sidebar-right.svg" alt="">
            </button>
          </app-cells>
        </app-cells>
        <!-------------------------------------------------------------------- header bookings_search -->
        <app-cells v-if="is_show_bookings_search" :indent="false" position="between">
          <div class="title  title--medium  title--600">Поиск</div>
          <app-cells :indent="false">
            <button @click="is_show_bookings_search = false; is_show_bookings_list = true" type="button">
              <!--close bookings-list button-->
              <img src="@/assets/img/gg_close.svg" alt="">
            </button>
          </app-cells>
        </app-cells>
        <!-------------------------------------------------------------------- header booking_detail -->
        <template v-if="is_show_booking_detail">
          <app-cells position="between">
            <div class="title  title--medium  title--600">
              <router-link
                :to="{name: 'booking-detail', params: {id: current_booking.id}}"
                class="title__link"
              >
                Бронь № {{ current_booking.id }}</router-link>, {{ current_booking.count_guests }} чел.
            </div>
            <button @click="onBookingDetailClose" type="button" style="flex-shrink: 0;">
              <!--close booking-detail button-->
              <img src="@/assets/img/gg_close.svg" alt="">
            </button>
          </app-cells>
          <app-status :status="current_booking.status.id">
            {{ current_booking.status.name }} {{ isToday ? `(${viewCurrentBookingTime})` : '' }}
          </app-status>
        </template>
        <!-------------------------------------------------------------------- header new_booking -->
        <app-cells v-if="is_show_booking_new" :indent="false" position="between">
          <app-cells position="start" :indent="false">
            <div class="title  title--medium  title--600">Новая бронь</div>
            <app-button
              @click.native="and_additional = !and_additional"
              type="button" size="super_small" theme="accept"
            >
              {{ and_additional ? 'Групповое' : 'Одиночное' }}
            </app-button>
          </app-cells>
          <app-cells :indent="false">
            <button @click="onNewBookingClose" type="button">
              <!--close new-booking button-->
              <img src="@/assets/img/gg_close.svg" alt="">
            </button>
          </app-cells>
        </app-cells>
        <!-------------------------------------------------------------------- header edit_booking -->
        <app-cells v-if="is_show_booking_edit" :indent="false" position="between" :wrap="false">
          <app-cells position="start" :indent="false" :wrap="false">
            <div class="title  title--medium  title--600">Редактирование бронирования</div>
            <app-button
              @click.native="and_additional = !and_additional"
              :and_additional="and_additional"
              type="button" size="super_small" theme="accept"
            >
              {{ and_additional ? 'Групповое' : 'Одиночное' }}
            </app-button>
          </app-cells>
          <app-cells :indent="false">
            <button @click="onEditBookingClose" type="button" style="flex-shrink: 0;">
              <!--close new-booking button-->
              <img src="@/assets/img/gg_close.svg" alt="">
            </button>
          </app-cells>
        </app-cells>
        <!-------------------------------------------------------------------- header extend_slide_booking -->
        <app-cells v-if="is_show_booking_extend_slide" position="between">
          <div class="title  title--medium  title--600">
            {{ booking_slide_effect === 'extend' ? 'Продлить' : 'Сдвинуть' }} заявку на:
          </div>
          <button @click="onExtendSlideClose" type="button">
            <!--close booking-detail button-->
            <img src="@/assets/img/gg_close.svg" alt="">
          </button>
        </app-cells>
      </template>

      <!-------------------------------------------------------------------- table-detail -->
      <template v-if="is_show_table_detail">
        <app-table-detail :data="current_table" />
        <div v-if="filteredCurrentBookings.length" class="title  title--small  title--600">Бронирования</div>
        <app-table-reservations
          :bookings="filteredCurrentBookings"
          @onGetBooking="onGetBooking"
          :is_today="isToday"
        />
      </template>
      <!-------------------------------------------------------------------- bookings-list -->
      <div v-if="is_show_bookings_list" class="bookings-list">
        <div class="bookings-list__body">
          <template v-if="crm_data.all_bookings.length">
            <div v-for="key in Object.keys(sortingBookingsByTime).sort()" :key="key" class="bookings-list__time">
              <app-divider>{{ `${key}:00` }}</app-divider>
              <app-booking-card
                :bookings="sortingBookingsByTime[key]"
                :crm_data="crm_data"
                :is_today="isToday"
                @onGetBooking="onGetBooking"
              />
            </div>
          </template>
          <template v-else>
            <div class="bookings-list__plug">
              <img src="@/assets/img/empty-bookings-icon.svg" alt="">
              <div>На текущую дату еще нет бронирований</div>
            </div>
          </template>
        </div>
      </div>
      <!-------------------------------------------------------------------- bookings-search -->
      <app-bookings-search v-if="is_show_bookings_search" @onGetBooking="onGetBooking" />
      <!-------------------------------------------------------------------- new-booking -->
      <template v-if="is_show_booking_new">
        <app-new-booking
          @onStartTablesPicking="onStartTablesPicking"
          @onStopTablesPicking="onStopTablesPicking"
          @getAllData="getAllData"
          @resetBookingCreate="resetBookingCreate"
          @onNewBookingGuestDelete="onNewBookingGuestDelete"
          @onNewBookingGuestEdit="getCurrentGuest"
          @changeGuestType="changeGuestType"
          :booking_form="new_booking_form"
          :and_additional="and_additional"
          ref="new_booking"
        />
      </template>
      <!-------------------------------------------------------------------- booking-detail -->
      <app-booking-detail
        v-if="is_show_booking_detail"
        :booking="current_booking"
        :is_today="isToday"
        :is_picking_table="is_picking_table"
        @onEditBookingButtonClick="onEditBookingButtonClick"
        @getReactiveBooking="getReactiveBooking"
        @onExtendSlideOpen="onExtendSlideOpen"
        @onShowAcceptModal="onShowAcceptModal"
        @onShowInfoModal="onShowInfoModal"
        @onReplaceStart="onReplaceStart"
        ref="booking_detail"
      />
      <!-------------------------------------------------------------------- edit-booking -->
      <template v-if="is_show_booking_edit">
        <app-edit-booking
          @onStartTablesPicking="onStartTablesPicking"
          @onStopTablesPicking="onStopTablesPicking"
          @getAllData="getAllData"
          @resetBookingEdit="resetBookingEdit"
          @onNewBookingGuestDelete="onNewBookingGuestDelete"
          @onNewBookingGuestEdit="getCurrentGuest"
          @changeGuestType="changeGuestType"
          :booking_form="new_booking_form"
          :and_additional="and_additional"
        />
      </template>
      <!-------------------------------------------------------------------- extend-slide-booking -->
      <template v-if="is_show_booking_extend_slide">
        <app-extend-slide-booking
          :booking_slide_effect="booking_slide_effect"
          :booking="current_booking"
          @onExtendSlideClose="onExtendSlideClose"
          @onShowAcceptModal="onShowAcceptModal"
          ref="extend_slide"
        />
      </template>

      <!-------------------------------------------------------------------- sidebar-hall footer -->
      <template v-slot:footer>
        <!-------------------------------------------------------------------- footer table_detail -->
        <app-cells v-if="is_show_table_detail" :indent="false" position="center">
          <app-button
            v-if="!is_now && current_table.status === 'free'"
            @click="onBookingFuture"
            type="button"
          >
            Забронировать
          </app-button>
          <app-button
            v-if="is_now && current_table.status === 'free'"
            @click="onSeatGuestButtonClick"
            theme="accept"
            type="button"
          >
            Посадить гостя
          </app-button>
        </app-cells>
        <!-------------------------------------------------------------------- footer bookings_list -->
        <app-sidebar-filters
          v-if="is_show_bookings_list"
          :bookings="crm_data.all_bookings"
          @filterBookingsList="filterBookingsList"
        />
      </template>
    </app-sidebar-hall>
    <!-------------------------- guest-modal -->
    <modal
      name="guest-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <app-cells position="start">
          <div class="modal__title  modal__title--no-indent">Добавить гостя</div>
          <app-button v-if="!isGuestSelected" @click.native="add_guest_by_phone = !add_guest_by_phone" type="button" size="super_small">
            {{ add_guest_by_phone ? 'По телефону' : 'По имени' }}
          </app-button>
        </app-cells>
        <div class="modal__body">
          <template v-if="guest_flag === '' || guest_flag === 'empty'">
            <app-form-group v-if="add_guest_by_phone" label="Телефон">
              <app-phone
                v-model="phone_search"
                @input.native="onPhoneSearch($event.target.value, 'phone')"
                placeholder="Введите телефон гостя"
                @paste.native.prevent
              />
            </app-form-group>
            <app-form-group v-else label="Имя">
              <app-input
                v-model="phone_search"
                @input.native="onPhoneSearch($event.target.value, 'name')"
                placeholder="Введите имя гостя"
              />
            </app-form-group>
            <div class="modal__search">
              <app-search-guests-list
                @onSearchGuestsListClick="onSearchGuestsListClick"
                :guests_list="guests_options_search"
              />
            </div>
            <app-cells position="center">
              <app-button
                v-if="guest_flag === 'empty'"
                @click="onEmptyButtonPhoneSearchClick"
                type="button" size="normal"
              >
                Гостя нет в базе, добавить его
              </app-button>
            </app-cells>
          </template>
          <app-guest-form
            v-if="guest_flag !== 'empty' && guest_flag !== ''"
            :form="current_guest"
            :flag="guest_flag"
            :languages="languages"
            @guestAdded="guestAdded"
          />
        </div>
        <div slot="top-right">
          <button @click="onGuestModalClear" class="modal__close"></button>
        </div>
      </div>
    </modal>
    <!-------------------------- guest-seat-modal -->
    <modal
      name="guest-seat-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="onGuestSeatModalClear" class="modal__close"></button>
        </div>
        <div class="modal__title">Посадить гостя</div>
        <div class="modal__body">
          <app-guest-seat-form :guest_seat_form="guest_seat_form" @guestSeatModalSubmit="guestSeatModalSubmit" />
        </div>
      </div>
    </modal>
    <!-------------------------- booking-accept-modal -->
    <modal
      name="accept-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="$modal.hide('accept-modal')" class="modal__close"></button>
        </div>
        <div class="modal__title  modal__title--center">{{ accept_modal_title }}</div>
        <div
          class="modal__body modal__body--center modal__body--text-center"
        >
          {{ accept_modal_text }}
        </div>
        <app-cells position="center" v-if="accept_modal_type === 'cancel'">
          <div class="radio-tags">
            <label
              v-for="reason in cancel_booking_reasons"
              :key="reason.id"
              class="radio-tags__item"
              :class="{ 'radio-tags__item--active': current_booking.cancel_reason === reason.id }"
            >
              <input type="radio" :value="reason.id" v-model="current_booking.cancel_reason" name="cancel-reason">
              {{ reason.name }}
            </label>
          </div>
        </app-cells>
        <template v-if="(accept_modal_type === 'cancel' || accept_modal_type === 'close') && current_booking.is_paid">
          <div class="modal__info">
            <span class="modal__info-title">
              <img src="@/assets/img/modal-info-icon.svg" alt="info-icon ">
              {{ accept_modal_type === 'cancel' ? 'По данному бронированию уже внесен депозит.' : 'По данному бронированию был внесен депозит.' }}
            </span>
            <span class="modal__info-text">
              {{ accept_modal_type === 'cancel' ? 'Укажите, требуется ли возврат.' : 'Если он не истрачен полностью, укажите, необходимо ли оформить возврат.' }}
            </span>
          </div>
          <app-cells position="center">
            <div class="radio-tags">
              <label
                v-for="status in refund_statuses"
                :key="status.id"
                class="radio-tags__item"
                :class="{ 'radio-tags__item--active': current_booking.refund_status === status.id }"
              >
                <input type="radio" :value="status.id" v-model="current_booking.refund_status" name="refund-status">
                {{ status.name }}
              </label>
            </div>
          </app-cells>
          <app-cells v-if="current_booking.refund_status === 'required'" position="center">
            <app-form-group label="Укажите сумму возврата" label-for="balance">
              <app-input
                @input.native="$v.$touch"
                v-model.number="current_booking.refund_request_amount"
                :error="$v.current_booking.refund_request_amount.$error"
                id="balance"
                placeholder="Введите сумму"
              />
              <template #error>
                <div v-if="$v.current_booking.refund_request_amount.$dirty && !$v.current_booking.refund_request_amount.required">Обязательное поле</div>
                <div v-if="$v.current_booking.refund_request_amount.$dirty && !$v.current_booking.refund_request_amount.between">
                  Некорректная сумма
                </div>
              </template>
            </app-form-group>
          </app-cells>
        </template>
        <template v-if="accept_modal_type === 'open' && current_booking.is_paid">
          <div
            class="modal__body modal__body--center modal__body--text-center modal__body--mt"
          >
            После открытия в R keeper будет создан заказ на {{ current_booking.table_number }} стол и внесен депозит в
            размере {{ new Intl.NumberFormat('ru-RU').format(current_booking.balance) }} ₽. Убедитесь, что стол, указанный
            в бронировании, совпадает с фактической посадкой гостя.
          </div>
        </template>
        <app-cells position="center">
          <app-button
            @click="onAcceptBooking"
            size="normal"
            type="button"
            ref="accept"
            :disabled="(accept_modal_type === 'cancel' && !current_booking.cancel_reason) || $v.current_booking.$error"
          >
            {{ accept_modal_btnText }}
          </app-button>
        </app-cells>
      </div>
    </modal>
    <!-------------------------- info-modal -->
    <modal
      name="info-modal"
      :width="600"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="$modal.hide('info-modal')" class="modal__close"></button>
        </div>
        <app-cells position="center">
          <img width="60" height="60" src="@/assets/img/rk-logo.png" alt="rk-logo">
        </app-cells>
        <div class="modal__title  modal__title--center">Информация о заказе</div>
        <div class="modal__grid">
          <div class="modal__item">
            <span>Номер заказа:</span>
            <span>{{ keeper_info.name }}</span>
          </div>
          <div class="modal__item">
            <span>Официант:</span>
            <span>{{ keeper_info.waiter }}</span>
          </div>
          <div class="modal__item">
            <span>Номер стола:</span>
            <span>{{ keeper_info.table }}</span>
          </div>
          <div class="modal__item">
            <span>Сумма предоплаты:</span>
            <span>{{ keeper_info.prepay_sum }} &#8381;</span>
          </div>
        </div>
      </div>
    </modal>
    <!-------------------------- replace-modal -->
    <modal
      name="replace-modal"
      :width="720"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <div slot="top-right">
          <button @click="onResetReplaceTable" class="modal__close"></button>
        </div>
        <div class="modal__title  modal__title--center">Замена стола</div>
        <app-replace-list :replace_data="replace_data" />
        <app-cells position="center" :indent="false">
          <app-button @click="onAcceptReplaceTable" size="normal" type="button" ref="accept_replace">Подтвердить действие</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { getCRMAllData, getGuests, getGuest, getReplaceTable, postReplaceTable, getCancelReasons, getLanguages, getRefundStatuses } from '@/http'
import { format, addHours, addMinutes, subMinutes, getTime, formatISO, isToday, differenceInMinutes } from 'date-fns'
import { dateFormatting } from '@/helpers'
import { debounce } from 'lodash'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { between, required } from 'vuelidate/lib/validators'
import AppHallCard from './Card'
import AppSidebarHall from './Sidebar'
import AppBookingCard from './Booking/Card'
import AppDivider from '@/components/AppDivider'
import AppTableDetail from './Table/Detail'
import AppTableReservations from './Table/Reservations'
import AppSidebarFilters from '@/components/AppSidebarFilters'
import AppBookingDetail from './Booking/Detail'
import AppNewBooking from './Booking/New'
import AppSearchGuestsList from '@/components/AppSearchGuestsList'
import AppGuestForm from '@/components/AppGuestForm'
import AppEditBooking from './Booking/Edit'
import AppBookingsSearch from './Booking/Search'
import AppGuestSeatForm from '@/components/AppGuestSeatForm'
import AppExtendSlideBooking from './Booking/ExtendSlide'
import AppHallStat from './Stat'
import AppReplaceList from '@/components/AppReplaceList'

export default {
  name: 'Hall',
  components: {
    AppBookingsSearch,
    VueCtkDateTimePicker,
    AppHallCard,
    AppSidebarHall,
    AppBookingCard,
    AppDivider,
    AppTableDetail,
    AppTableReservations,
    AppSidebarFilters,
    AppBookingDetail,
    AppNewBooking,
    AppSearchGuestsList,
    AppGuestForm,
    AppEditBooking,
    AppGuestSeatForm,
    AppExtendSlideBooking,
    AppHallStat,
    AppReplaceList,
  },
  data() {
    return {
      timeout: null,
      show_sidebar: false,
      viewport_width: null,
      viewport_height: null,
      date_time_picker: '',
      min_date: format(new Date(), 'yyyy-MM-dd HH:mm'),
      crm_data: { tables: [], all_bookings: [] },
      checked_hall: {},
      current_table: {},
      current_booking: {},
      is_show_table_detail: false,
      is_show_booking: true,
      is_show_bookings_list: true,
      is_show_booking_new: false,
      is_show_booking_detail: false,
      is_show_booking_edit: false,
      is_show_bookings_search: false,
      is_show_booking_extend_slide: false,
      is_picking_table: '',
      and_additional: false,
      new_booking_form: {
        time_start: '',
        time_end: '',
        table: '',
        additional_tables: [],
        guest: [],
        additional_guests: []
      },
      phone_search: '',
      guests_options_search: [],
      current_guest: {},
      guest_flag: '',
      guest_type: '',
      filter_bookings_list_status: '',
      guest_seat_form: {},
      is_now: true,
      booking_slide_effect: 'extend',
      add_guest_by_phone: true,
      accept_modal_title: '',
      accept_modal_text: '',
      accept_modal_btnText: '',
      accept_modal_type: '',
      keeper_info: {},
      replace_data: [],
      cancel_booking_reasons: [],
      languages: [],
      refund_statuses: [],
    }
  },
  validations: {
    current_booking: {
      refund_request_amount: {
        required,
        between (value) {
          return between(1, this.current_booking.balance)(value)
        }
      }
    },
  },
  created() {
    this.resizeWindowHandler()
    window.addEventListener('resize', debounce(this.resizeWindowHandler, 250))
    if (this.$route.params.date_from_stat || this.$route.params.waiting_data) {
      if (this.$route.params.date_from_stat) this.date_time_picker = this.$route.params.date_from_stat
      if (this.$route.params.waiting_data) {
        this.min_date = ''
        this.is_show_bookings_list = false
        this.is_show_booking = true
        this.is_show_booking_new = true
        this.is_picking_table = 'start'
        this.new_booking_form.guest = this.$route.params.waiting_data.guest
        this.new_booking_form.count_guests = this.$route.params.waiting_data.count_guests
        this.new_booking_form.comment_hostes = this.$route.params.waiting_data.comment
        this.new_booking_form.time_start = this.$route.params.waiting_data.time
        this.new_booking_form.time_end = formatISO(addHours(new Date(this.$route.params.waiting_data.time), 2)).slice(0, 16)
        this.date_time_picker = format(new Date(this.$route.params.waiting_data.time), 'dd.MM.yyyy HH:mm')
        this.$nextTick(() => { this.min_date = format(new Date(), 'yyyy-MM-dd HH:mm') })
      }
    } else {
      this.pollingData()
    }
    getLanguages().then(response => { this.languages = response.data })
    setTimeout(() => { this.show_sidebar = true }, 1500)
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
    this.timeout = null
  },
  watch: {
    is_show_booking() {
      this.$refs.app_sidebar_hall.setHeight()
    },
    is_show_booking_new() {
      this.$refs.app_sidebar_hall.setHeight()
      this.current_booking = {}
    },
    is_show_booking_detail() {
      this.$refs.app_sidebar_hall.setHeight()
    },
    is_show_booking_edit() {
      this.$refs.app_sidebar_hall.setHeight()
    },
    is_show_bookings_search() {
      this.$refs.app_sidebar_hall.setHeight()
    },
    is_show_table_detail() {
      if (this.current_table.status !== 'free') this.$refs.app_sidebar_hall.setHeight()
    },
    date_time_picker(value) {
      const start = getTime(new Date(subMinutes(new Date(), 1)))
      const current = getTime(new Date(dateFormatting(value, 'normal-to-iso')))
      const end = getTime(new Date(addMinutes(new Date(), 1)))

      this.is_now = current > start && current < end
    },
    is_now(value) {
      if (!value) {
        clearTimeout(this.timeout)
        this.timeout = null
        this.getAllData()
      } else if (!this.timeout) this.pollingData()
    },
    add_guest_by_phone(value) {
      this.phone_search = ''
      this.guests_options_search = []
      if (value) this.guest_flag = ''
      else this.guest_flag = 'empty'
    }
  },
  computed: {
    isToday() {
      return isToday(new Date(dateFormatting(this.date_time_picker, 'normal-to-iso')))
    },
    viewCurrentBookingTime() {
      if (this.current_booking.status.id === 'new') {
        return this.calculateTime(differenceInMinutes(new Date(dateFormatting(this.current_booking.time_start, 'normal-to-iso')), new Date()))
      }
      if (this.current_booking.status.id === 'wait') {
        return this.calculateTime(differenceInMinutes(new Date(), new Date(dateFormatting(this.current_booking.time_start, 'normal-to-iso'))))
      }
      if (this.current_booking.status.id === 'open') {
        return this.calculateOpenTime(differenceInMinutes(new Date(this.current_booking.time_end), new Date()))
      }
      return false
    },
    filteredTablesByHall() {
      return this.crm_data.tables.filter(table => table.hall === this.checked_hall.id)
    },
    filteredCurrentBookings() {
      return this.crm_data.all_bookings.filter(booking => booking.table_id === this.current_table.id || booking.additional_tables.includes(this.current_table.id))
    },
    sortingBookingsByTime() {
      if (this.filter_bookings_list_status) {
        if (this.filter_bookings_list_status === 'not-read') {
          return this.crm_data.all_bookings.filter(item => !item.is_read).reduce((rv, x) => {
            (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
            return rv
          }, {})
        } else if (this.filter_bookings_list_status === 'closure') {
          return this.crm_data.all_bookings
            .filter(item => {
              return item.status.id === 'open' && differenceInMinutes(new Date(item.time_end), new Date()) <= 0
            })
            .reduce((rv, x) => {
              (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
              return rv
            }, {})
        } else if (this.filter_bookings_list_status === 'open') {
          return this.crm_data.all_bookings
            .filter(item => {
              return item.status.id === 'open' && differenceInMinutes(new Date(item.time_end), new Date()) > 0
            })
            .reduce((rv, x) => {
              (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
              return rv
            }, {})
        } else if (this.filter_bookings_list_status === 'new') {
          if (this.crm_data.all_bookings.filter(item => item.status.id === 'new' && item.is_read).length) {
            return this.crm_data.all_bookings.filter(item => item.status.id === 'new' && item.is_read).reduce((rv, x) => {
              (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
              return rv
            }, {})
          } else {
            return this.crm_data.all_bookings.reduce((rv, x) => {
              (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
              return rv
            }, {})
          }
        } else {
          return this.crm_data.all_bookings.filter(item => item.status.id === this.filter_bookings_list_status).reduce((rv, x) => {
            (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
            return rv
          }, {})
        }
      } else {
        return this.crm_data.all_bookings.reduce((rv, x) => {
          (rv[x['short_time_start'].slice(0, 2)] = rv[x['short_time_start'].slice(0, 2)] || []).push(x)
          return rv
        }, {})
      }
    },
    isGuestSelected() {
      return Object.keys(this.current_guest).length > 0
    },
  },
  methods: {
    getCurrentTableX(settings) {
      if (this.viewport_width > 1200) return settings.x
      else return settings.x_adaptive
    },
    getCurrentTableY(settings) {
      if (this.viewport_width > 1200) return settings.y
      else return settings.y_adaptive
    },
    getCurrentTableWidth(settings) {
      if (this.viewport_width > 1200) return settings.width
      else return settings.width_adaptive
    },
    getCurrentTableHeight(settings) {
      if (this.viewport_width > 1200) return settings.height
      else return settings.height_adaptive
    },
    getCurrentHallWidth(settings) {
      if (settings) {
        if (this.viewport_width > 1200) return settings.width
        else return settings.width_adaptive
      }
    },
    getCurrentHallHeight(settings) {
      if (settings) {
        if (this.viewport_width > 1200) return settings.height
        else return settings.height_adaptive
      }
    },
    pollingData() {
      this.getAllData(this.getCurrentDate())
        .then(() => {
          this.timeout = setTimeout(() => {
            this.pollingData()
          }, 60000)
        })
    },
    getAllData(date_time = this.date_time_picker, booking_id = null, table_id = this.current_table.id) {
      return new Promise(resolve => {
        if (date_time) this.date_time_picker = date_time
        else date_time = this.getCurrentDate()
        getCRMAllData(
          dateFormatting(date_time, 'normal-to-iso'))
          .then(res => {
            this.crm_data = { ...res.data }
            if (!Object.keys(this.checked_hall).length) this.checked_hall = res.data.halls[0]
            if (booking_id) {
              this.current_booking = res.data.all_bookings.find(item => item.id === booking_id)
              this.current_booking.time_start = dateFormatting(this.current_booking.time_start, 'iso-to-normal')
              this.current_booking.created_at = dateFormatting(this.current_booking.created_at, 'iso-to-normal')
              this.current_booking.updated_at = dateFormatting(this.current_booking.updated_at, 'iso-to-normal')
            }
            if (table_id) this.current_table = res.data.tables.find(item => item.id === table_id)

            resolve(res.data)
          })
      })
    },
    calculateTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    calculateOpenTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      if (difference <= 0) return 'Закрытие'
      else return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    resizeWindowHandler() {
      this.viewport_width = window.innerWidth
      this.viewport_height = window.innerHeight
    },
    findMainBookingTable(table) {
      return ((this.is_show_booking_edit || this.is_show_booking_new) && table.id === this.new_booking_form.table.id) ||
        (this.is_show_booking_detail && table.id === this.current_booking.table_id)
    },
    getCurrentDate() {
      return format(new Date(), 'dd.MM.yyyy HH:mm')
    },
    filterBookingsList(status) {
      this.filter_bookings_list_status = status
    },
    onIsHiddenPicker() {
      this.$store.commit('setSidebarScrollTop', 0)
      document.getElementsByClassName('sidebar-hall__main')[0].scrollTop = this.$store.state.sidebarScrollTop
      this.getAllData()
    },
    onTableHallClick(table) {
      this.current_table = { ...table }

      if (!this.is_picking_table) {
        this.is_show_booking = false
        if (this.is_show_bookings_list) this.is_show_bookings_list = false
        if (this.is_show_booking_detail) this.is_show_booking_detail = false
        if (this.is_show_booking_edit) this.is_show_booking_edit = false
        if (this.is_show_bookings_search) this.is_show_bookings_search = false
        this.current_booking = {}
        this.is_show_table_detail = true
        if (!this.show_sidebar) this.show_sidebar = true
      } else if (this.is_picking_table === 'replace') {
        getReplaceTable({ table: this.current_table.id, booking: this.current_booking.id })
          .then(res => {
            this.replace_data = res.data
            this.$modal.show('replace-modal')
          })
      } else {
        if (this.current_table.status === 'free' || this.current_booking.table_id === this.current_table.id || this.current_booking.additional_tables.map(item => item.id).includes(this.current_table.id)) {
          if (this.is_picking_table === 'main') {
            if (this.new_booking_form.additional_tables.map(item => item.id).includes(this.current_table.id)) {
              this.$notify({
                type: 'info',
                title: 'Внимание',
                text: 'Стол уже выбран как дополнительный'
              })
            } else {
              this.new_booking_form.table = this.current_table.id !== this.new_booking_form.table ? this.current_table : ''
            }
          }
          if (this.is_picking_table === 'additional') {
            if (this.current_table.id === this.new_booking_form.table.id) {
              this.$notify({
                type: 'info',
                title: 'Внимание',
                text: 'Стол уже выбран как основной'
              })
            } else {
              if (this.new_booking_form.additional_tables.map(item => item.id).includes(this.current_table.id)) {
                this.new_booking_form.additional_tables = this.new_booking_form.additional_tables.filter(item => item.id !== this.current_table.id)
              } else {
                this.new_booking_form.additional_tables.push(this.current_table)
              }
            }
          }
        } else {
          this.$notify({
            type: 'info',
            title: 'Внимание',
            text: 'Выбирать можно только свободные столы'
          })
        }
      }
    },
    onTableDetailClose() {
      this.is_show_booking = true
      this.is_show_table_detail = false
      this.is_show_bookings_list = true
    },
    onGetBooking(booking) {
      this.$store.commit('setSidebarScrollTop', document.getElementsByClassName('sidebar-hall__main')[0].scrollTop)
      this.current_booking = { ...booking }
      this.date_time_picker = dateFormatting({ ...booking }.time_start, 'iso-to-normal')
      this.current_booking.time_start = dateFormatting({ ...booking }.time_start, 'iso-to-normal')
      this.current_booking.created_at = dateFormatting({ ...booking }.created_at, 'iso-to-normal')
      this.current_booking.updated_at = dateFormatting({ ...booking }.updated_at, 'iso-to-normal')

      if (booking.hall && this.checked_hall.id !== booking.hall.id) this.checked_hall = booking.hall
      if (this.is_show_bookings_list) this.is_show_bookings_list = false
      if (this.is_show_bookings_search) this.is_show_bookings_search = false
      if (this.is_show_table_detail) this.is_show_table_detail = false
      this.is_show_booking_detail = true
    },
    onBookingDetailClose() {
      if (this.isToday) this.date_time_picker = this.getCurrentDate()
      this.current_booking = {}
      this.is_show_booking_detail = false
      if (this.is_picking_table === 'replace') this.is_picking_table = ''
      if (this.is_show_booking) this.is_show_bookings_list = true
      else this.is_show_table_detail = true
      this.$nextTick(() => {
        document.getElementsByClassName('sidebar-hall__main')[0].scrollTop = this.$store.state.sidebarScrollTop
      })
    },
    getReactiveBooking(booking_id, flag, table_id) {
      if (flag === 'reset') {
        this.getAllData(undefined, null, table_id)
          .then(() => {
            this.is_show_booking_detail = false
            this.is_show_table_detail = true
          })
      } else {
        this.getAllData(this.date_time_picker, booking_id, table_id)
      }
      this.$refs.accept.preload = false
      this.$modal.hide('accept-modal')
    },
    findAdditionalBookingTables(id) {
      if (id) {
        return ((this.is_show_booking_edit || this.is_show_booking_new) && this.new_booking_form.additional_tables.map(item => item.id).includes(id)) ||
          (this.is_show_booking_detail && this.current_booking.additional_tables.length > 0 && this.current_booking.additional_tables.map(item => {
            if (typeof item === 'number') return item
            else return item.id
          }).includes(id))
      } else {
        return []
      }
    },
    onNewBookingButtonClick() {
      this.new_booking_form = {
        time_start: '',
        time_end: '',
        table: '',
        additional_tables: [],
        guest: [],
        additional_guests: []
      }
      if (this.$route.params.waiting_data) this.$route.params.waiting_data = ''
      this.is_picking_table = 'start'
      this.new_booking_form.time_start = dateFormatting(this.date_time_picker, 'normal-to-iso').slice(0, 16)
      this.new_booking_form.time_end =
        formatISO(new Date(addHours(new Date(dateFormatting(this.date_time_picker, 'normal-to-iso')), 2))).slice(0, 16)
      if (!this.show_sidebar) this.show_sidebar = true
      if (!this.is_show_booking) this.is_show_booking = true
      if (this.is_show_bookings_list) this.is_show_bookings_list = false
      if (this.is_show_booking_detail) this.is_show_booking_detail = false
      if (this.is_show_table_detail) this.is_show_table_detail = false
      if (this.is_show_booking_edit) {
        this.is_show_booking_edit = false
        this.is_picking_table = ''
      }
      this.is_show_booking_new = true
    },
    onEditBookingButtonClick() {
      const tempBooking = { ...this.current_booking }
      const etalon_additional_tables = this.current_booking.additional_tables
      const etalon_additional_tables_number = this.current_booking.additional_tables_number

      tempBooking.guest = []
      if (this.current_booking.guest) tempBooking.guest[0] = this.current_booking.guest
      tempBooking.table = {
        id: tempBooking.table_id,
        number: tempBooking.table_number,
        max_seat_number: this.crm_data.tables.find(table => table.id === tempBooking.table_id).max_seat_number
      }
      etalon_additional_tables.forEach((item, index) => {
        if (!item.hasOwnProperty('id')) {
          tempBooking.additional_tables[index] = {
            id: item,
            number: etalon_additional_tables_number[index],
            max_seat_number: this.crm_data.tables.find(table => table.id === item).max_seat_number
          }
        }
      })
      this.is_picking_table = 'start'
      this.and_additional = tempBooking.additional_tables.length > 0
      tempBooking.time_start = dateFormatting(tempBooking.time_start, 'normal-to-iso').slice(0, 16)
      tempBooking.time_end = tempBooking.time_end.slice(0, 16)
      this.new_booking_form = { ...tempBooking }
      this.is_show_booking_detail = false
      this.is_show_booking_edit = true
    },
    onNewBookingClose() {
      this.is_show_booking_new = false
      this.is_show_bookings_list = true
      this.is_picking_table = ''
      this.new_booking_form = {
        time_start: '',
        time_end: '',
        table: '',
        additional_tables: [],
        guest: [],
        additional_guests: []
      }
      if (this.$route.params.waiting_data) this.$route.params.waiting_data = ''
    },
    onEditBookingClose() {
      this.is_show_booking_edit = false
      this.is_picking_table = ''
      this.new_booking_form = {
        time_start: '',
        time_end: '',
        table: '',
        additional_tables: [],
        guest: [],
        additional_guests: []
      }
      this.is_show_booking_detail = true
    },
    onStartTablesPicking(type) {
      this.is_picking_table = type
    },
    onStopTablesPicking() {
      this.is_picking_table = 'in-process'
    },
    resetBookingCreate() {
      this.onNewBookingClose()
      this.getAllData()
    },
    resetBookingEdit(booking_id) {
      this.getAllData(this.date_time_picker, booking_id)
        .then(() => { this.onEditBookingClose() })
    },
    onPhoneSearch(value, flag) {
      if (value) {
        if (flag === 'phone') {
          this.searchPhone(value.replace(/\s+/g, ''), this)
          if (value.length > 2 && this.phone_search !== 0 && !this.guests_options_search.length) this.guest_flag = 'empty'
          else this.guest_flag = ''
        } else if (flag === 'name') {
          this.searchPhone(value, this)
        }
      }
    },
    searchPhone: debounce((search, vm) => {
      getGuests({ search, page: 1, page_size: 20 })
        .then(res => { vm.guests_options_search = res.data.results })
    }, 350),
    onEmptyButtonPhoneSearchClick() {
      if (this.add_guest_by_phone) this.$set(this.current_guest, 'phone', this.phone_search)
      else this.$set(this.current_guest, 'name', this.phone_search)
      this.guest_flag = 'new'
    },
    getCurrentGuest(guest_id, type) {
      getGuest(guest_id).then(res => {
        this.current_guest = { ...res.data }
        this.current_guest.birthday = dateFormatting(res.data.birthday, 'iso-to-normal-small')
        if (type) this.guest_type = type
        this.guest_flag = 'edit'
        this.$modal.show('guest-modal')
      })
    },
    onSearchGuestsListClick(guest) {
      if ((this.new_booking_form.guest.length && this.new_booking_form.guest[0].id === guest.id)) {
        this.$notify({
          type: 'info',
          title: 'Внимание',
          text: 'Этот гость уже добавлен как основной'
        })
      } else if (this.new_booking_form.additional_guests.length && this.new_booking_form.additional_guests.map(item => item.id).includes(guest.id)) {
        this.$notify({
          type: 'info',
          title: 'Внимание',
          text: 'Этот гость уже добавлен как дополнительный'
        })
      } else this.getCurrentGuest(guest.id)
    },
    guestAdded(new_guest, lang) {
      if (this.guest_type === 'main') {
        if (this.new_booking_form.guest.length > 0) this.new_booking_form.guest.shift()
        if (!new_guest) this.new_booking_form.guest.push({ ...this.current_guest })
        else this.new_booking_form.guest.push({ ...new_guest })
        this.new_booking_form.guest[0].language = this.languages.find(item => item.id === lang)
      } else {
        if (this.new_booking_form.additional_guests.map(item => item.id).includes(this.current_guest.id)) {
          const index = this.new_booking_form.additional_guests
            .indexOf(this.new_booking_form.additional_guests.find(item => item.id === this.current_guest.id))

          this.new_booking_form.additional_guests.splice(index, 1, { ...this.current_guest })
        } else {
          if (!new_guest) this.new_booking_form.additional_guests.push({ ...this.current_guest })
          else this.new_booking_form.additional_guests.push({ ...new_guest })
        }
      }
      this.onGuestModalClear()
    },
    onGuestModalClear() {
      this.$modal.hide('guest-modal')
      this.guests_options_search = []
      this.current_guest = {}
      this.guest_flag = ''
      this.phone_search = ''
      this.add_guest_by_phone = true
    },
    onNewBookingGuestDelete(guest_id, type) {
      if (type === 'main') this.new_booking_form.guest.shift()
      else {
        const index = this.new_booking_form.additional_guests
          .indexOf(this.new_booking_form.additional_guests.find(item => item.id === guest_id))

        this.new_booking_form.additional_guests.splice(index, 1)
      }
    },
    changeGuestType(type) {
      this.guest_type = type
    },
    onCheckHallTabItem(hall) {
      this.$refs.hall.scrollTo(0, 0)
      this.checked_hall = hall
    },
    onOpenSearchBookings() {
      this.is_show_bookings_list = false
      this.is_show_bookings_search = true
    },
    onSeatGuestButtonClick() {
      this.guest_seat_form.time_start = this.date_time_picker
      this.guest_seat_form.time_end =
        formatISO(new Date(addHours(new Date(dateFormatting(this.date_time_picker, 'normal-to-iso')), 2))).slice(0, 16)
      this.guest_seat_form.table = this.current_table
      this.$modal.show('guest-seat-modal')
    },
    onGuestSeatModalClear() {
      this.$modal.hide('guest-seat-modal')
      this.guest_seat_form = {}
    },
    guestSeatModalSubmit(table_id) {
      if (table_id) this.getAllData(undefined, undefined, table_id)
      this.onGuestSeatModalClear()
    },
    onExtendSlideOpen(effect) {
      this.booking_slide_effect = effect
      this.is_show_booking_detail = false
      this.is_show_booking_extend_slide = true
    },
    onExtendSlideClose(flag) {
      if (flag === 'patched') {
        this.getAllData(undefined, this.current_booking.id).then(() => {
          this.is_show_booking_extend_slide = false
          this.is_show_booking_detail = true
        })
        this.$refs.accept.preload = false
        this.$modal.hide('accept-modal')
      } else {
        this.is_show_booking_extend_slide = false
        this.is_show_booking_detail = true
      }
    },
    onBookingFuture() {
      this.onNewBookingButtonClick()
      this.new_booking_form.table = { ...this.current_table }
    },
    onShowAcceptModal(title, text, btnText, type) {
      this.accept_modal_title = title
      this.accept_modal_text = text
      this.accept_modal_type = type
      this.accept_modal_btnText = btnText
      if (type === 'cancel' || type === 'close') {
        if (!this.refund_statuses.length) {
          getRefundStatuses()
            .then(response => {
              this.refund_statuses = response.data
              this.current_booking.refund_status = 'not_required'
            })
        } else this.current_booking.refund_status = 'not_required'
        if (!this.cancel_booking_reasons.length) {
          getCancelReasons()
            .then(response => {
              this.cancel_booking_reasons = response.data
            })
        }
        if (this.current_booking.refund_request_amount !== parseInt(this.current_booking.balance)) {
          this.current_booking.refund_request_amount = parseInt(this.current_booking.balance)
        }
      }
      this.$modal.show('accept-modal')
    },
    onAcceptBooking() {
      this.$refs.accept.preload = true
      if (this.accept_modal_type === 'accept') this.$refs.booking_detail.onAcceptBooking()
      if (this.accept_modal_type === 'cancel') this.$refs.booking_detail.onCancelBooking()
      if (this.accept_modal_type === 'open') this.$refs.booking_detail.onOpenBooking()
      if (this.accept_modal_type === 'close') this.$refs.booking_detail.onCloseBooking()
      if (this.accept_modal_type === 'slide' || this.accept_modal_type === 'extend') this.$refs.extend_slide.sendForm()
      if (this.accept_modal_type === 'sms') this.$refs.booking_detail.onSendSMS()
      if (this.accept_modal_type === 'create_rk_booking') this.$refs.booking_detail.onCreateRKBooking()
      if (this.accept_modal_type === 'create_rk_prepay') this.$refs.booking_detail.onCreateRKPrepay()
    },
    onShowInfoModal(info) {
      this.keeper_info = info
      this.$modal.show('info-modal')
    },
    onReplaceStart() {
      this.is_picking_table = 'replace'
    },
    onAcceptReplaceTable() {
      this.$refs.accept_replace.preload = true
      postReplaceTable({ table: this.current_table.id, booking: this.current_booking.id })
        .finally(() => { this.$refs.accept_replace.preload = false })
        .then(() => {
          this.onResetReplaceTable()
          this.is_show_booking_detail = false
          this.is_show_bookings_list = true
          this.getAllData()
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Замена стола произошла успешно'
          })
        })
    },
    onResetReplaceTable() {
      this.$modal.hide('replace-modal')
      this.is_picking_table = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.3s, transform 0.3s

.fade-enter, .fade-leave-to
  transform: translateX(100%)
  opacity: 0

</style>
