<template>
  <div class="sidebar">
    <div class="sidebar__logo">
      <img src="@/assets/img/ag-black.svg" alt="">
    </div>
    <ul class="sidebar__list">
      <li v-for="link in links" :key="link.name" class="sidebar__item">
        <router-link :to="{ name: link.name }" class="sidebar__link" active-class="sidebar__link--active">
          <img :src="require(`@/assets/img/${link.icon}.svg`)" alt="menu-point-icon">
        </router-link>
      </li>
    </ul>
    <button @click="onLogout" class="sidebar__exit">
      <img src="@/assets/img/exit-icon.svg" alt="exit-icon">
    </button>
  </div>
</template>

<script>
import {clearAuthTokens} from 'axios-jwt'

export default {
  name: 'AppSidebar',
  data() {
    return {
      menuLinks: [
        { icon: 'hall', name: 'hall' },
        { icon: 'waiting-list', name: 'waiting' },
        { icon: 'reservation', name: 'bookings-list' },
        { icon: 'guests', name: 'guests-list' },
        { icon: 'analytic', name: 'analytics' },
      ]
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    links() {
      if (this.userRole === 'admin' || this.userRole === 'manager') return [...this.menuLinks, { icon: 'users', name: 'users' }, { icon: 'table-settings', name: 'settings' }]
      return this.menuLinks
    }
  },
  methods: {
    onLogout() {
      clearAuthTokens()
      localStorage.clear()
      this.$router.push({ name: 'login' })
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.sidebar
  position: fixed
  top: 0
  left: 0
  width: 80px
  height: 100%
  background-color: #222B44

  +max-w($laptop-big)
    width: 40px

.sidebar__logo
  height: 80px
  padding: 24px 0
  text-align: center
  line-height: 0
  background-color: $color-bg

  +max-w($laptop-big)
    height: 50px
    padding: 14px 0

  img
    +max-w($laptop-big)
      width: 80%

.sidebar__list
  width: 100%
  text-align: center

.sidebar__item
  border-top: 1px solid #1c223c

  &:first-child
    border-color: $color-bg

  &:last-child
    border-bottom: 1px solid #1c223c

.sidebar__link
  display: block
  padding: 18px 0
  transition: opacity 0.3s

  +max-w($laptop-big)
    padding: 14px 0

  @media (hover: hover)
    &:hover
      opacity: 0.7

  img
    +max-w($laptop-big)
      width: 80%

.sidebar__link--active
  background-color: $color-theme

.sidebar__exit
  position: absolute
  bottom: 0
  width: 80px
  padding: 20px 0
  text-align: center
  line-height: 0
  transition: opacity 0.3s

  +max-w($laptop-big)
    width: 40px
    padding: 14px 0

  @media (hover: hover)
    &:hover
      opacity: 0.7

  img
    +max-w($laptop-big)
      width: 80%
</style>
