<template>
  <div class="content">
    <app-cells position="start"><h1 class="title">Аналитика</h1></app-cells>
    <app-tabs :tabs="tabsForRoles" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsLayout',
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    tabsForRoles() {
      if (this.userRole === 'admin') return [
        { name: 'analytic-guests', label: 'Гости' },
        { name: 'analytic-fin', label: 'Финансы' },
        { name: 'analytic-refund', label: 'Запросы на возврат' },
      ]
      return [{ name: 'analytic-guests', label: 'Гости' }]
    }
  }
}
</script>
