<template>
  <div>
    <form @submit.prevent="onCheckForm">
      <app-form-group label="Дата, время начала" label-for="time_start" required>
        <app-input
          v-model="booking_form.time_start"
          type="datetime-local"
          placeholder="Период с"
          id="time_start"
          @change.native="onChangeTimeStart"
          :error="$v.booking_form.time_start.$error"
          :readonly="booking_form.status.id === 'open'"
        />
        <template #error>
          <div v-if="$v.booking_form.time_start.$dirty && !$v.booking_form.time_start.required">Обязательное поле</div>
        </template>
      </app-form-group>
      <app-form-group label="Дата, время окончания" label-for="time_end" required>
        <app-input
          v-model="booking_form.time_end"
          type="datetime-local"
          placeholder="Период по"
          id="time_end"
          :error="$v.booking_form.time_end.$error"
        />
        <template #error>
          <div v-if="$v.booking_form.time_end.$dirty && !$v.booking_form.time_end.required">Обязательное поле</div>
        </template>
      </app-form-group>
      <app-form-group label="Количество гостей" label-for="count_guests" required>
        <app-input
          v-model.number="booking_form.count_guests"
          placeholder="Введите кол-во гостей"
          type="number"
          id="count_guests"
          :error="$v.booking_form.count_guests.$error"
        />
        <template #error>
          <div v-if="$v.booking_form.count_guests.$dirty && !$v.booking_form.count_guests.required">
            Обязательное поле
          </div>
          <div v-if="$v.booking_form.count_guests.$dirty && !$v.booking_form.count_guests.integer">
            Принимаются только целые числовые значение
          </div>
          <div v-if="overbooking">Количество гостей превышает посадку за столами</div>
        </template>
      </app-form-group>

      <app-form-group label="Стол основной" required key="main">
        <template #additional>
          <app-button
            v-if="!is_start_picking_additional && (!is_start_picking_main && !is_main_done)"
            @click="onStartPicking('main')"
            type="button" size="super_small"
            key="main_add"
          >
            выбрать
          </app-button>
          <app-button
            v-if="booking_form.table && !is_main_done"
            @click="onStopPicking('main')"
            type="button" size="super_small" theme="accept"
            key="main_check"
          >
            подтвердить
          </app-button>
          <app-button
            v-if="is_main_done"
            @click="onStartPicking('main')"
            type="button" size="super_small"
            key="main_edit"
          >
            редактировать
          </app-button>
        </template>
        <template #default>
          <div v-if="is_main_done || is_start_picking_main" class="table-tags" key="tags_main">
            <div v-if="booking_form.table" :class="['table-tags__item', { 'table-tags__item--check': is_main_done }]">
              {{ `№: ${booking_form.table.number}` }}
            </div>
            <div v-else class="table-tags__plug">Выберите стол на схеме</div>
          </div>
        </template>
        <template #error>
          <div v-if="$v.booking_form.table.$dirty && !$v.booking_form.table.required">
            Обязательное поле
          </div>
          <div v-if="booking_form.table && !is_main_done">
            Подтвердите выбор основного стола
          </div>
        </template>
      </app-form-group>
      <app-form-group v-if="isGroupBooking" label="Столы дополнительные" required key="additional">
        <template #additional>
          <app-button
            v-if="!is_start_picking_main && (!is_start_picking_additional && !is_additional_done)"
            @click="onStartPicking('additional')"
            type="button" size="super_small"
            key="additional_add"
          >
            выбрать
          </app-button>
          <app-button
            v-if="booking_form.additional_tables.length && !is_additional_done"
            @click="onStopPicking('additional')"
            type="button" size="super_small" theme="accept"
            key="additional_check"
          >
            подтвердить
          </app-button>
          <app-button
            v-if="is_additional_done"
            @click="onStartPicking('additional')"
            type="button" size="super_small"
            key="additional_edit"
          >
            редактировать
          </app-button>
        </template>
        <template #default>
          <div v-if="is_additional_done || is_start_picking_additional" class="table-tags" key="tags_additional">
            <template v-if="booking_form.additional_tables.length">
              <div
                v-for="table in booking_form.additional_tables"
                :key="`${table.id}_${Math.random()}`"
                :class="['table-tags__item', { 'table-tags__item--check': is_additional_done }]"
              >
                {{ `№: ${table.number}` }}
              </div>
            </template>
            <div v-else class="table-tags__plug">Выберите столы на схеме</div>
          </div>
        </template>
        <template #error>
          <div v-if="$v.booking_form.additional_tables.$dirty && !$v.booking_form.additional_tables.required">
            Обязательное поле
          </div>
          <div v-if="booking_form.additional_tables.length > 0 && !is_additional_done">
            Подтвердите выбор дополнительных столов
          </div>
        </template>
      </app-form-group>
      <app-form-group>
        <app-checkbox
          label="Зафиксировать столы"
          v-model="booking_form.is_fixed_table"
        />
      </app-form-group>
      <app-form-group label="Гость основной" key="guest_main">
        <template #additional>
          <app-button
            v-if="!booking_form.guest.length"
            @click="onAddGuestClick('main')"
            type="button" size="super_small" theme="accept"
            key="guest_main_add"
          >
            добавить
          </app-button>
        </template>
        <template #default>
          <app-guest-card
            :guests="booking_form.guest"
            @onGuestDelete="onGuestDelete"
            @onGuestEdit="onGuestEdit"
            type="main"
            key="guest_card_main"
          />
        </template>
      </app-form-group>
      <template v-if="isGroupBooking">
        <app-form-group label="Дополнительные гости" key="guest_additional">
          <template #additional>
            <app-button
              @click="onAddGuestClick('additional')"
              type="button" size="super_small" theme="accept"
              key="guest_additional_add"
            >
              добавить
            </app-button>
          </template>
          <template #default>
            <app-guest-card
              v-if="booking_form.additional_guests.length > 0"
              :guests="booking_form.additional_guests"
              @onGuestDelete="onGuestDelete"
              @onGuestEdit="onGuestEdit"
              type="additional"
              key="guest_card_additional"
            />
          </template>
        </app-form-group>
      </template>
      <template v-if="!booking_form.is_paid">
        <app-form-group>
          <app-checkbox
            v-if="is_deposit"
            v-model="booking_form.deposit"
            label="Забронировать с депозитом"
            :readonly="booking_form.is_paid"
          />
        </app-form-group>
        <div v-if="booking_form.deposit" class="deposit-card">
          <div class="deposit-card__header">
            <div class="deposit-card__text"><span>Депозит</span></div>
            <div v-if="deposit.params.price && !booking_form.is_paid" class="deposit-card__text">
              {{ new Intl.NumberFormat('ru-RU').format(deposit.params.price) }} руб.
              <template v-if="deposit.params.type">{{ deposit.params.type }}</template>
            </div>
          </div>
          <div v-if="deposit.params.total_price" class="deposit-card__text" :class="{ 'deposit-card__text--nomb': booking_form.is_paid }">
            Итого: <span>{{ new Intl.NumberFormat('ru-RU').format(deposit.params.total_price) }} руб.</span>
          </div>
          <div class="deposit-card__text">
            <app-checkbox
              v-model="is_due_date"
              @change.native="onChangeDueDate"
              label="Установить срок оплаты"
            />
          </div>
          <app-form-group v-if="is_due_date && !booking_form.is_paid" label="Срок оплаты" label-for="due_date">
            <app-input
              v-model="booking_form.pay_due_date"
              type="datetime-local"
              placeholder="Срок оплаты"
              id="due_date"
            />
          </app-form-group>
          <app-checkbox
            v-if="is_sms"
            v-model="send_sms"
            label="Отправить смс на оплату"
          />
        </div>
      </template>
      <app-form-group label="Комментарий хостес" label-for="description">
        <app-textarea
          v-model="booking_form.comment_hostes"
          placeholder="Введите комментарий"
          id="description"
        />
      </app-form-group>
      <app-cells position="center">
        <app-button
          ref="submit"
          :disabled="$v.booking_form.$error || !is_main_done || isGroupBooking && !is_additional_done"
        >
          Сохранить бронирование
        </app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators'
import { getTableCheck, patchBooking } from '@/http'
import { dateFormatting } from '@/helpers'
import AppGuestCard from '@/components/AppGuestCard'
import AppCheckbox from '@/components/AppCheckbox'
import { addHours, formatISO } from 'date-fns'

export default {
  name: 'AppEditBooking',
  components: { AppGuestCard, AppCheckbox },
  props: {
    booking_form: Object,
    and_additional: Boolean,
    guest_type: String
  },
  data() {
    return {
      is_start_picking_main: false,
      is_start_picking_additional: false,
      is_main_done: true,
      is_additional_done: this.booking_form.additional_tables.length > 0,
      deposit: {
        params: {},
      },
      is_deposit: false,
      send_sms: true,
      is_sms: false,
      overbooking: false,
      is_due_date: true
    }
  },
  validations() {
    const booking_form = {
      time_start: { required },
      time_end: { required },
      count_guests: { required, integer },
      table: { required },
      additional_tables: {},
    }
    if (this.isGroupBooking) booking_form.additional_tables = { required }
    else booking_form.additional_tables = {}

    return { booking_form }
  },
  watch: {
    'booking_form.time_start'(value) {
      if (value && !this.booking_form.is_paid) this.checkTableDeposit(value, undefined, undefined)
    },
    'booking_form.guest'(value) {
      if (value.length > 0) {
        this.is_sms = !!value[0].phone
        this.send_sms = true
      } else {
        this.is_sms = false
        this.send_sms = false
      }
    },
    and_additional(value) {
      if (!value) {
        this.is_start_picking_additional = false
        this.is_additional_done = false
        this.booking_form.additional_tables = []
        this.booking_form.additional_guests = []
      }
    },
    'booking_form.count_guests'(value) {
      if (value !== 0 && !this.booking_form.is_paid) this.checkTableDeposit(undefined, value, undefined)
      if (value && this.booking_form.table) {
        this.overbooking = value > this.booking_form.table.max_seat_number
      }
      if (this.booking_form.additional_tables.length > 0) {
        let additional_tables_max_seat_number = 0
        this.booking_form.additional_tables.map(item => { additional_tables_max_seat_number += item.max_seat_number })
        this.overbooking = value > (this.booking_form.table.max_seat_number || 0) + additional_tables_max_seat_number
      }
    },
    'booking_form.table'(value) {
      if (value.hasOwnProperty('id')) {
        if (!this.booking_form.is_paid) this.checkTableDeposit(undefined, undefined, this.booking_form.table.id)
        if (this.booking_form.count_guests) {
          this.overbooking = this.booking_form.count_guests > value.max_seat_number
        }
        if (this.booking_form.additional_tables.length > 0) {
          let additional_tables_max_seat_number = 0
          this.booking_form.additional_tables.map(item => { additional_tables_max_seat_number += item.max_seat_number })
          this.overbooking = this.booking_form.count_guests > value.max_seat_number + additional_tables_max_seat_number
        }
      }
    },
    'booking_form.additional_tables'(value) {
      if (value.length > 0) {
        const main_max_seat_number = this.booking_form.table.max_seat_number || 0
        let additional_tables_max_seat_number = 0

        value.map(item => { additional_tables_max_seat_number += item.max_seat_number })
        this.overbooking = this.booking_form.count_guests > main_max_seat_number + additional_tables_max_seat_number
      }
    },
    'booking_form.deposit'(value) {
      if (value) this.booking_form.pay_due_date = formatISO(addHours(new Date(), process.env.VUE_APP_BASIC_PAYMENT_DURATION)).slice(0, 16)
    },
    // is_due_date(value) {
    //   if (value) this.booking_form.pay_due_date = formatISO(addHours(new Date(), 6)).slice(0, 16)
    //   else this.booking_form.pay_due_date = null
    // }
  },
  computed: {
    isGroupBooking() {
      return this.and_additional
    },
  },
  mounted() {
    this.checkTableDeposit()
    this.isOverbooking()
    if (this.booking_form.pay_due_date) this.booking_form.pay_due_date = this.booking_form.pay_due_date.slice(0, 16)
  },
  methods: {
    onChangeDueDate() {
      if (this.is_due_date) this.booking_form.pay_due_date = formatISO(addHours(new Date(), process.env.VUE_APP_BASIC_PAYMENT_DURATION)).slice(0, 16)
      else this.booking_form.pay_due_date = null
    },
    isOverbooking() {
      let additional_tables_max_seat_number = 0
      if (this.booking_form.additional_tables.length > 0) {
        this.booking_form.additional_tables.map(item => { additional_tables_max_seat_number += item.max_seat_number })
      }
      this.overbooking = this.booking_form.count_guests > this.booking_form.table.max_seat_number + additional_tables_max_seat_number
    },
    onCheckForm() {
      this.$v.booking_form.$touch()
      if (this.$v.booking_form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      patchBooking(this.booking_form.id, this.normalizeForm())
        .then(res => {
          this.$notify({
            type: 'success',
            title: 'Успешно',
            text: 'Бронь сохранена'
          })
          this.$refs.submit.preload = false
          this.$emit('resetBookingEdit', res.data.id)
        })
        .catch(() => { this.$refs.submit.preload = false })
    },
    normalizeForm() {
      const normalizedForm = { ...this.booking_form }

      normalizedForm.status = normalizedForm.status.id
      if (normalizedForm.time_start.length === 16) normalizedForm.time_start = `${normalizedForm.time_start}:00+03:00`
      normalizedForm.time_end = `${normalizedForm.time_end}:00+03:00`
      if (normalizedForm.pay_due_date) normalizedForm.pay_due_date = `${normalizedForm.pay_due_date}:00+03:00`
      normalizedForm.table = normalizedForm.table.id
      if (normalizedForm.additional_tables.length > 0) {
        normalizedForm.additional_tables = normalizedForm.additional_tables.map(item => item.id)
      }
      if (normalizedForm.guest.length > 0) normalizedForm.guest = normalizedForm.guest[0].id
      else normalizedForm.guest = ''
      if (normalizedForm.additional_guests.length > 0) normalizedForm.additional_guests = normalizedForm.additional_guests.map(item => item.id)
      normalizedForm.send_sms = this.send_sms
      if (!normalizedForm.deposit) normalizedForm.pay_due_date = null
      // if (this.deposit.hasOwnProperty('is_deposit')) normalizedForm.deposit = this.deposit.is_deposit
      // else normalizedForm.deposit = false
      if (normalizedForm.status === 'open') delete normalizedForm.time_start
      normalizedForm.is_overbooking = this.overbooking
      if (!this.is_due_date) normalizedForm.pay_due_date = null

      return normalizedForm
    },
    onChangeTimeStart() {
      this.$emit('getAllData', dateFormatting(this.booking_form.time_start, 'iso-to-normal'))
      this.booking_form.time_end = formatISO(new Date(addHours(new Date(this.booking_form.time_start), 2))).slice(0, 16)
    },
    onStartPicking(type) {
      if (type === 'main') {
        this.is_start_picking_main = true
        this.is_main_done = false
      }
      if (type === 'additional') {
        this.is_start_picking_additional = true
        this.is_additional_done = false
      }
      this.$emit('onStartTablesPicking', type)
    },
    onStopPicking(type) {
      if (type === 'main') {
        this.is_main_done = true
        this.is_start_picking_main = false
      }
      if (type === 'additional') {
        this.is_additional_done = true
        this.is_start_picking_additional = false
      }
      this.$emit('onStopTablesPicking')
    },
    onGuestDelete(id, type) {
      this.$emit('onNewBookingGuestDelete', id, type)
    },
    onGuestEdit(id, type) {
      this.$emit('onNewBookingGuestEdit', id, type)
    },
    onAddGuestClick(type) {
      this.$modal.show('guest-modal')
      this.$emit('changeGuestType', type)
    },
    checkTableDeposit(
      time_start = this.booking_form.time_start,
      count_guests = this.booking_form.count_guests,
      table_id = this.booking_form.table.id,
    ) {
      if (!this.booking_form.is_paid && time_start && count_guests && table_id) {
        time_start = `${time_start}:00+03:00`
        getTableCheck({ time_start, count_guests, table_id })
          .then(res => {
            this.deposit = res.data
            this.is_deposit = res.data.is_deposit
            this.is_due_date = this.booking_form.pay_due_date
          })
      }
    }
  }
}
</script>
