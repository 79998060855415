<template>
  <button type="button" class="hall-card" :class="[`hall-card--${getStatus}`]">
    <div class="hall-card__icon" v-html="icon"></div>
    <div class="hall-card__info">
      <div class="hall-card__header">
        <div class="hall-card__number">Стол: {{ number }}</div>
        <div class="hall-card__count-place">
          {{ count_place_min }} {{ count_place_max === count_place_min ? '' : `- ${count_place_max}` }} <img src="@/assets/img/hall/count-place-icon.svg" alt="">
        </div>
      </div>
      <div v-if="events.length" class="hall-card__desc">
        <div v-for="(event, index) in events" :key="index" class="hall-card__event">
          <img v-if="event.event_time === 'free'" src="@/assets/img/hall/calendar-check-icon.svg" alt="">
          <img v-if="event.event_time === 'wait'" src="@/assets/img/hall/timer-icon.svg" alt="">
          <img v-if="event.event_time === 'busy'" src="@/assets/img/hall/chair-icon.svg" alt="">
          <span>{{ event.event_start ? `${event.event_start} -` : 'до' }} {{ event.event_end }}</span>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'AppHallCard',
  props: {
    number: String,
    count_place_min: Number,
    count_place_max: Number,
    events: Array,
    icon: String,
    status: String,
  },
  computed: {
    getStatus() {
      return this.status
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.hall-card
  position: relative
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
  overflow: hidden

.hall-card--main
  .hall-card__info
    background-color: rgba(#2f73ff, 0.7)

.hall-card--additional
  .hall-card__info
    background-color: rgba(#ff7f0a, 0.6)

.hall-card__info
  position: absolute
  top: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  height: 100%
  padding: 10px 6px
  background-color: rgba(47, 52, 67, 0.6)
  color: #fff

  +max-w($laptop-big)
    padding: 10px 4px

.hall-card__icon
  line-height: 0
  text-align: center

  svg
    width: 100%
    height: auto

    +max-w($laptop-big)
      width: 90%

.hall-card__header
  display: flex
  justify-content: space-between
  align-items: center

  +max-w($laptop-big)
    font-size: 12px

.hall-card__count-place
  display: flex
  align-items: center
  gap: 0 4px

  +max-w($laptop-big)
    gap: 0 2px

.hall-card__event
  display: flex
  justify-content: center
  align-items: center
  gap: 0 8px
  margin-bottom: 4px
  padding: 10px
  border-radius: 4px
  background-color: #222b44

  +max-w($laptop-big)
    gap: 0 6px
    padding: 3px

  &:last-child
    margin-bottom: 0

.hall-card--wait
  .hall-card__icon svg
    filter: hue-rotate(260deg) brightness(1.15)

.hall-card--cancel,
.hall-card--close,
.hall-card--busy
  .hall-card__icon svg
    filter: hue-rotate(200deg)

</style>
