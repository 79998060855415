<template>
  <div class="default-layout">
    <app-sidebar />
    <main class="main">
      <app-header />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar'
import AppHeader from '@/components/AppHeader'

export default {
  components: {
    AppSidebar,
    AppHeader,
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.default-layout
  flex-grow: 1
  display: flex
  flex-direction: column
  width: 100%
  height: 100%

.main
  flex-grow: 1
  margin-left: 80px

  +max-w($laptop-big)
    margin-left: 40px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
