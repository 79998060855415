<template>
  <div>
    <button
      v-for="item in bookings"
      :key="item.id"
      @click="$emit('onGetBooking', item)"
      type="button"
      class="booking-card"
      :class="[ `booking-card--${item.status.id}` ]"
    >
      <div v-if="!item.is_read" class="booking-card__unconfirmed">Новая заявка</div>
      <div class="booking-card__top">
        <div class="booking-card__name">
          <img v-if="!item.guest" src="@/assets/img/warning-triangle-icon.svg" alt="" class="booking-card__warning-icon">
          {{ item.guest ? item.guest.name : 'Гость не указан' }},
          <span>{{ item.count_guests }} <img src="@/assets/img/person-icon.svg" alt=""></span>
        </div>
        <div class="booking-card__timespan">{{ item.short_time_start }} - {{ item.short_time_end }}</div>
      </div>
      <div v-if="item.guest && item.guest.phone" class="booking-card__phone">{{ item.guest.phone }}</div>
      <div class="booking-card__info">
        <div class="booking-card__tables" :class="{ 'booking-card__tables--max-w': item.deposit_amount }">
          <span>
            Столы: <span :class="{ 'is-main': item.additional_tables.length }">{{ item.table_number }}</span>{{ item.additional_tables_number.length ? ',' : '' }}
            <template v-if="item.additional_tables_number.length">
              <span v-for="(additional_t, index) in item.additional_tables_number" :key="additional_t.id">
                {{ additional_t }}{{ index !== item.additional_tables_number.length - 1 ? ',' : '' }}
              </span>
            </template>
            <img v-if="item.is_fixed_table" src="@/assets/img/lock.svg" alt="">
            <img v-if="item.is_overbooking" src="@/assets/img/overbooking.svg" alt="">
          </span>
        </div>
        <div v-if="item.deposit_amount" :class="['booking-card__depo',  { 'booking-card__depo--check': item.is_paid }]">
          Депозит: <span>{{ new Intl.NumberFormat('ru-RU').format(item.deposit_amount) }} ₽</span>
        </div>
      </div>
      <div v-if="item.comment_guest" class="booking-card__comment">
        <img src="@/assets/img/guest-icon.svg" alt="">
        <div class="booking-card__c-d">{{ item.comment_guest }}</div>
      </div>
      <div v-if="item.comment_hostes" class="booking-card__comment">
        <img src="@/assets/img/hostes-icon.svg" alt="">
        <div class="booking-card__c-d">{{ item.comment_hostes }}</div>
      </div>
      <div class="booking-card__footer">
        <div class="booking-card__footer-wrapper">
          <div v-if="is_today && item.status.id === 'new'" class="booking-card__info-status  booking-card__info-status--new">
            <img src="@/assets/img/hall/time-new-icon.svg" alt=""> <span>{{ getNewTime(item.time_start) }}</span>
          </div>
          <div v-if="is_today && item.status.id === 'wait'" class="booking-card__info-status  booking-card__info-status--wait">
            <img src="@/assets/img/hall/time-wait-icon.svg" alt=""> <span>{{ getWaitTime(item.time_start) }}</span>
          </div>
          <div v-if="is_today && item.status.id === 'open'" class="booking-card__info-status  booking-card__info-status--close">
            <img v-if="isClosure(item.time_end) <= 0" src="@/assets/img/hall/time-closure-icon.svg" alt="">
            <img v-else src="@/assets/img/hall/to-close-icon.svg" alt="">
            <span>{{ getOpenTime(item.time_end) }}</span>
          </div>
          <div v-if="is_today && item.is_fast" class="booking-card__info-status  booking-card__info-status--is-fast">
            <img src="@/assets/img/hall/location.svg" alt=""> <span>Офлайн</span>
          </div>
        </div>
        <div class="booking-card__b-number">ID: {{ item.id }}</div>
      </div>
    </button>
  </div>
</template>

<script>
import { differenceInMinutes } from 'date-fns'

export default {
  name: 'AppBookingCard',
  props: {
    bookings: Array,
    is_today: Boolean
  },
  methods: {
    getNewTime(time_start) {
      const difference = differenceInMinutes(new Date(time_start), new Date())
      return this.calculateTime(difference)
    },
    getWaitTime(time_start) {
      const difference = differenceInMinutes(new Date(), new Date(time_start))
      return this.calculateTime(difference)
    },
    getOpenTime(time_end) {
      const difference = differenceInMinutes(new Date(time_end), new Date())
      return this.calculateOpenTime(difference)
    },
    calculateTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    calculateOpenTime(difference) {
      const hours = Math.trunc(difference / 60)
      const minutes = difference % 60

      if (difference <= 0) return 'Закрытие'
      else return `${hours ? `${hours}ч ` : ''}${minutes}мин`
    },
    isClosure(time_end) {
      return differenceInMinutes(new Date(time_end), new Date())
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.booking-card
  position: relative
  display: block
  width: 100%
  margin-bottom: 12px
  padding: 20px 16px 36px
  border: 1px solid #eceff4
  border-radius: 10px
  color: $color-text-base
  background-color: $color-bg-third
  font-size: 16px
  overflow: hidden

  +max-w($laptop-big)
    padding: 10px 10px 32px

.booking-card--wait
  border: 1px solid #ffeec3
  background-color: #faf7e4

.booking-card--wait
  border: 1px solid #ffeec3
  background-color: #faf7e4

.booking-card--open,
.booking-card--close
  border: 1px solid #ffc3c3
  background-color: #fae4e4

.booking-card__top
  display: flex
  align-content: center
  justify-content: space-between
  margin-bottom: 10px

.booking-card__name
  font-weight: 600

  span
    display: inline-flex
    align-content: center
    gap: 0 4px

.booking-card__warning-icon
  padding-bottom: 2px
  vertical-align: middle

.booking-card__timespan
  flex-shrink: 0
  font-weight: 600

.booking-card__phone
  margin-bottom: 10px

.booking-card__info
  display: flex
  align-content: center
  justify-content: space-between

.booking-card__tables
  color: $color-theme

  span
    img
      margin-right: 3px
      margin-bottom: -2px

    &.is-main
      color: $status-wait

.booking-card__tables--max-w
  max-width: 55%

.booking-card__depo
  color: #737c85

  span
    color: $color-error

.booking-card__depo--check
  span
    color: $status-new

.booking-card__comment
  display: flex
  align-items: flex-start
  gap: 0 5px
  margin-top: 10px
  margin-bottom: 10px

  &:last-child
    margin-bottom: 0

.booking-card__c-t
  margin-bottom: 5px

.booking-card__c-d
  color: #737c85
  font-size: 14px

.booking-card__unconfirmed
  position: absolute
  top: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  border-radius: 10px
  background-color: rgba(39, 174, 96, 0.9)
  color: #fff
  font-size: 20px
  font-weight: 600

.booking-card__footer
  position: absolute
  bottom: -1px
  left: 0
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%

.booking-card__info-status
  display: inline-flex
  align-items: center
  gap: 0 6px
  padding: 6px 11px 5px
  border-top-right-radius: 10px
  font-size: 14px
  font-weight: 600

  &:first-child
    position: relative

  &:not(:first-child)
    margin-left: -7px

  img
    width: 19px

.booking-card__info-status--new
  background-color: $color-theme
  color: #fff

.booking-card__info-status--wait
  background-color: $status-wait

.booking-card__info-status--close
  background-color: $color-error
  color: #fff

.booking-card__info-status--is-fast
  background-color: #b4b7bf
  color: #fff

.booking-card__b-number
  padding: 6px 11px 5px
  border-top-left-radius: 10px
  background-color: $color-theme
  color: #fff
  font-size: 14px

</style>
