<template>
  <button v-if="guests" class="hall-stat" :class="{ 'hall-stat--open': hallStatOpen }" @click="hallStatOpen = !hallStatOpen">
    <div class="hall-stat__toggle">
      <div class="hall-stat__arrow"></div>
    </div>
    <div class="hall-stat__line hall-stat__line--head">
      <div class="hall-stat__item"><span>Всего:</span></div>
      <div class="hall-stat__item">
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
            fill="#fff"/>
        </svg>
        <span>{{ guests.close_guests }} / {{ guests.all_guests }}</span>
      </div>
      <div class="hall-stat__item">
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.854 7.147a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.794l2.646-2.647a.5.5 0 0 1 .708 0Z"
            fill="#fff"/>
          <path
            d="M4.063 1a.437.437 0 0 1 .437.438v.437h7v-.438a.438.438 0 0 1 .875 0v.438h.875A1.75 1.75 0 0 1 15 3.625v9.625A1.75 1.75 0 0 1 13.25 15H2.75A1.75 1.75 0 0 1 1 13.25V3.625a1.75 1.75 0 0 1 1.75-1.75h.875v-.438A.437.437 0 0 1 4.063 1ZM1.874 4.5v8.75a.875.875 0 0 0 .875.875h10.5a.875.875 0 0 0 .875-.875V4.5H1.875Z"
            fill="#fff"/>
        </svg>
        <span>{{ guests.close_bookings }} / {{ guests.all_bookings }}</span>
      </div>
    </div>
    <div class="hall-stat__content">
      <div class="hall-stat__line hall-stat__line--wait">
        <div class="hall-stat__item"><span>Ждем:</span></div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
              fill="#f2c94c"/>
          </svg>
          <span>{{ guests.waiting_guests }}</span>
        </div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.854 7.147a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.794l2.646-2.647a.5.5 0 0 1 .708 0Z"
              fill="#f2c94c"/>
            <path
              d="M4.063 1a.437.437 0 0 1 .437.438v.437h7v-.438a.438.438 0 0 1 .875 0v.438h.875A1.75 1.75 0 0 1 15 3.625v9.625A1.75 1.75 0 0 1 13.25 15H2.75A1.75 1.75 0 0 1 1 13.25V3.625a1.75 1.75 0 0 1 1.75-1.75h.875v-.438A.437.437 0 0 1 4.063 1ZM1.874 4.5v8.75a.875.875 0 0 0 .875.875h10.5a.875.875 0 0 0 .875-.875V4.5H1.875Z"
              fill="#f2c94c"/>
          </svg>
          <span>{{ guests.waiting_bookings }}</span>
        </div>
      </div>
      <div class="hall-stat__line hall-stat__line--hall">
        <div class="hall-stat__item"><span>В зале:</span></div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
              fill="#74c596"/>
          </svg>
          <span>{{ guests.now_guests }}</span>
        </div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.854 7.147a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.794l2.646-2.647a.5.5 0 0 1 .708 0Z"
              fill="#74c596"/>
            <path
              d="M4.063 1a.437.437 0 0 1 .437.438v.437h7v-.438a.438.438 0 0 1 .875 0v.438h.875A1.75 1.75 0 0 1 15 3.625v9.625A1.75 1.75 0 0 1 13.25 15H2.75A1.75 1.75 0 0 1 1 13.25V3.625a1.75 1.75 0 0 1 1.75-1.75h.875v-.438A.437.437 0 0 1 4.063 1ZM1.874 4.5v8.75a.875.875 0 0 0 .875.875h10.5a.875.875 0 0 0 .875-.875V4.5H1.875Z"
              fill="#74c596"/>
          </svg>
          <span>{{ guests.now_bookings }}</span>
        </div>
      </div>
      <div class="hall-stat__line hall-stat__line--close">
        <div class="hall-stat__item"><span>Закрыто:</span></div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
              fill="#ea5467"/>
          </svg>
          <span>{{ guests.close_guests }}</span>
        </div>
        <div class="hall-stat__item">
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.854 7.147a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.794l2.646-2.647a.5.5 0 0 1 .708 0Z"
              fill="#ea5467"/>
            <path
              d="M4.063 1a.437.437 0 0 1 .437.438v.437h7v-.438a.438.438 0 0 1 .875 0v.438h.875A1.75 1.75 0 0 1 15 3.625v9.625A1.75 1.75 0 0 1 13.25 15H2.75A1.75 1.75 0 0 1 1 13.25V3.625a1.75 1.75 0 0 1 1.75-1.75h.875v-.438A.437.437 0 0 1 4.063 1ZM1.874 4.5v8.75a.875.875 0 0 0 .875.875h10.5a.875.875 0 0 0 .875-.875V4.5H1.875Z"
              fill="#ea5467"/>
          </svg>
          <span>{{ guests.close_bookings }}</span>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'AppHallStat',
  props: {
    guests: Object
  },
  data() {
    return {
      hallStatOpen: false,
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.hall-stat
  position: absolute
  top: 100px
  right: 20px
  padding: 11px 16px
  border-radius: 15px
  background-color: #040d26
  max-height: 42px
  overflow: hidden
  z-index: 100
  font-size: 16px
  transition: max-height 0.3s

  +max-w($laptop_lg)
    padding: 12px 10px

  +max-w($laptop-big)
    top: 70px

.hall-stat--open
  max-height: 146px

  .hall-stat__arrow
    top: 16px
    transform: scale(-1)

    +max-w($laptop_lg)
      top: 14px
      right: 5px

.hall-stat__arrow
  position: absolute
  top: 14px
  right: 15px
  width: 16px
  height: 16px
  background-image: url("~@/assets/img/hall/stat-arrow.svg")
  transition: transform 0.3s

  +max-w($laptop_lg)
    top: 12px
    right: 5px

.hall-stat__content
  padding-top: 12px

.hall-stat__line
  display: grid
  grid-template-columns: 140px 110px 115px
  margin-bottom: 12px

  &:last-child
    margin-bottom: 0

  +max-w($laptop_lg)
    font-size: 14px
    grid-template-columns: 75px 85px 85px

.hall-stat__line--head
  margin-bottom: 0
  color: #fff

.hall-stat__line--wait
  color: $status-wait

.hall-stat__line--hall
  color: $status-new

.hall-stat__line--close
  color: $status-close

.hall-stat__item
  display: flex
  align-items: center
  gap: 8px
  font-weight: 600

</style>
